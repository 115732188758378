import React, { createContext, useState, useEffect } from 'react'
import { getDataFormSeamCookie, setToSemeCookie } from '@/utils/utils'
export const TabContext = createContext()

const TabContextProvider = (props) => {
  const [activeTab, setActiveTab] = useState(0)

  const sendActiveTab = (index) => {
    setActiveTab(index)
    setToSemeCookie('activeTab', index)
  }

  useEffect(() => {
    const tab = getDataFormSeamCookie('activeTab') || 0
    setActiveTab(Number(tab))
  }, [])

  return <TabContext.Provider value={{ activeTab, sendActiveTab }}>{props.children}</TabContext.Provider>
}

export default TabContextProvider
