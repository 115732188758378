import React from 'react'
import Menus from '@/common/components/Menus'
import { menus } from '@/lib/menus'

export const getMenuData = (activeTab: number) => {
  return menus
}
export default function MenusWrapper() {

  return (
    <Menus menus={menus} />
  )
}
