
// 当前代码为自动生成, 切勿修改或者手动新增！！
export default {
" Ads Activity": `广告活动`,
"+ Add Email Template": `新增邮件模板`,
"+ Add SMS Template": `新增短信模板`,
"A password reset email has been sent to your mailbox": `密码重置邮件已成功发送至您的邮箱`,
"AI Model": `人工智能模型`,
"ALI": `所有`,
"Account": `账号`,
"Account Management": `账户管理`,
"Account/User Name/Email/Phone": `账号/用户名/邮箱/手机号`,
"Account/User Name/Email/Role": `账号/用户名/邮箱/角色`,
"Actions": `操作`,
"Activate": `激活`,
"Active": `活跃`,
"Activity Logs": `活动日志`,
"Ad Generation": `广告生成`,
"Add": `新增`,
"Add Account": `新增账户`,
"Add Application": `添加应用`,
"Add Attributes": `新增属性`,
"Add Description": `新增产品描述`,
"Add Email Template": `新增邮件模板`,
"Add Folder": `添加文件夹`,
"Add Frequency": `新增频率`,
"Add Graphic message": `添加图形消息`,
"Add MP QR Code": `增加小程序二维码`,
"Add Measurement Family": `新增度量组`,
"Add Measurement Unit": `新增度量单位`,
"Add New Automatic Reply": `新增自动回复`,
"Add New Catalog": `添加新文件夹`,
"Add New Chat": `添加新聊天`,
"Add New Page": `添加新页面`,
"Add New Page Categories": `添加新的页面分类`,
"Add New Posts": `新增博客`,
"Add New QR Code": `增加新的二维码`,
"Add New Reply Content": `增加新的回复内容`,
"Add Org": `新增组织`,
"Add Post": `新增博客`,
"Add Product": `新增产品`,
"Add Reward": `新增奖励`,
"Add Role": `添加角色`,
"Add Secondary Goal": `添加次要目标`,
"Add Shipping Method": `新增运费模板`,
"Add Transition Method": `新增配送方式`,
"Add URL": `新增URI`,
"Add User": `添加用户`,
"Add Users": `添加用户`,
"Add Video": `增加视频`,
"Add Warehouse": `新增仓库`,
"Add a New Blank Page": `添加一个新的空白页`,
"Add application": `添加应用`,
"Add email to blocklist": `将电子邮件添加到黑名单`,
"Add ip to blocklist": `将 ip 添加到黑名单`,
"Add related content": `添加相关内容`,
"Add related products": `添加相关产品`,
"Add tax zone": `添加税区`,
"Additional": `续件`,
"Additional quantity (item)": `续件`,
"Address": `地址`,
"Ads Activity": `广告活动`,
"Adyen": `Adyen`,
"After shipment": `发货后t`,
"After-sale": `售后仓`,
"Al Reco Models": `智能推荐模型`,
"AlReco": `智能推荐`,
"Ali": `Ali`,
"All": `全部`,
"All Channel": `全渠道`,
"All Events": `所有事件`,
"All Logs": `所有日志`,
"All Requests": `所有请求`,
"All Subscription": `全部订阅`,
"All goods in the order must be sent from the same warehouse. In this case it is not allowed to split the order.": `订单中的所有货物必须从同一个仓库发送。在这种情况下不允许拆分订单。`,
"All plan type": `所有流程类型`,
"All posts": `所有博客`,
"Allow Callback URIs": `回调URIs`,
"Allow Wildcard * in sign-in URI redirect": `允许域名泛解析重定向`,
"App Description": `应用描述`,
"App Push": `App推送`,
"App Resource": `应用资源`,
"App Role": `用户角色`,
"Application": `应用`,
"Application List": `应用列表`,
"Application Name": `应用名称`,
"Application Setting": `应用设置`,
"Application/Client ID": `应用/Client ID`,
"Applications": `应用`,
"Applications.Applications": `应用管理`,
"Apply Hold Order Setting": `Hold单设置`,
"Approve": `通过`,
"Are you sure delete this feild?": `您确定删除该字段吗？`,
"Are you sure disable it?": `您确定要禁用当前组织吗？`,
"Are you sure to clear the conversation": `您确定要清除对话吗？`,
"Are you sure to deliver immediately?": `你确定要立即生成订单吗？`,
"Asset Size": `资产规模`,
"Assets Management": `资产管理`,
"Assign Users": `添加用户`,
"Associate": `联系`,
"Associate Media": `联营媒体`,
"Associate Media Note": `注意：如果使用此方法，则仅关联外部链接`,
"Associated Logistic Company": `关联物流公司`,
"Associated Physical Warehouse": `关联物理仓`,
"Associated Store": `关联店铺`,
"Associated logical warehouse": `关联逻辑仓`,
"Attempts during broken integration": `集成失败期间的尝试`,
"Attribute Name": `属性名称`,
"Attributes": `属性`,
"Attributes Basic Info": `属性基本信息`,
"Audit Order": `审核订单`,
"Audit Status": `审核状态`,
"Authenticator": `验证`,
"Authorization Code": `验证码`,
"Automatic Replies": `自动回复`,
"Automation gives": `自动化为您`,
"Back Order": `异常订单`,
"Back Order tip": `标记为异常订单的订单总数`,
"Basic Information": `基本信息`,
"Basic information": `基本信息`,
"Batch Delete": `批量删除`,
"Batch Export": `导入 & 导出`,
"Batch Time / Batch ID": `导出时间 / 导出ID`,
"Behaviour": `行为`,
"Billing address": `账单地址`,
"Bind": `绑定`,
"Bind Services": `绑定服务`,
"Blog Engine": `博客引擎`,
"Branch": `分支`,
"Brand New Store": `全新店铺`,
"Brand New Store Information": `全新店铺信息`,
"Brand.com": `品牌网`,
"Brand.com tips": `Brand.com 是一个动态内容网站，展示有关品牌产品、服务和独特故事的深入信息。它通过引人入胜的文章、专家评论和迷人的视觉效果，为消费者和爱好者提供了一个全面的平台，让他们探索并与他们喜爱的品牌建立联系。`,
"Brightness": `亮度`,
"Broken": `破损仓`,
"Build landing pages for ecommerce promotions, paid ad campaigns": `为电子商务促销、付费广告活动构建登陆页面。`,
"Business ID": `企业ID`,
"Business qualification": `经营资质`,
"CDN URL": `CDN 网址`,
"CHANNEL": `渠道仓`,
"COMING SOON！": `即将发布！`,
"CRM": `客户关系管理`,
"CRM AI Conversation": `CRM人工智能对话`,
"Calculate Tax Based on": `计税方式`,
"Campaign Detail": `活动详情`,
"Campaign Engagement": `活动参与度`,
"Campaign Plan": `活动计划`,
"Campaign Plan Overview": `活动计划概览`,
"Cancel": `取消`,
"Canceled": `取消`,
"Capture payment after shipping": `在发货后扣款`,
"Carrier Setting": `物流设置`,
"Catalog Name": `文件夹名字`,
"Categories": `类别`,
"Categories: All": `依据博客类别进行搜索`,
"Category": `类别`,
"Category Detail": `类目详情`,
"Ceate Tags": `新增标签`,
"Change Logistic Company": `修改物流商`,
"Change Warehouse": `修改仓库`,
"Channel": `渠道`,
"Channel Options": `渠道选项`,
"Channel Warehouse": `渠道仓`,
"Character Settings": `角色设定`,
"Chinese": `简体中文`,
"Choose Media": `从资料库中`,
"Choose Website Type": `选择网站类型`,
"Choose the characteristics of the orders you want this rule to handle.": `选择您希望此规则处理的订单特征。`,
"City": `城市`,
"Click to Generate Token": `点击生成Token`,
"Client Credentials": `用户凭证`,
"Client ID": `客户代码`,
"Client Name": `客户名称`,
"Client Secret": `客户隐私`,
"Client acting on behalf of a user": `用户端请求`,
"Client acting on behalf of itself": `客户端请求`,
"Clone": `克隆`,
"Close sidebar": `关闭侧边栏`,
"Code": `度量组代码`,
"Collection Name": `集合名称`,
"Collection Type": `集合类型`,
"Collections": `集合`,
"Coming soon": `即将到来`,
"Comments": `评论`,
"Compare characteristics of this order with fraudulent orders observed in the past.": `将此订单的特征与过去观察到的欺诈订单进行比较。`,
"Component Name": `组件名称`,
"Configuration": `配置`,
"Configure Store Basic Info": `配置店铺基本信息`,
"Confirm": `确认`,
"Connect New Data Stream": `连接新数据流`,
"Consumer": `消费者`,
"Consumer Detail": `消费者详情`,
"Consumer Email is required": `消费者邮箱是必填`,
"Consumer Experience": `消费者体验`,
"Consumer ID": `消费者ID`,
"Consumer Insights": `消费者洞察`,
"Consumer Insights List": `消费者洞察列表`,
"Consumer Name is required": `消费者名字是必填`,
"Consumer info": `消费者信息`,
"Consumer paid": `消费者付款`,
"Consumer phone number is credential personal...": `消费者电话号码是凭证个人信息数据。请确保您有权限访问这些数据否则您可能会被卷入`,
"Contact Information": `联系方式`,
"Content Experience": `内容体验`,
"Content Name": `内容名称`,
"Content Types": `内容类型`,
"Contents": `内容`,
"Continue Shopping": `继续购物`,
"Contrast": `对比`,
"Conversational BI Analysis tips": `对话式BI分析、自然数据探索体验和智能归因工具。`,
"Conversion Operation": `标准转换率`,
"Copied": `已复制`,
"Copy": `复制`,
"Copy Link": `复制链接`,
"Copy from Current Page Type": `从当前页面类型复制`,
"Copy from Existed Store Information": `从现有商店信息复制`,
"Copy to clipboard": `复制到剪切板`,
"Country": `国家`,
"Country based": `基于国家`,
"Cover Video": `封面视频`,
"Craft email/ SMS newsletters": `新建与客户建立联系的电子邮件/短信通讯、活动和促销活动。自定义您的通信内容以准确提供他们正在寻找的内容。`,
"Creat a Campaign": `创建活动`,
"Creat a Task": `创建快速任务`,
"Creat and manage your own promotion for your shop and products on Dtc Center": `在DTC Center上为您的店铺和产品创建并管理您自己的促销活动`,
"Create Marketing Automation Campaign": `创建营销自动化活动`,
"Create New Promotion": `创建新折扣`,
"Create New Segments": `创建人群包`,
"Create New Voucher": `创建优惠券`,
"Create Process Canvas": `创建流程画布`,
"Create Role": `创建角色`,
"Create SMS Template": `创建SMS模板`,
"Create Task": `创建快速任务`,
"Create Website": `创建网站`,
"Create a process canvas": `创建一个流程画布以自定义复杂的用户旅程和营销策略。`,
"Create a return order": `创建逆向订单`,
"Create a task triggerred": `创建立即或稍后触发的任务。`,
"Create and manage logistic sourcing rules to determine the most appropriate logistic company for shipping to reduce shipping cost and improve efficiency.": `创建和管理物流寻源规则以确定最适合运输的物流公司从而降低运输成本并提高效率。`,
"Create and manage warehouse sourcing rules to control what node or warehouse should be considered for out-bounding to increase automation capabilities.": `创建和管理仓库询源规则以控制应考虑哪些节点或仓库进行发货以提高自动化能力。`,
"Create collection": `创建收藏`,
"Create collection to manage collection template": `创建集合来管理集合模板。`,
"Create collections": `创建收藏`,
"Create collections for tables of data to be referenced": `为要引用的数据表创建集合。`,
"Create component to compose dynamic landing pages without a developer": `创建组件以在没有开发人员的情况下组成动态登录页面。`,
"Create components": `创建组件`,
"Create page type to manage page templates": `创建页面类型来管理页面模板。`,
"Create page types": `创建页面类型`,
"Create pages": `创建页面`,
"Create posts": `创建帖子`,
"Create posts for ecommerce article or news": `为电子商务文章或新闻创建帖子。`,
"CreateOrModifyTaxClass": `创建或更新税费`,
"Created SMS template will be submitted to all to review to user as a template.": `请注意，某些短信服务商要求提交已创建的短信模板进行审核。审核通过后的模版才可被使用。`,
"Created Time": `创建时间`,
"Created time": `创建时间`,
"Creation  Data": `创作数据`,
"Creation Data": `创作数据`,
"Credentials & Secrets": `证书 & 密钥`,
"Credentials & Secrets-alert": `请妥善保存当前密钥一旦当前窗口关闭当前密钥将不能复制保存。`,
"Credentials & Secrets-info": `应用程序在请求token时用来证明其身份的秘密字符串`,
"Currency": `货币`,
"Currency Format": `货币格式`,
"Currency format": `显示格式`,
"Currency info": `设置店铺统一的货币类型符号精度和展示形式`,
"Currency symbol": `货币符号`,
"Current Language": `当前语言`,
"Current theme": `当前主题`,
"Custom Product Restrictions": `自定义地区限制`,
"Custom Region Restrictions": `自定义地区限制`,
"Custom logistic priority": `自定义物流优先级`,
"Custom shipping fee": `自定义`,
"Custom warehouse weight": `自定义仓库权重`,
"Customer Service": `客户服务`,
"DATA SOURCE": `数据源`,
"DAYS": `天`,
"DELIVRD": `转发成功`,
"DTC": `DTC`,
"DTC Platform": `DTC平台`,
"DaoXin": `DaoXin`,
"Data Connector Type": `数据连接器类型`,
"Data Flow": `数据流`,
"Data Ingestion": `数据接入`,
"Data Ingestion tips": `数据摄取是构建您自己的 CDP 的第一步。它将您的消费者源数据移动并复制到目标落地中，并且只需从各种渠道进行最少的转换。`,
"Data Management": `数据管理`,
"Data Stream Name": `数据流名称`,
"Data Stream Status": `数据流状态`,
"Data Streams": `数据流`,
"Date & Time format": `日期 & 时间格式`,
"Date format": `日期格式`,
"Date&Time format": `日期时间格式`,
"Days": `天`,
"DaysSincetheLastPurchase": `距今最近一次购买的天数`,
"Deactivated": `未激活`,
"Dectivate": `禁用`,
"Default Value": `默认值`,
"Default Weight": `默认重量`,
"Default value will be displayed whenattributes value is null.": `当插入的属性属性值为空时将会展现默认值。`,
"Delete": `删除`,
"Delivery area": `配送区域`,
"Description": `描述`,
"Descriptions": `描述`,
"Deselect All": `取消全选`,
"Detail": `详情`,
"Details": `详情`,
"Device ID": `设备ID`,
"Device Info": `设备信息`,
"Digit grouping": `数据分组`,
"Digital Data Assist": `数字数据辅助`,
"Disable": `禁用`,
"Discount Promotions": `折扣`,
"Display timezone": `显示时区`,
"Distance": `距离`,
"Distribute Coupon": `分发优惠券`,
"Document Type": `文件类型`,
"Domain name is required": `域名为必填项`,
"Done": `完毕`,
"Down payment": `起送金额`,
"Download Later": `晚些再下载`,
"Download invoice": `下载发票`,
"Draft": `草稿`,
"Drag and Edit Board": `编辑面板`,
"Duplicate": `克隆`,
"EC Activity": `电子商务活动`,
"EMAIL": `邮箱`,
"EXPIRED": `已过期`,
"EXTERNAL": `外部`,
"Ecommerce": `电子商务`,
"Ecommerce tips": `电子商务是一个蓬勃发展的在线市场，使品牌能够通过数字平台购买和销售产品或服务，从而在舒适的家中提供无缝且易于访问的购物体验。`,
"Edit": `编辑`,
"Edit Account": `编辑账户`,
"Edit Automatic Reply": `编辑自动回复`,
"Edit Board": `编辑板`,
"Edit Catalog": `编辑文件夹`,
"Edit Email Template": `编辑邮件模板`,
"Edit Frequency": `编辑频率`,
"Edit Measurement Family": `编辑度量组`,
"Edit Measurement Unit": `编辑度量单位`,
"Edit Model": `编辑模型`,
"Edit Org": `编辑组织信息`,
"Edit Page Categories": `编辑页面分类`,
"Edit Reply Content": `编辑回复内容`,
"Edit SMS Template": `编辑SMS模板`,
"Edit Shipping Method": `编辑运费模板`,
"Edit Tags": `编辑标签`,
"Edit Task": `编辑快速任务`,
"Edit Transition Method": `编辑配送方式`,
"Edit error massage": `Edit error massage`,
"Edit tax zone": `编辑配置`,
"Email": `邮箱`,
"Email BlockList": `电子邮件阻止列表`,
"Email Template": `邮件模板名称`,
"Email Template Description": `邮件模板描述`,
"Email Template Detail": `邮件模板详情`,
"Email Template Name": `邮件模板名称`,
"End": `结束`,
"End date": `结束日期`,
"Endrollment date": `上线时间`,
"Enter Q&A Chatbot details": `进入问答聊天机器人详情界面`,
"Enter price": `计税价格`,
"Entry Setting": `准入设置`,
"Error": `错误`,
"Error Message": `Error Message`,
"Event": `事件`,
"Event Attribute": `事件属性`,
"Event Detail": `事件详情`,
"Event Edit": `事件编辑`,
"Event Type": `Event Type`,
"Event Type  App  Account": `Event Type  App  Account`,
"EventAdd-Info": `只支持小写字母、数字和英文下划线且必须以英文字母开头不能以下划线结尾`,
"EventGrid": `事件网格`,
"Events": `事件`,
"Every Day": `每天`,
"Every Month": `每月`,
"Every Week": `每周`,
"Except for the specified region the default freight is used for other regions": `除指定地区外其他区域使用默认模版`,
"Exception handling": `异常处理`,
"Exception type": `异常类型`,
"Exclusive of tax": `不含税`,
"Expire Date": `过期日期`,
"Expires": `过期时间`,
"Export status": `出口状态`,
"External URL": `外部网址`,
"Facebook": `Facebook`,
"Fail": `失败`,
"Failure": `失败`,
"Fans Detail": `粉丝详情`,
"Fans Management": `粉丝管理`,
"Faster editing": `编辑速度更快`,
"Faster time to market": `更快的上市时间`,
"Featured Image": `博客主图`,
"Featured Image Alt Text": `图片替代文本`,
"File name": `文件名称`,
"Filters & Sort": `筛选器和排序`,
"Finance": `财务`,
"First Name": `名`,
"First name": `名`,
"Fold": `折叠`,
"Follow-up": `跟进`,
"Follow-up.content": `在履行订单之前，请联系消费者以验证其身份和购买情况`,
"Forever": `永远`,
"Format": `货币格式`,
"Fraud Analysis": `欺诈分析`,
"Fraud Criteria": `欺诈标准`,
"Freight rule": `运费规则`,
"Frequency": `频率`,
"Frequency setting": `频率设置`,
"Friday": `周五`,
"From customers": `来自 {arg1} 客户`,
"Fulfilled": `已履约`,
"Fulfilled tip": `订单状态为“已发货”、“已完成”的订单总数`,
"Fulfillment": `履约`,
"Fulfillment Setting": `履约配置`,
"Fulfillmentlist": `仓库网络`,
"Fulfilment Experience": `履约体验`,
"Full Access": `读写`,
"Function": `功能`,
"General": `概述`,
"General Setting": `全局设置`,
"Generate New Secret": `生成新密钥`,
"Generate New Token": `生成新Token`,
"Generate New Token Info1": `1.在DTC Verify 验证应用程序中，单击+并按顺序输入设置键。`,
"Generate New Token Info2": `2.输入您的电子邮件地址或电话号码和以下密钥（空格不影响）。`,
"Generate New Token Info3": `3.单击“添加”完成设置`,
"Generate Token": `生成Token`,
"Generate Website": `生成网站`,
"Generate pictures": `我想生成图片`,
"Give priority to delivery from warehouse with large inventory": `优先从库存量大的仓库发货。`,
"Give priority to delivery from warehouse with low freight cost": `运费低优先从运费低的仓库发货`,
"Global Setting": `全局设置`,
"Goal Completion Rate": `目标达成率`,
"Goal Setting": `目标设定`,
"Grant Type": `授权类型`,
"Group": `分组`,
"Guest": `游客`,
"HOURS": `小时`,
"Header": `标头`,
"Headless CMS": `无头内容管理系统`,
"Height": `高度`,
"High.Risk": `High Risk`,
"Hold": `Hold单`,
"Hold tip": `申请悬挂的订单总数`,
"Hours": `小时`,
"How would you like to get started": `您想如何开始？`,
"ID Mapping": `ID映射`,
"ID Mapping tips": `生成唯一ID以连接来自不同渠道的不同ID`,
"IDENTIFICATION": `鉴别`,
"INTERNAL": `内部`,
"IP": `IP`,
"If nothing is selected below, then by default only ordeline fields are exported": `如果下面未选择任何内容，则默认情况下仅导出订单字段`,
"If the following conversion events are completed within": `如果以下转换事件在内完成`,
"Image Preview": `图像预览`,
"Implicit (hybrid)": `隐含（混合）`,
"In Progress": `In Progress`,
"Inactive Subscription": `取消订阅`,
"Inactive Subscription.description": `您确定要取消此订阅吗？`,
"Inclusive of tax": `含税`,
"Information": `信息`,
"Initial": `初始`,
"Initial quantity (item)')": `首件`,
"Input Account": `输入账号`,
"Input Address": `请输入地址`,
"Input Application Name": `请输入应用名称`,
"Input City": `请输入城市`,
"Input Client ID": `请输入Client ID`,
"Input Client Secret": `输入用户隐私`,
"Input Country": `请输入国家`,
"Input Description": `请输入描述`,
"Input Email": `输入邮箱`,
"Input First Name": `输入名`,
"Input Last Name": `输入姓`,
"Input Legal Entity": `请输入法律实体`,
"Input Length": `输入长度`,
"Input Phone Number": `输入电话号码`,
"Input Postcode": `请输入邮编`,
"Input Store name": `请输入店铺名称`,
"Input Value": `输入值`,
"Input prefix": `输入前缀`,
"Input suffix": `输入后缀`,
"Insert Attributes": `插入属性`,
"Instagram": `Instagram`,
"Integration Portal": `集成门户`,
"Intelligent Recommendation": `智能推荐`,
"Interface ID": `接口ID`,
"Interface Name": `接口名称`,
"International/ Hongkong Macao or Taiwan Messages": `国际/港澳台信息`,
"Inventory": `库存`,
"Inventory Management": `库存管理`,
"Inventory Setting": `库存设置`,
"Inventory Setup": `库存创建`,
"Inventory depth": `库存深度`,
"Invoice List": `发票列表`,
"Invoker": `祈求者`,
"Job Center": `任务中心`,
"Job Detail": `定时任务详情`,
"Job List": `任务列表`,
"Judge the distance between the warehouse and the order receiving address and give priority to shipping from the nearest warehouse": `仓库优先级判断仓库与订单接收地址之间的距离并优先从最近的仓库发货。`,
"KDL": `KDL`,
"Kernel sampling (top_p)": `内核采样（top_p）`,
"Kernel tips": `与随机性类似，但不要与随机性一起改变`,
"Key Highlights Of Our Functions": ``,
"Key Highlights Of Our Functions.four": `使用主要生态系统参与者法学硕士（包括<span class="font-bold">NVIDIA、Google、AWS</span>和<span class="font-bold">OpenAI</span> ）开发`,
"Key Highlights Of Our Functions.one": `精心设计，与该行业<span class="font-bold">独特的转型驱动因素</span>和<span class="font-bold">重点领域</span>保持一致，同时利用<span class="font-bold">Gen AI 解决方案</span>`,
"Key Highlights Of Our Functions.subTitle": `旨在与该行业独特的转型驱动因素和重点领域保持一致，同时利用 Gen AI 解决方案`,
"Key Highlights Of Our Functions.three": `设计<span class="font-bold">易于部署</span>，并且可以<span class="font-bold">轻松定制</span>以满足您组织的独特需求`,
"Key Highlights Of Our Functions.two": `旨在展示优势，帮助您就 GenAI 如何为您的组织<span class="font-bold">增加价值</span>做出明智的选择`,
"LOGICAL": `逻辑库存`,
"Label": `标签`,
"Language": `语言`,
"Language: All": `依据博客语言进行搜索`,
"Last 180 Days": `过去180天`,
"Last 30 Days": `过去30天`,
"Last 7 Days": `过去7天`,
"Last Modified": `修改时间`,
"Last Name": `姓`,
"Last Outbound Time": `最晚出库时间`,
"Last edited": `最后编辑时间`,
"Last name": `姓`,
"Last180Days": `过去180天`,
"Last30Days": `过去30天`,
"Last7Days": `过去7天`,
"Layout: YYYYMMDDHHMMSS": `格式：YYYYMMDDHHMMSS`,
"Legal Entity": `法律实体`,
"Length": `长度`,
"Live Streaming": `直播`,
"Locale Information": `语言/时区`,
"Location": `店铺地址`,
"Log Date": `记录日期`,
"Logical": `逻辑仓`,
"Logical  Warehouse Name": `逻辑仓名称`,
"Logical Warehouse": `逻辑仓`,
"Logical Warehouse Name": `逻辑仓名称`,
"Logistic": `物流商管理`,
"Logistic Company": `物流公司`,
"Logistic Company Name": `物流公司名称`,
"Logistic Priority": `物流优先级`,
"Logistic Sourcing Rule": `物流商寻源规则`,
"Logistic Sourcing Rule List": `物流寻源规则列表`,
"Logistic Sourcing Rules": `物流寻源规则`,
"Logistics Company Name": `物流公司名称`,
"Logistics information synchronization": `物流信息同步`,
"Low Freight Cost": `低运费`,
"Low.Risk": `低风险`,
"Loyalty Programs": `忠诚度计划`,
"MA Experience": `营销体验`,
"MFA": `MFA`,
"MINI_PROGRAM": `微信小程序`,
"MINUTES": `分钟`,
"MONTHS": `月`,
"MP Banner Add": `增加小程序横幅`,
"MP Banner Detail": `小程序横幅详情`,
"MP QR Code Detail": `小程序二维码详情`,
"Mail sending failed": `密码重置邮件发送失败请稍后重试`,
"Main": `主仓`,
"Main Process": `主要流程`,
"Manage": `管理`,
"Marketing": `营销`,
"Marketing Automation Experience": `营销自动化体验`,
"Marketing Calendar": `营销日历`,
"Marketing Center": `营销中心`,
"Marketing Experience": `营销自动化`,
"Measurement Family": `UOM`,
"Measurement Family info": `UOM主要定义不同的单位类型每种类型将定义一个标准单位作为基准`,
"Measurement Unit": `Measurement Unit`,
"Measurement families": `度量组`,
"Measurements": `计量单位`,
"Measurements info": `测量单位是测量相关值的实际单位用作测量同类数量的标准。`,
"Media Name": `媒体名称`,
"Medium.Risk": `Medium Risk`,
"Member": `会员`,
"Menu Management": `菜单管理`,
"MenuManage Add": `新增菜单管理`,
"MenuManage Detail": `菜单管理详细信息`,
"Merge Order": `合并订单`,
"Merge Order Setting": `合单设置`,
"Message Center": `信息中心`,
"Message Detail": `推送详情`,
"Message list": `推送列表`,
"Meta Data": `元数据`,
"Meta Description": `元描述`,
"Method": `方法`,
"Middle Layer": `中间层`,
"Middle name": `中名`,
"Mini Program": `小程序`,
"Mini Program Banner": `小程序横幅`,
"Mini Program QR Code": `小程序二维码`,
"Mins": `分钟`,
"Missing Error Message": `Missing Error Message`,
"Missing email": `Missing email`,
"Missing ip": `Missing ip`,
"Missing validate": `缺少验证`,
"Model Detail": `模型详情`,
"Module": `模块`,
"Monday": `周一`,
"Months": `月`,
"Multi-FactorAuthentication is enabled": `MFA启用`,
"My Orders": `我的订单`,
"My Shipment": `我的发货`,
"My Subscriptions": `我的订阅`,
"My device": `我的设备`,
"Name": `模版名称`,
"New Page": `新建页面`,
"New Return Order": `新退货单`,
"New Sales Order": `新销售订单`,
"New Tag": `新标签`,
"New tax zone": `新增配置`,
"Next Refill Date": `下次订单日期`,
"No Access": `未授权`,
"No restrictions on the same warehouse": `对同一仓库没有限制`,
"No results found": `未找到结果`,
"No technology lock-in": `无技术锁定`,
"No.": `序号`,
"No. of digits after decimal": `货币精度`,
"Node": `节点`,
"Not Started": `Not Started`,
"Note:  These information needs to be updated in Organization Module of Global Setting": `注意：这些信息需要在全局设置的组织模块中更新`,
"Note: The value is filled in as an integer and the priority is greater than 0; The lower the value the higher the priority.": `注：该值以整数形式填写值必须大于0；值越低优先级越高。`,
"Notification": `提醒`,
"Notification SMS": `通知型短信`,
"Number Format": `数字格式`,
"Number format": `数据格式`,
"OKTA": `OKTA`,
"OS": `操作系统`,
"Objects": `对象`,
"Omni-channel": `全渠道`,
"One Time": `一次性`,
"One Year": `一年`,
"Online Store Creation": `快速开店`,
"Online store": `线上商店`,
"Open Platform": `开放平台`,
"Open sidebar": `打开侧边栏`,
"Operation": `操作`,
"Options": `操作`,
"Order": `订单`,
"Order Audit": `订单审核`,
"Order Characteristics": `订单特征`,
"Order Close Time": `截单时间`,
"Order Detail": `订单详情`,
"Order Flow Name": `订单流名称`,
"Order Line": `订单行`,
"Order List": `订单列表`,
"Order Management System (OMS)": `订单管理系统（OMS）`,
"Order Management System (OMS) description": `它主要管理销售订单和退货订单的整个生命周期。OMS平台为管理来自多个销售渠道的订单提供了一个单一、集中的系统。它简化了客户的购买流程并使管理订单、库存、履行和退货变得更高效。`,
"Order Metrics": `订单指标`,
"Order Monitor Details": `订单监控详情`,
"Order Setting": `订单设置`,
"Order With 0 Price": `0元订单`,
"Order date": `订购日期`,
"Order export status": `订单导出状态`,
"Order flows.description": `订单流允许将一组状态组合并排列成逻辑订单流。`,
"Order source": `订单来源`,
"Order with Promotion": `折扣订单`,
"Order.Total amount": `总金额`,
"Orders": `订单`,
"Orders with Voucher": `优惠券订单`,
"Orders.Card type": `卡类型`,
"Org": `组织管理`,
"Org Code": `组织编码`,
"Org Detail": `组织详情`,
"Org Management": `组织管理`,
"Org Management info": `主要为平台提供组织管理能力包括组织、品牌、用户、角色、公司、设置、权限等管理。`,
"Org Name": `组织名称`,
"Org Setting": `组织设置`,
"Org Setting info": `为平台提供组织管理能力。`,
"Org Type": `组织类型`,
"Organizations": `组织`,
"Original URL": `原始网址`,
"Outbound Management": `出库管理`,
"Ownership": `所有权`,
"PCDP100000": `您确定要删除标签 {arg1} 吗？`,
"PCDP100001": `您确定要删除段 {arg1} 吗？`,
"PFE100000": `您确定要申请保留订单{arg1}吗？`,
"PFE100001": `您确定要解决订单 {arg1} 的保留问题吗？`,
"PFE100002": `您确定要删除{arg1}仓库吗？`,
"PFE100003": `您确定要删除{arg1}仓库规则吗？`,
"PFE100004": `您确定删除{arg1}物流规则吗？`,
"PGS100000": `您确定要激活用户 {arg1} 吗？`,
"PGS100001": `您确定要停用用户 {arg1} 吗？`,
"PGS1000018": `您确定禁用 DTC 跟踪吗？`,
"PGS100002": `您确定要重新发送 {arg1} 的密码电子邮件吗？`,
"PGS100003": `您确定要删除 {arg1} 吗？`,
"PGS100004": `您确定要删除 {arg1} 吗？`,
"PGS100005": `您确定启用 {arg1} 吗？`,
"PGS100006": `您确定要删除 {arg1} 吗？`,
"PGS100007": `您确定要复制 {arg1} 吗？`,
"PGS100008": `您确定要删除 {arg1} 吗？`,
"PGS100009": `您确定启用 DTC 跟踪吗？`,
"PGS100010": `您确定要删除 {arg1} 吗？`,
"PGS100011": `您确定启用 {arg1} 吗？`,
"PGS100012": `您确定启用 {arg1} 吗？`,
"PGS100013": `您确定启用 {arg1} 吗？`,
"PGS100014": `您确定要删除 {arg1} 吗？`,
"PGS100015": `您确定启用 {arg1} 吗？`,
"PGS100016": `您确定启用 {arg1} 吗？`,
"PGS100017": `您确定禁用 {arg1} 吗？`,
"PGS100019": `您确定禁用 {arg1} 吗？`,
"PGS100020": `您确定禁用 {arg1} 吗？`,
"PGS100021": `您确定禁用 {arg1} 吗？`,
"PGS100022": `您确定禁用 {arg1} 吗？`,
"PGS100023": `您确定禁用 {arg1} 吗？`,
"PHYSICAL": `物理仓`,
"PMA100000": ``,
"PMA100001": `您确定要暂停活动 {arg1} 吗？`,
"PMA100002": `您确定要重新启动广告活动 {arg1} 吗？`,
"POWERFUL MARKETING AUTOMATION": `强大的营销自动化系统`,
"PSE100000": `您确定要删除 {arg1} 吗？`,
"PSE100001": `您确定要删除 {arg1} 订单流吗？`,
"PSE100002": `您确定要完成订单 {arg1} 吗？`,
"PSE100003": `您确定拒绝退货订单 {arg1} 吗？`,
"PSE100004": `您确定确认退货订单 {arg1} 已发货吗？`,
"PSE100005": `您确定确认已收到退货订单 {arg1} 吗？`,
"PSE100006": `将第一句更改为：对于退货订单 {arg1}，`,
"PSE100007": `您确定要删除 {arg1} 频率吗？`,
"PSE100008": `您确定要暂停订阅 {arg1} 吗？`,
"PSE100009": `您确定要重新启动订阅 {arg1} 吗？`,
"PSE100010": `您确定订阅 {arg1} 处于非活动状态吗？`,
"PSE100011": `您确定立即交付订阅 {arg1} 吗？`,
"PSE100012": `您确定禁用 {arg1} 吗？`,
"PSE100013": `您确定启用 {arg1} 吗？`,
"PSE100014": `您确定从购物车中删除 {arg1} 吗？`,
"PSE100015": `您确定从购物车中删除所有产品吗？`,
"PSE100016": ``,
"PSE100017": `您确定禁用热门建议 {arg1} 吗？`,
"PSE100018": `您确定启用标签 {arg1} 吗？`,
"PSE100019": `您确定要结束优惠券 {arg1} 吗？`,
"PSE100020": `您确定批准退货单 {arg1} 吗？`,
"PSE100021": `您确定启用热门建议 {arg1} 吗？`,
"PSE100024": `您确定禁用标签 {arg1} 吗？`,
"PSE100025": `您确定禁用所有热门搜索吗？`,
"PSE100026": `您确定启用所有热门搜索吗？`,
"PSP Payment status": `PSP 付款状态`,
"Page Categories": `页面类`,
"Page Categories Name": `页面类别名称`,
"Page List": `页面列表`,
"Page Name": `页面名称`,
"Page Type": `页面模板`,
"Pages": `页面`,
"Parcellab": `Parcellab`,
"Parent category": `父级类目`,
"Partially delivered": `部分交付`,
"Partner": `合作伙伴`,
"Partner Detail": `合作伙伴详情`,
"Partner List": `合作伙伴列表`,
"Partner Type": `合作伙伴类型`,
"Partner.Address": `地址`,
"Partner.Description": `描述`,
"Partner.Latitude": `纬度`,
"Partner.Longitude": `经度`,
"Partner.Partner Address": `地址`,
"Partner.Partner ID": `合作伙伴ID`,
"Partner.Partner Name": `合作伙伴名称`,
"Partner.Partner Type": `类型`,
"Partner.Prescriber status": `状态`,
"Partner.Remove from partner list?": `从合作伙伴列表中移除？`,
"Partner.Type ID": `类型ID`,
"Partner.Type Name": `类型名称`,
"Partners": `伙伴`,
"Pass": `成功`,
"Password": `密码`,
"Paused": `暂停`,
"Payload": `有效载荷`,
"Payment": `支付信息`,
"Payment Settings": `支付设置`,
"Payment amount": `支付金额`,
"Payment successful": `支付成功`,
"Pending Review": `待审核`,
"Permissions": `权限`,
"Personal Information": `个人信息`,
"Personalize your contents": `定制化您的内容`,
"Pesonalized Content Marketing": `个性化内容营销`,
"Pet Detail": `宠物详情`,
"Phone": `电话`,
"Phone Number": `电话号码`,
"Physical": `物理仓`,
"Physical Warehouse Name": `物理仓名称`,
"Platform": `平台`,
"Platform type": `平台类型`,
"Please Select": `请选择`,
"Please confirm to execute which audit option.": `请确认执行以下哪个审核选项。`,
"Please enter any questions you want to enter": `请输入您想输入的任何问题`,
"Please enter correct email": `请输入正确邮箱`,
"Please enter the correct URL": `请输入正确的网址`,
"Please enter your phone number": `请输入电话号码`,
"Please input Account!": `请选择账号！`,
"Please input Application": `应用名`,
"Please input Application Name!": `请输入应用名称`,
"Please input Client ID!": `请输入用户ID`,
"Please input Client Secret!": `请输入Client Secret`,
"Please input Description!": `请输入描述`,
"Please input Email!": `请输入邮箱！`,
"Please input First Name!": `请输入名！`,
"Please input Last Name!": `请输入姓！`,
"Please input Legal Entity!": `请输入法律实体！`,
"Please input Length!": `请输入长度`,
"Please input Number format!": `请输入编号格式`,
"Please input Phone Number!": `请输入电话号码！`,
"Please input Role Name!": `请输入角色名称！`,
"Please input Store name!": `请输入店铺名称!`,
"Please input URL": `请输入URL`,
"Please input Value!": `请输入值`,
"Please input default value": `请输入默认值`,
"Please input prefix": `请输入前缀`,
"Please input suffix": `请输入后缀`,
"Please input task ID ": `请输入收件人联系方式`,
"Please search plan name": `请搜索活动计划名称`,
"Please select City!": `请选择城市！`,
"Please select Country!": `请选择国家！`,
"Please select Currency format!": `请选择货币类型！`,
"Please select Date format!": `请选择日期格式！`,
"Please select Default Weight!": `请选择默认重量！`,
"Please select Digit grouping!": `请选择数据分组！`,
"Please select Expires!": `请选择过期时间！`,
"Please select Language!": `请选择语言！`,
"Please select No of digits after decimal!": `请选择货币精度！`,
"Please select Region": `请选择地区`,
"Please select Tag": `请选择标签`,
"Please select Time format!": `请选择时间格式！`,
"Please select Unit System!": `请选择系统单位！`,
"Please select User Type!": `请选择用户类型！`,
"Please select attribute name": `请选择属性名称`,
"Points": `积分`,
"Points of Sales": `POS`,
"Post Content": `博客内容`,
"Post Detail": `博客详情`,
"Postal Address": `邮寄地址`,
"Postcode": `邮编`,
"Posts": `博客`,
"Pre shipment": `发货前`,
"Preffered language": `语言`,
"Preview": `预览`,
"Preview on mobile": `移动端预览`,
"Pricing method": `计价方式`,
"Primary Goal": `主要目标`,
"Principal Attribute": `主体属性`,
"Priority": `优先级`,
"Process Canvas": `流程画布`,
"Process Category": `流程类别`,
"Process Description": `流程描述`,
"Process Name": `流程名称`,
"Process Name Group": `状态/名称/开始和结束时间`,
"Process Order": `订单业务`,
"Process Return Order": `处理逆向订单`,
"Process status": `流程状态`,
"Process type": `流程类型`,
"Product": `产品`,
"Product Categories": `产品类别`,
"Product Category": `产品类目`,
"Product Categrey": `产品分类`,
"Product Detail": `产品详情`,
"Product List": `产品列表`,
"Product Restrictions": `商品限制`,
"Product Search": `产品搜索`,
"Product category": `产品类目`,
"Product is required": `产品为必填项`,
"Profile": `用户详情`,
"Profile Attributes": `用户详情`,
"Promotion": `折扣`,
"Promotion Detail": `促销详情`,
"Promotion calculation": `促销计算`,
"Promotional SMS": `促销型SMS`,
"Promotions.delete": `删除`,
"Promotions.start": `重新启动`,
"Promotions.stop": `停止`,
"Promotions.termination": `终止`,
"Provide suggestions when consumers enter address": `当消费者输入地址时提供建议`,
"Provider": `提供者`,
"Province": `省份`,
"Publish": `发布`,
"Publish Date": `发布日期`,
"Published": `已发布`,
"Push Category": `推送类型`,
"Push Channel": `推送渠道`,
"Push again": `再推一次`,
"Q&A Chatbot": `问答聊天机器人`,
"Q&A Chatbot tips": `有关DTC平台的任何问题，请随时询问`,
"QR Code Management": `二维码管理`,
"Quality": `质量`,
"Quality Base": `按数量`,
"Quantity must be greater than 1": `数量必须大于1`,
"Qucik Task": `快速任务`,
"Quick Task": `快速任务`,
"R12PurchaseAmount": `过去12个月购买金额`,
"R12PurchaseFrequency": `过去12个月购买次数`,
"RUNNING": `运行中`,
"Random Number": `随机数`,
"Randomness (temperature)": `随机性（温度）`,
"Randomness tips": `值越高，回复越随机`,
"Rate": `税率`,
"Re-try requests automatically": `自动重试请求`,
"Read Access": `只读`,
"Real Time Edit": `实时编辑`,
"Recommendation History": `推荐历史记录`,
"Recommended:180 days(6 months)": `180天(6个月)`,
"Reduce technical debt": `减少技术债务`,
"Reference ID": `参考编号`,
"Referrals": `参考`,
"Refresh Token": `刷新Token`,
"Region": `区域`,
"Region / Langeuage": `区域/语言`,
"Region / Language": `地区 / 语言`,
"Region / Language info": `为店铺设置统一的地区  语言和时区配置`,
"Region Restrictions": `地区限制`,
"Region/ Language": `区域 / 语言`,
"Reject": `拒绝`,
"Related Contents": `相关内容`,
"Remain": `剩余`,
"Renewal fee": `续费`,
"Repeated": `重复`,
"Reply Content": `回复内容`,
"Request Detail": `请求详情`,
"Request ID": `请求ID`,
"Request Time": `Request Time`,
"Request Url": `请求网址`,
"Request body": `请求正文`,
"Resend Password Email": `密码重置`,
"Resend in": `重发`,
"Resources": `资源`,
"Response": `回复`,
"Response body": `响应体`,
"Return Order": `逆向订单`,
"Reviewing": `审核中`,
"Reward Detail": `奖励详情`,
"Reward History": `奖励历史记录`,
"Reward Settings": `奖励设置`,
"Risk Level Setting": `风险等级设置`,
"Role": `角色`,
"Role Code": `角色编码`,
"Role Name": `角色名称`,
"Role Name/Role code": `角色名称/角色code`,
"Roles": `角色管理`,
"Roles that belong to the current application": `当前应用下的角色列表`,
"Rotate": `旋转`,
"Rule Name": `规则名称`,
"Rule Setting": `规则设置`,
"Running": `运行中`,
"SCDP100000": `事件{arg1} 已被成功添加。`,
"SCDP100001": `事件属性{arg1} 已被成功添加。`,
"SCDP100002": `主要属性{arg1} 已被成功添加。`,
"SCDP100003": `业务属性{arg1} 已被成功添加。`,
"SCDP100004": `标签{arg1} 已被成功修改`,
"SCDP100005": `标签{arg1} 已被成功删除`,
"SCDP100006": `标签{arg1} 已被成功更新`,
"SCDP100007": `标签{arg1} 已被成功添加`,
"SCDP100008": `细分{arg1} 已被成功添加`,
"SCDP100009": `细分{arg1} 已被成功删除`,
"SCDP100010": `细分{arg1} 已被成功修过`,
"SCDP100011": `细分{arg1} 已被成功添加`,
"SCDP100012": `工作{arg1} 已成功暂停/运行`,
"SCE100000": `主题已被切换至{arg1}。`,
"SCE100001": `博客{arg1} 已被成功复制。`,
"SCE100002": `博客{arg1}已被成功删除。`,
"SCE100003": `博客{arg1}已被成功发布。`,
"SCE100004": `博客{arg1}已被成功存储为草稿。`,
"SCE100005": `博客{arg1} 已被成功下线。`,
"SCE100006": `博客类别{arg1} 已被成功创建。`,
"SCE100007": `博客类别{arg1} 已被成功删除。`,
"SCE100008": `博客标签{arg1}已被成功创建。`,
"SCE100009": `博客标签{arg1}已被成功删除。`,
"SCE100010": `页面{arg1}已被成功发布。请注意该页面在审核通过后才会展示在消费者端。`,
"SCE100011": `页面{arg1}已被成功发布。`,
"SCE100012": `页面{arg1}已被成功审核。`,
"SCE100013": `页面{arg1}已成功下线。`,
"SCE100014": `页面{arg1}已成功上线。`,
"SCE100015": `页面{arg1}已被成功删除。`,
"SCE100016": `页面文件夹{arg1}已被成功创建。`,
"SCE100017": `页面文件夹{arg1}内容已被成功更新。`,
"SCE100018": `页面文件夹{arg1}已被成功删除。`,
"SCE100019": `集合数据{arg1}已被成功删除。`,
"SCE100020": `集合数据{arg1}已被成功存储为草稿。`,
"SCE100021": `集合数据{arg1}已被成功发布。`,
"SCE100022": `组件已被成功同步。`,
"SCE100023": `集合模版{arg1}已被成功创建。`,
"SCE100024": `集合模版{arg1}已被成功删除。`,
"SCE100025": `集合模版{arg1}已被成功更新。`,
"SCE100026": `组件模版{arg1}已被成功创建。`,
"SCE100027": `组件模版{arg1}已被成功删除。`,
"SCE100028": `组件模版{arg1}已被成功更新。`,
"SCE100029": `页面模版{arg1}已被成功创建。`,
"SCE100030": `页面模版{arg1}已被成功删除。`,
"SCE100031": `页面模版{arg1}已被成功更新。`,
"SCE100032": `页面模版元数据已被成功更新。`,
"SCE100033": `资产类别{arg1}已被成功创建。`,
"SCE100034": `资产类别{arg1}已被成功更新。`,
"SCE100035": `资产类别{arg1}已被成功删除。`,
"SCE100036": `资产{arg1}已成功上传。`,
"SCE100037": `资产{arg1}已被成功删除。`,
"SCE100038": `元数据{arg1}已被成功删除。`,
"SCE100039": `元数据{arg1}已被成功更新。`,
"SCE100040": `元数据{arg1}已被成功创建。`,
"SCE100041": `Webhook 信息已被成功更新。`,
"SCE100042": `Webhook{arg1}信息已被成功删除。`,
"SCE100043": `CDN{arg1}已被成功{arg2}。`,
"SENDGRID": `SENDGRID`,
"SEO Title": `元标题`,
"SEO/SEM": `搜索引擎优化/搜索引擎营销`,
"SFE100000": `订单{arg1}已成功创建。`,
"SFE100001": `订单{arg1} 已成功添加新标签{arg2}。`,
"SFE100002": `订单{arg1} 已成功通过验证。`,
"SFE100003": `订单{arg1} 已成功被拒绝。`,
"SFE100004": `订单{arg1} 已成功暂停。`,
"SFE100005": `订单{arg1} 已成功开启。`,
"SFE100006": `订单{arg1}已成功被更新为back order。`,
"SFE100007": `订单{arg1} 已成功删除。`,
"SFE100008": `订单{arg1} 的备注已成功修改。`,
"SFE100009": `发票{arg1} 已成功下载。`,
"SFE100010": `审核订单{arg1}已成功创建。`,
"SFE100011": `Back order{arg1}已成功创建。`,
"SFE100012": `{arg1}已成功修改。`,
"SFE100013": `相关出货号{arg1}已成功复制。`,
"SFE100014": `出库货物{arg1}的备注已成功更新。`,
"SFE100015": `{arg1}已成功出货。`,
"SFE100016": `{arg1}已成功进货。`,
"SFE100017": `{arg1}已成功创建。`,
"SFE100018": `入库货物{arg1}的备注已成功更新。`,
"SFE100019": `新仓库{arg1}已成功创建。`,
"SFE100020": `仓库{arg1}已成功删除。`,
"SFE100021": `{arg1}已成功与{arg2}联动。`,
"SFE100022": `新仓库寻源规则{arg1}已成功添加。`,
"SFE100023": `{arg1}已成功修改。`,
"SFE100024": `{arg1}已成功删除。`,
"SFE100025": `新逻辑寻源规则{arg1}已成功添加。`,
"SFE100026": `{arg1}已成功修改。`,
"SFE100027": `{arg1}已成功删除。`,
"SFE100028": `订单{arg1}中的备注已成功添加。`,
"SFE100029": `{arg1} 已成功禁用。`,
"SFE100030": `{arg1} 已成功启用。`,
"SFE100031": `仓库 {arg1} 已成功更新。`,
"SGS100000": `{arg1}已被成功更新`,
"SGS100001": `地区已被成功更新至{arg1}`,
"SGS100002": `时区已被成功更新至{arg1}，则Store Front和DTC Platform时间现在将根据最新时区显示。`,
"SGS100003": `该语言已被成功更新至{arg1}`,
"SGS100004": `货币符号已被成功更新至{arg1}，则Store Front和DTC Platform时间现在将根据最新货币符号显示。温馨提醒：不涉及到货币汇率转化`,
"SGS100005": `货币格式已被成功更新至{arg1}，则Store Front和DTC Platform时间现在将根据最新货币显示。`,
"SGS100006": `小数点后的位数已被成功更新至{arg1}，现在将根据最新的小数点后位数显示Store Front和DTC Platform货币值。
温馨提示：通过四舍五入保留小数位数`,
"SGS100007": `日期和时间格式已被成功更新至{arg1}，Store Front和DTC Platform时间现在将根据最新的日期和时间形式显示。`,
"SGS100008": `小数点后的位数已被成功更新至{arg1}，现在将根据最新的小数点后位数显示Store Front和DTC Platform非货币值。
温馨提示：通过四舍五入保留小数位数`,
"SGS100009": `数字显示格式已被成功更新至{arg1}，Store Front和DTC Platform非货币值现在将根据最新的数字格式显示。`,
"SGS100010": `{arg1}已被成功更新。`,
"SGS100011": `度量系列已被成功添加。`,
"SGS100012": `度量单位已被成功添加。`,
"SGS100013": `度量系列已被成功编辑。`,
"SGS100014": `度量单位已被成功编辑。`,
"SGS100015": `度量系列已被成功删除。`,
"SGS100016": `度量单位已被成功删除。`,
"SGS100017": `转换方法已被成功添加。`,
"SGS100018": `转换方法已被成功删除。`,
"SGS100019": `转换方法已被成功编辑。`,
"SGS100020": `转换方法{arg1}状态已被成功{arg2}。`,
"SGS100021": `发货模板已被成功添加。`,
"SGS100022": `发货模板已被成功编辑。`,
"SGS100023": `发货模板已被成功复制。`,
"SGS100024": `发货模板已被成功删除。`,
"SGS100025": `DTC跟踪已被成功编辑。`,
"SGS100026": `DTC跟踪状态已被成功{arg1} 。`,
"SGS100027": `物流公司{arg1}已被成功添加。`,
"SGS100028": `物流公司{arg1}已被成功编辑。`,
"SGS100029": `物流公司{arg1}已被成功删除。`,
"SGS100030": `物流公司{arg1}状态已被成功{arg2}。`,
"SGS100031": `组织{arg1}已被成功添加。`,
"SGS100032": `组织{arg1}已被成功编辑。`,
"SGS100033": `组织{arg1}状态已被成功{arg2}。`,
"SGS100034": `税区 {arg1} 已被成功更新。`,
"SGS100035": `税区 {arg1} 已被成功编辑。`,
"SGS100036": `税区已被成功删除。`,
"SGS100037": `税区状态已被成功{arg2} 。`,
"SGS100038": `税务类别已被成功更新。`,
"SGS100039": `{arg1}已被成功编辑。`,
"SGS100040": `{arg1}状态已被成功{arg2}。`,
"SGS100041": `新用户{arg1}已成功添加。`,
"SGS100042": `新密码邮件已成功发送给{arg1}。`,
"SGS100043": `用户{arg1}已成功激活。`,
"SGS100044": `用户{arg1}已成功停用。`,
"SGS100045": `{arg1}已成功复制。`,
"SGS100046": `新功能{arg1}已成功添加。`,
"SGS100047": `{arg1}已成功开启。`,
"SGS100048": `{arg1}已成功关闭。`,
"SGS100049": `{arg1} 已成功编辑。`,
"SGS100050": `新职位{arg1}已成功添加。`,
"SGS100051": `新成员{arg1}已成功添加。`,
"SGS100052": `{arg1}已成功任命为{arg2}。`,
"SGS100053": `{arg1}已成功删除。`,
"SGS100054": `新密码已成功添加。`,
"SGS100055": `Key {arg1} 已成功翻译。`,
"SMA100000": `活动{arg1} 已成功添加。`,
"SMA100001": `任务{arg1} 已审核通过。`,
"SMA100002": `任务{arg1} 未审核通过。`,
"SMA100003": `画布{arg1} 已成功暂停。`,
"SMA100004": `画布{arg1} 已成功运行。`,
"SMA100005": `任务{arg1} 已成功发布。`,
"SMA100006": `活动 {arg1} 已审核通过`,
"SMA100007": `活动{arg1} 未审核通过`,
"SMA100008": `任务{arg1} 已成功暂停`,
"SMA100009": `画布{arg1} 已成功添加。`,
"SMA100010": `画布{arg1} 已保存为草稿。`,
"SMA100011": `画布{arg1} 已审核通过。`,
"SMA100012": `画布{arg1} 未审核通过。`,
"SMA100013": `模板{arg1} 已成功添加`,
"SMA100014": `模板{arg1} 已成功修改`,
"SMA100015": `模板{arg1} 已成功修改`,
"SMA100016": `模板{arg1} 已成功删除`,
"SMA100017": `模板{arg1} 已成功添加`,
"SMA100018": `模板{arg1} 已成功修改`,
"SMA100019": `模板{arg1} 已成功修改`,
"SMA100020": `模板{arg1} 已成功删除`,
"SMA100021": `信息{arg1} 已成功设定`,
"SMA100022": `任务{arg1} 已成功运行。`,
"SMA100023": `画布{arg1} 已成功保存为草稿。`,
"SMA100024": `画布{arg1} 已成功更新。`,
"SMA100025": `画布{arg1} 已成功删除。`,
"SMA100026": `任务 {arg1} 已成功保存为草稿。`,
"SMA100027": `任务{arg1} 已成功更新。`,
"SMA100028": `任务{arg1} 已成功删除。`,
"SMS": `SMS`,
"SMS Template": `SMS模板`,
"SMS Template Content": `SMS模板内容`,
"SMS Template Description": `SMS模板描述`,
"SMS Template Detail": `SMS短信详情`,
"SMS Template Name": `SMS模板名称`,
"SMS-ALI": `SMS-阿里`,
"SSE100000": `备注{arg1}已成功保存。`,
"SSE100001": `{arg1} 订单发票已成功下载。`,
"SSE100002": `{arg1} 订单已成功取消。`,
"SSE100003": `{arg1} 订单已成功发货。`,
"SSE100004": `{arg1} 订单已完成。`,
"SSE100005": `{arg1} 订单已成功通过审核。`,
"SSE100006": `{arg1} 订单已成功拒绝。`,
"SSE100007": `订单信息已成功被导出。`,
"SSE100008": `{arg1}订单已成功申请退货单 {arg2} 。`,
"SSE100009": `{arg1}订单退货申请已成功批准。`,
"SSE100010": `{arg1}订单退货申请已成功拒绝。`,
"SSE100011": `{arg1}退货订单已成功确认发出。`,
"SSE100012": `{arg1}退货订单已成功确认收到。`,
"SSE100013": `{arg1}已成功退款{arg2}。`,
"SSE100014": `一般订单设置已成功更改。`,
"SSE100015": `电子邮件阻止列表已成功更改。`,
"SSE100016": `{arg1}选择已保存。`,
"SSE100017": `订单号格式已成功更改。`,
"SSE100018": `新的sales order flow {arg1}已成功添加。`,
"SSE100019": `新的return order flow {arg1}已成功添加。`,
"SSE100020": `order flow {arg1}已成功更新。`,
"SSE100021": `order flow {arg1}已成功删除。`,
"SSE100022": `商品{arg1}已成功添加到购物车。`,
"SSE100023": `商品{arg1}已成功从购物车删除。`,
"SSE100024": `{arg1} 订阅已成功暂停。`,
"SSE100025": `{arg1} 订阅已成功重启。`,
"SSE100026": `{arg1} 订阅已成功取消。`,
"SSE100027": `{arg1} 订阅送货地址已成功修改。`,
"SSE100028": `{arg1} 订阅将在{arg2}自动生成下一单。`,
"SSE100029": `{arg1} 订单已成功生成。`,
"SSE100030": `订阅已成功导出。`,
"SSE100031": `{arg1}的修改已成功保存。`,
"SSE100032": `{arg1}已被成功删除。`,
"SSE100033": `新频率{arg1}已成功添加。`,
"SSE100034": `订阅号格式已成功修改。`,
"SSE100035": `谷歌支付已成功关闭。`,
"SSE100036": `谷歌支付已成功开启。`,
"SSE100037": `谷歌支付已成功修改。`,
"SSE100038": `{arg1}发票已成功生成。`,
"SSE100039": `{arg1}发票已成功下载。`,
"SSE100040": `商品类别{arg1}已成功开启。`,
"SSE100041": `商品类别{arg1}已成功关闭。`,
"SSE100042": `商品类别{arg1}的修改已被成功保存。`,
"SSE100043": `二级商品类别{arg1}已被成功添加到商品类别{arg1}下。`,
"SSE100044": `商品类别{arg1}已成功删除。`,
"SSE100045": `商品类别{arg1}已成功添加。`,
"SSE100046": `小程序已成功开启。`,
"SSE100047": `小程序已成功关闭。`,
"SSE100048": `站点导航地图导出成功。`,
"SSE100049": `用户偏好改动已保存成功。`,
"SSE100050": `商品{arg1}已成功添加到购物车。`,
"SSE100051": `商品{arg1}已成功从购物车中删除。`,
"SSE100052": `所有商品已成功从购物车中清除。`,
"SSE100053": `语言已成功更改为{arg1}。`,
"SSE100054": `{arg1}伙伴已成功被添加。`,
"SSE100055": `{arg1}伙伴已成功被移除。`,
"SSE100056": `文件{arg1}已成功被下载。`,
"SSE100057": `运输 {arg1} 已经成功安排`,
"SSE100058": `发票{arg1} 已经成功下载`,
"SSE100059": `导出{arg1} 成功`,
"SSE100060": `开启{arg1} 成功.`,
"SSE100061": `修改{arg1} 成功.`,
"SSE100062": `导出{arg1} 成功`,
"SSE100063": `添加产品{arg1} 成功`,
"SSE100064": `修改产品{arg1} 成功`,
"SSE100065": `上架产品{arg1} 成功`,
"SSE100066": `删除产品{arg1} 成功`,
"SSE100067": `添加产品{arg1} 成功`,
"SSE100068": `添加搜索{arg1} 成功`,
"SSE100069": `删除搜索{arg1} 成功`,
"SSE100070": `搜索{arg1} 显示成功`,
"SSE100071": `所有搜索{arg1} 显示成功`,
"SSE100072": `添加同义词{arg1} 成功`,
"SSE100073": `删除同义词{arg1} 成功`,
"SSE100074": `修改同义词{arg1} 成功`,
"SSE100075": `绑定描述成功`,
"SSE100076": `绑定属性成功`,
"SSE100077": `添加描述{arg1} 成功`,
"SSE100078": `修改描述{arg1} 成功`,
"SSE100079": `删除描述{arg1} 成功`,
"SSE100080": `添加属性{arg1} 成功`,
"SSE100081": `修改属性{arg1} 成功`,
"SSE100082": `删除属性{arg1} 成功`,
"SSE100083": `添加属性筛选{arg1} 成功`,
"SSE100084": `删除属性筛选{arg1} 成功`,
"SSE100085": `添加标签筛选{arg1} 成功`,
"SSE100086": `删除标签筛选{arg1} 成功`,
"SSE100087": `删除个性筛选{arg1} 成功`,
"SSE100088": `排序方式{arg1} 开启成功`,
"SSE100089": `搜索平台{arg1} 开启成功`,
"SSE100090": `搜索平台{arg1} 修改成功`,
"SSE100091": `删除顾客{arg1} 成功`,
"SSE100092": `修改顾客标签{arg1} 成功`,
"SSE100093": `标签{arg1} 添加成功`,
"SSE100094": `标签{arg1} 开启成功`,
"SSE100095": `标签{arg1} 添加顾客{arg2}成功`,
"SSE100096": `标签{arg1} 删除顾客{arg2}成功`,
"SSE100097": `代金券{arg1} 添加成功`,
"SSE100098": `代码{arg1} 生成成功`,
"SSE100099": `代金券{arg1} 已暂停使用`,
"SSE100100": `促销活动{arg1} 添加成功`,
"SSE100101": `促销活动{arg1} 已终止`,
"SSE100102": `模板{arg1} 添加成功`,
"SSE100103": `模板{arg1} 已被成功删除`,
"SSE100104": `账户{arg1} 添加成功`,
"SSE100105": `账户{arg1} 修改成功`,
"SSE100106": `账户{arg1} 删除成功`,
"SSE100107": `排序方式{arg1} 关闭成功`,
"SSE100108": `欺诈检测标准已成功保存。`,
"SSE100109": `订单审核已更改成功。`,
"SSE100110": `订单审核风险等级已成功更改。`,
"SSE100111": `0 元订单审核已成功更改。`,
"SSE100112": `订单审核产品类别变更成功。`,
"SSE100113": `搜索平台{arg1} 关闭成功`,
"SSE100114": `Ip blocklist 中的修改已保存。`,
"SSE100115": `标签{arg1} 开启成功`,
"SSE100116": `成功禁用 {name}。`,
"SSE100117": `产品{arg1}已成功下架`,
"SSE100118": `热门搜索 {arg1} 已成功隐藏`,
"SSE100119": `热门搜索 已全部隐藏成功`,
"SSE100120": `频率 {arg1} 已成功禁用。`,
"SSE100121": `频率 {arg1} 已成功启用。`,
"SSE100122": `评论 {arg1} 已成功删除。`,
"SSE100123": `折扣代码 {arg1} 已成功重新启动。`,
"SSE100124": `折扣代码 {arg1} 已成功暂停。`,
"STEP": `步骤`,
"Sales Experience": `销售体验`,
"Sales Order": `销售订单`,
"Sales Order Flow": `销售订单流程`,
"Same Warehouse Limit": `同仓限制`,
"Saturday": `周六`,
"Save": `保存`,
"Save Draft": `保存为草稿`,
"Scenario Description": `场景描述`,
"Scenario Link": `场景链接`,
"Scheduled": `定时发布`,
"Search Engine Preview": `搜索引擎样式预览`,
"Search Media Name": `搜索媒体名字`,
"Search Warehouse Name": `搜索仓库名称`,
"Search for posts": `依据博客名称进行搜索`,
"Search functionality's introduction.": `我们的搜索功能允许您快速配置顶部建议和同义词。它使消费者能够输入关键词、短语或其他搜索条件来检索相关结果。`,
"Search sourcing rule name": `搜索寻源规则名称`,
"Second Goal": `次要目标`,
"Secret Value": `密钥`,
"Security": `安全`,
"Segments Detail": `人群包详情`,
"Segments List": `人群包列表`,
"Segments Management": `人群包管理`,
"Select  Logical  warehouse": `选择逻辑仓`,
"Select  Logistics Company": `选择物流公司`,
"Select  Physical warehouse": `选择物理仓`,
"Select  Store": `选择店铺`,
"Select Campaign Process Type": `选择活动流程类型`,
"Select Date": `选择日期`,
"Select Number format": `选择编号格式`,
"Select Related Main Warehouse": `选择对应主仓`,
"Select Specific Period": `选择特定时间段`,
"Select product": `选择商品`,
"SelectSpecificPeriod": `选择特定时间段`,
"Selected Subscription": `已选订阅`,
"Self-built": `自建`,
"Seller": `销售店铺`,
"Send To Node": `发送到仓库`,
"Send To Node tip": `生成出库单的订单总量`,
"Send alert notification email automatically": `自动发送警报通知电子邮件`,
"Send exactly what your": `准确发送您的内容`,
"Send this email after the last attempt": `最后一次尝试后发送此电子邮件`,
"Sendgrid Template Name": `SendGrid模板名称`,
"Sequence Number": `序列号`,
"Service Name": `服务名称`,
"Service description": `服务描述`,
"Session": `会话`,
"Session ID": `会话ID`,
"Set Domain": `设置域`,
"Set as guest": `设置为游客`,
"Set rule  for specific products and except for the specified products the other products is used for other logistic company": `为特定商品设置规则除指定的商品外其他商品用于其他物流公司`,
"Set rule for specific regions and except for the specified region the other region is used for other logistic company": `为特定地区设置规则除指定地区外其他地区用于其他物流公司`,
"Set specific weight of all logistics. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.": `设置所有物流的具体权重。权重以整数形式填写且权重大于0；值越高优先级就越高。`,
"Set specific weight of all warehouses. Weight is filled in as an integer and the weight is greater than 0; The higher the value the higher the priority.": `设置所有仓库的具体权重。权重以整数形式填写且权重大于0；值越高优先级就越高。`,
"Set up a welcome series": `让您的营销活动变得简单。通过电子邮件自动化设置欢迎系列细分联系人或使用简单的自动化工作流程来节省时间和金钱。`,
"Set up your own rule": `规则配置`,
"Setting": `环境`,
"Setting Application": `设置应用`,
"Setting your sourcing based on what is most important for the orders that will be processed by this rule.": `根据将由该规则处理的订单中最重要的内容来设置您的寻源。`,
"Setting-Shipping Method": `运费模版`,
"Setting-Shipping Method info": `运费模板配置对整个店铺有效不同的运费模板关联不同的运费规则并与特定货物相关联`,
"Setup a mobile web or IoT application to use platform for Authentication": `设置移动端、Web端或IOT物联网应用程序以使用平台进行统一身份验证`,
"Sharpen": `锐化`,
"Shipment": `发货`,
"Shipping": `发货`,
"Shipping Method": `配送方式`,
"Shipping Method Please input": `请输入模版名称`,
"Shipping Method info": `运费模版是顾客在支付订单时可以选择的配送服务。它通常基于与您合作的运营商并确定购物者支付的运费以及他们收到货物的速度。`,
"Shipping Methods": `配送方式`,
"Shipping address": `物流地址`,
"Shipping to": `运送地区`,
"Shipping.Consumer": `消费者`,
"Shipping.Consumer:": `授权码：`,
"Shipping.Edit Express 100 setting": `编辑快递100设置`,
"Shipping.Express 100": `快递100`,
"Shipping.Key": `键`,
"Shipping.Key:": `键：`,
"Shipping.Please input callbackUrl": `请输入回调地址`,
"Shipping.Please input consumer": `请输入消费者姓名`,
"Shipping.Please input key": `请输入键`,
"Shipping.Pull URL": `请求地址`,
"Shipping.Pull URL:": `请求地址：`,
"Shipping.Query URL": `查询地址:`,
"Shipping.Query URL:": `查询地址：`,
"Shipping.SCallback URL": `回调地址：`,
"Shipping.SCallback URL:": `回调地址`,
"Shipping.SEQ": `次数`,
"Shipping.Shipping Setting": `发货设置`,
"Shipping.please input url": `请输入URL`,
"Shipping.please input user name": `请输入用户名`,
"Shop": `商店`,
"Shop Category": `销售类别`,
"Sign-in redirect URIs": `登录重定向URIs`,
"Sign-out redirect URIs": `登出重定向URIs`,
"Signature": `签章`,
"Signed Product Category": `签约产品类目`,
"Signed information": `签约信息`,
"Single Add": `单个新建`,
"Single product in the same warehouse first": `同一仓库中的单个产品`,
"Site Map": `站点地图`,
"Six Months": `六个月`,
"Size": `大小`,
"Smart Operation": `智能运营`,
"Source": `来源`,
"Sourcing Rule": `寻源规则`,
"Sourcing Rule Name": `寻源规则名称`,
"Sourcing rule is to help business parties express": `寻源规则是帮助业务方以系统规则的形式使用这些分配标准并为订单找到最合适的仓库和物流以降低成本
  提高库存资源的利用率。 `,
"Split Order": `拆分订单`,
"Split Order Setting": `拆单设置`,
"Standards and format": `标准格式`,
"Standards and format info": `维护店铺日期、时间、数字的统一显示形式`,
"Start": `开始`,
"Start and end time": `开始和结束时间`,
"Start date": `开始日期`,
"State": `州`,
"States based": `基于州`,
"Status": `状态`,
"Stock availability": `库存可用性`,
"Stocking": `备货`,
"Store": `店铺`,
"Store Basic Configuration": `存储基本配置`,
"Store Basic Information": `店铺基本信息`,
"Store Currency": `货币`,
"Store Details": `店铺详情`,
"Store Name": `店铺名称`,
"Store Region Language": `地区 / 语言`,
"Store Setting": `店铺设置`,
"Store Setting Info": `为商店提供商店配置功能包括基本信息、区域设置、测量、标准格式等。店铺详情/基本信息/ 区域/语言/货币`,
"Store information": `店铺信息`,
"Store name": `店铺名称`,
"Submit": `提交`,
"Subscription Date": `订阅日期`,
"Subscription Detail": `订阅详情`,
"Subscription Setting": `订阅设置`,
"Subscriptions": `订阅`,
"Success": `Success`,
"Summary": `博客小结`,
"SunDay": `周日`,
"Support Subscription": `支持订阅`,
"Switch": `转变`,
"Switch to": `切换到`,
"Symbal": `单位符号`,
"Symbol(eg:km)": `单位符号`,
"Synchronize Data": `同步数据`,
"Synchronize assets info": `同步资产信息`,
"Synchronize contents info including pages": `同步内容信息，包括页面`,
"Synchronize data from Existed Store": `同步现有存储中的数据`,
"Synchronize products info": `同步产品信息`,
"Synchronize store configuration info": `同步商店配置信息`,
"Synchronized Data": `同步数据`,
"Synchronized Data option1": `产品包括产品描述、属性、产品基本信息`,
"Synchronized Data option2": `内容包括内容类型、集合、页面内容和电子邮件`,
"Synchronized Data option3": `资产包括图像、视频、音频和视频`,
"Synchronized Data option4": `店铺基本信息`,
"Synonyms": `同义词`,
"System": `系统`,
"System Menu": `资源管理`,
"Tag Management": `标签管理`,
"Tagging Details": `标签详情`,
"Tagging Setting": `标签设置`,
"Tags": `标签`,
"Tags List": `标签列表`,
"TagsDetail.Add New Option": `新增标签值`,
"TagsDetail.Create Tags": `新增标签`,
"TagsDetail.Input Option Description": `请输入描述`,
"TagsDetail.Input Option Name": `请输入标签值`,
"TagsDetail.Input Tag Label": `请输入标签名称`,
"TagsDetail.Option Name": `值`,
"TagsDetail.Option description": `描述`,
"TagsDetail.Select Tag Type": `请选择标签类型`,
"TagsDetail.Tag Basic Information": `标签基本信息`,
"TagsDetail.Tag Label": `标签名称`,
"TagsDetail.Tag Rules": `标签规则`,
"TagsDetail.Tag Type": `标签类型`,
"TagsDetail.Tags Detail": `标签详情`,
"TagsDetail.updateType.tip": `标签是每日进行更新的。`,
"Take your ecommerce to the next level": `将您的电商平台提升到一个新的水平`,
"Take your ecommerce to the next level description": `您需要的所有功能可以简化从获取产品到销售、运输、库存和退货的所有流程。`,
"Target Language": `目标语言`,
"Task Center": `任务中心`,
"Task Detail": `定时任务详情`,
"Task List": `任务列表`,
"Task List displays all records that were sent or planning to send to consumers.": `消息列表显示已发送或计划发送给消费者的消息。`,
"Tax": `税务配置`,
"Tax Managemen": `税管理`,
"Tax Management": `税费管理`,
"Tax Management info": `为店铺提供税务配置管理主要包括区域税率配置、计税规则配置等`,
"Tax Setting": `税务设置`,
"Tax calculation": `税费设置`,
"Tax class": `税费类型`,
"Tax class label": `税费类型标签`,
"Tax rates": `税率`,
"Tax setting": `税费设置`,
"Tax setting info": `为门店提供税务管理主要包括税区管理、税务设置、税务计算。`,
"Tax zone description": `描述`,
"Tax zone management": `税区管理`,
"Tax zone name": `区域名称`,
"Tax zone type": `区域类型`,
"Template Message": `模板消息`,
"Template Message Detail": `模板消息详情`,
"Template Type": `模板类型`,
"Template name": `模板名称`,
"Templates": `模板`,
"The export report will show in the Export report.": `导出报告将显示在导出报告中。`,
"The maximum period is six months": `最长期限为六个月`,
"The stocking warehouse can share inventory or not but it cannot ship order.": `库存仓库可以共享库存也可以不共享但不能发货。`,
"The whole document dimension and the single item dimension have no restriction that": `不受必须从同一仓库发送的限制。只要它们能满足订单所需的库存就可以发货。`,
"Theme List": `主题列表`,
"Themes": `主题`,
"These are all the resource and api scopes that this application uses.": `DTC Center平台资源列表`,
"Third-party": `三方`,
"This change will apply to future": `此更改将适用于未来`,
"This page is ready to be audited. Do you confirm to publish this page?": `此页面已准备好进行审核。您确认要发布此页面吗？`,
"This product is ready to be published. Do you confirm to publish this product?": `该产品已准备好发布。您确认要发布此产品吗？`,
"This will delete": `这将删除 {arg1}?`,
"This will replace your current theme to": `这会将您当前的主题替换为`,
"Three Months": `三个月`,
"Thursday": `周四`,
"Tier Setting": `等级设置`,
"Time": `时间`,
"Time Now": `当前时间`,
"Time Zone": `时区`,
"Time format": `时间格式`,
"Top Suggestions": `热门建议`,
"Total": `总计`,
"Total Records": `总记录数`,
"Transaction": `交易`,
"Transaction Attribute": `交易属性`,
"Transfer Order": `转移订单`,
"Transformational Functions.three": `为不同语气/客户类型的博客文章、电子邮件活动、社交媒体帖子和广告文案创建营销内容`,
"Transformational Functions": `转换功能`,
"Transformational Functions.one": `根据给定的用户查询有效地搜索数据库，并生成按需见解，包括表格数据库中的图表和可视化`,
"Transformational Functions.subTitle": `通过我们的集成创新解决方案释放您组织的全部潜力。`,
"Transformational Functions.two": `根据复杂文档提供问题的自然语言答案`,
"Transit Method": `配送方式`,
"Transit time": `配送时间`,
"Transition Method": `配送方式`,
"Transition Methods": `配送方式`,
"Transition Methods info": `定义不同的配送服务`,
"Translated": `已翻译`,
"Translation Assistant": `翻译助理`,
"Translation Assistant tips": `翻译助手帮助您翻译该页面的所有页面内容。请在下面选择所需的语言，并注意整个翻译过程可能需要2~3分钟。`,
"Trust Origins": `跨域配置`,
"Tuesday": `周二`,
"Twitter": `推特`,
"Type": `类型`,
"UNKNOWN": `未知错误`,
"URL": `URL`,
"US/Eastern": `United States`,
"Unit Code": `单位代码`,
"Unit System": `系统单位`,
"Unpublish": `取消发布`,
"Untitled": `标签值`,
"Untranslated": `未翻译`,
"Update Return Order": `更新退货单`,
"Update Sales Order": `更新销售订单`,
"Upload Media": `上传媒体`,
"Upload More": `上传更多`,
"Upload a media": `上传媒体`,
"Upload a media tips": `笔记：<ul><li> 1.图像<ul className='list-disc ml-5'><li>支持文件格式：BMP、PNG、JPEG、JPG、GIF、SVG、WEBP、AVIG；</li><li>尺寸≤20M</li></ul></li><li> 2.音频<ul className='list-disc ml-5'><li>支持文件格式：MP3、WMA、WAV、AMR；</li><li>尺寸≤30M</li></ul></li><li> 3.视频<ul className='list-disc ml-5'><li>支持文件格式：MP4、FLV、F4V、AVI；</li><li>尺寸≤200M</li></ul></li><li> 4.文件<ul className='list-disc ml-5'><li>支持文件格式：DOC、DOCX、PPT、CSV、XLS、XLSX、PDF；</li><li>尺寸≤20M</li></ul></li></ul>`,
"Upload digital assets": `上传数字资产`,
"Upload images, videos to stay on-brand with a centralized media library": `上传图像、视频，通过集中媒体库保持品牌形象。`,
"Upload to Catalog": `上传至目录`,
"Uptime": `正常运行时间`,
"Use Google maps service to autocompolete shipping and billing address fields": `使用 Google 地图服务自动填写送货和帐单地址字段`,
"Use Google maps tips": `使用 Google 地图服务自动填写送货和帐单地址字段`,
"User": `用户`,
"User Guide": `用户指南`,
"User List": `用户列表`,
"User Name": `用户名`,
"User Setting": `用户设置`,
"User Type": `用户类型`,
"User already exists": `用户已存在！`,
"Username": `账号`,
"Users": `用户管理`,
"Users that have this apllication directly assigned.": `分配当前应用的用户`,
"Valet Order": `代客下单`,
"Valid period": `有效期`,
"Validate until": `验证直到`,
"Verification Code": `验证码`,
"Version": `版本`,
"Version History": `版本历史`,
"View Order": `查看订单`,
"View Return Order": `查看逆向订单`,
"View full fraud analysis": `查看全部欺诈分析`,
"Volume Base": `按体积`,
"Vouchers": `优惠券`,
"WECHAT_MINI_PROGRAM": `小程序`,
"Warehouse": `仓库`,
"Warehouse Name": `仓库名称`,
"Warehouse Ownership": `仓库所有权`,
"Warehouse Priority": `仓库优先级`,
"Warehouse Role": `仓库角色`,
"Warehouse Sourcing Rule": `仓库寻源规则`,
"Warehouse Sourcing Rule List": `仓库寻源规则列表`,
"Warehouse Sourcing Rules": `仓库寻源规则`,
"Warehouse Usage": `仓库用途`,
"Warehouse name": `仓库名称`,
"Warehouse type": `仓库类型`,
"Warehouses": `仓库`,
"We can't find any item matching your search": `我们找不到任何与您的搜索匹配的产品`,
"We suggest a ": `我们建议`,
"Webhook": `Webhook`,
"Wechat Mini Program": `微信小程序`,
"Wechat Service Account": `Wechat Service Account`,
"Wednesday": `周三`,
"Weight": `权重`,
"Weight Base": `按重量`,
"What Store would you like to create": `您想创建什么商店？`,
"What is your desired domain": `您想要的域名是什么？`,
"What is your desired domain tips": `域名是消费者访问您的在线商店时必须输入的 URL。这个唯一的网址是根据下面选择的国家/地区和语言设置生成的。`,
"When an order contains multiple goods the same goods must be sent from the same warehouse and different goods can be sent from different warehouses.": `当订单包含多个货物时相同的货物必须从同一仓库发送不同的货物可以从不同的仓库发送。`,
"Whole order in the same warehouse": `同一仓库中的整个订单`,
"Zip Code": `邮编`,
"Zone Name": `区域名称`,
"Zone Type": `类型`,
"Zone includes": `包含区域`,
"activityLogs.subTile": `显示用户登录和注销应用程序的日志`,
"add page": `新增`,
"address.Add Field": `新增字段`,
"address.Add new schema": `原数据`,
"address.Edit area": `编辑地区`,
"address.Field Name": `字段名`,
"address.Field area": `字段区域`,
"address.Field display name": `展示名称`,
"address.Format": `编辑人`,
"address.Visualization area": `可视化区域`,
"adyenTip": `Adyen是一个旨在简化全球支付、提供全球支付解决方案的支付平台并覆盖全球所有消费者熟悉并信任的支付方式。`,
"after the user enters the process the goal is  considered to be compleeted": `用户进入流程后认为目标已完成`,
"aiRecoModel.AAFCO": `美国饲料控制官员协会（AAFCO）`,
"aiRecoModel.Detail": `模型详细信息`,
"aiRecoModel.FEDIAF": `欧洲宠物食品工业联合会（FEDIAF）`,
"aiRecoModel.GB": `中华人民共和国国家标准（GB）`,
"aiRecoModel.NRC": `国家研究委员会（NRC）`,
"aiRecoModel.aiRecoModel": `智能推荐模型`,
"aiRecoModel.apply": `应用`,
"aiRecoModel.baseInfo": `基本信息`,
"aiRecoModel.buyHistory": `购买历史记录`,
"aiRecoModel.cancel": `取消`,
"aiRecoModel.drag_to_sort": `拖动以进行排序`,
"aiRecoModel.edit": `编辑模型`,
"aiRecoModel.editorName": `编辑器名称`,
"aiRecoModel.foodDatabase": `食品数据库`,
"aiRecoModel.foodDatabase2": `食品数据库`,
"aiRecoModel.foodDatabaseLabel1": `美国农业部（USDA）食品数据库`,
"aiRecoModel.foodDatabaseLabel2": `日本食品成分标准表`,
"aiRecoModel.foodDatabaseLabel3": `新西兰食品成分数据库`,
"aiRecoModel.foodDatabaseLabel4": `澳大利亚食品成分数据库`,
"aiRecoModel.history.date": `日期`,
"aiRecoModel.history.model": `模型`,
"aiRecoModel.history.modelNameOrId": `模型名称/id`,
"aiRecoModel.history.phone": `电话`,
"aiRecoModel.history.recProductNames": `推荐产品名称`,
"aiRecoModel.history.recomTime": `推荐时间`,
"aiRecoModel.history.skus": `SKU`,
"aiRecoModel.history.userName": `用户名`,
"aiRecoModel.history.userSearchingInput": `用户搜索输入`,
"aiRecoModel.id": `模型ID`,
"aiRecoModel.inputPlaceholder": `模型名称模型ID`,
"aiRecoModel.lastMonth": `上个月`,
"aiRecoModel.lastThreeMonths": `最近3个月`,
"aiRecoModel.lastWeek": `上周`,
"aiRecoModel.lastYear": `去年`,
"aiRecoModel.latestUpdate": `最新更新`,
"aiRecoModel.list.subTitle": `可以打开/关闭模型在选择推荐模型后用户可以编辑模型内的参数。`,
"aiRecoModel.maxNumber": `最大推荐次数`,
"aiRecoModel.model_parameter_opti": `模型参数优化`,
"aiRecoModel.name": `模型名称`,
"aiRecoModel.nutritionBenchmark": `营养基准`,
"aiRecoModel.nutritionBenchmark2": `营养基准`,
"aiRecoModel.parameters": `模型参数`,
"aiRecoModel.petInfo": `宠物信息`,
"aiRecoModel.status": `在线状态`,
"aiRecoModel.type.customizedReco": `自定义推荐`,
"aiRecoModel.type.disable_tip": `请问您是否确认要禁用此智能推荐模型？`,
"aiRecoModel.type.enable_tip": `请问您是否要启用此智能推荐模型？`,
"aiRecoModel.type.relatedReco": `相关推荐`,
"aiRecoModel.userAndPetInfo": `用户和宠物信息`,
"aiRecoModel.userInfo": `用户信息`,
"aiRecoModel.viewHistory": `查看历史记录`,
"arrt.name": `属性名称`,
"arrt.value": `属性类型`,
"assets.Assets Type": `分类类型`,
"assets.Audio": `音频`,
"assets.Content Type": `内容类型`,
"assets.Files": `文件`,
"assets.Images": `图片`,
"assets.Videos": `视频`,
"assets.catalog": `分类`,
"assets.mediaLibrary": `媒体库`,
"assets.mediaLibrary edit": `编辑媒体`,
"attbiutes": `属性`,
"attempts tips": `<p>重试尝试会定期进行，最多持续 7 天，并且时间间隔逐渐增加：<br /> <strong>·</strong> 5 分钟<br /><strong>·</strong> 10 分钟<br /><strong>·</strong> 15 分钟<br /><strong>·</strong> 30分钟<br /><strong>·</strong> 1小时<br /><strong>·</strong> 2小时<br /><strong>·</strong> 4小时<br />此后，在接下来的 7 天内每 8 小时重试一次。</p>`,
"autoMatedProcessDescription": `通过自动化流程、个性化内容和定制的消费者旅程我们帮助品牌利用当前的消费者细分市场并自动触发额外销售和交叉销售的转化机会。`,
"automated precision marketing": `自动化精准营销`,
"bind Descriptions": `绑定描述`,
"bind attbiutes": `绑定属性`,
"blockList": `黑名单`,
"campaign.Adjust Points": `调整积分`,
"campaign.Campaign Plan Overview": `活动计划概览`,
"campaign.Deliver Message": `发送消息`,
"campaign.Distribute Coupons": `分发优惠券`,
"campaign.abandon_push": `放弃此次推送`,
"campaign.add_condition": `增加条件`,
"campaign.add_config": `增加配置`,
"campaign.add_segment": `新增人群包`,
"campaign.are_you_sure_delete": `你确定删除这个活动吗？`,
"campaign.are_you_sure_pause": `你确定暂停这个活动吗？`,
"campaign.are_you_sure_restart": `你确定重启这个活动吗？`,
"campaign.audit notification": `审核提示`,
"campaign.audit_log": `操作日志`,
"campaign.belong_to": `属于`,
"campaign.branch": `分支`,
"campaign.canvas_tip": `请完成画布设置`,
"campaign.complete_events": `完成事件`,
"campaign.complete_tip": `请完成设置`,
"campaign.confirm_audit_tip": `请确认执行以下哪个审核选项。`,
"campaign.confirm_publish_tip": `你确定要继续发布吗？`,
"campaign.confirm_savedraft_tip": `你确定保存为草稿吗？`,
"campaign.date_period": `期间`,
"campaign.days": `天`,
"campaign.delay_send": `延迟发送`,
"campaign.detail": `活动详情`,
"campaign.dirturb_tip": `是勿扰期间。勿扰期间内触发到则`,
"campaign.distribution": `流量分配`,
"campaign.div_by_condition": `由用户筛选条件分流`,
"campaign.div_by_event": `按触发事件分流`,
"campaign.do_not_disturb": `勿扰`,
"campaign.enter_setting": `进入设置`,
"campaign.enter_time": `进入时间`,
"campaign.enter_tip1": `设置流程类型、进入时间和进入限制`,
"campaign.enter_tip2": `筛选用户进入流程`,
"campaign.entry_condition": `进入条件`,
"campaign.event_meet_cnt": `{count}个事件属性`,
"campaign.has_child_node": `应该先移除渠道`,
"campaign.missing_branch_tip": `至少设置一个子策略或者分流器`,
"campaign.missing_parent_node": `应该先完成父渠道设置`,
"campaign.multi": `多次`,
"campaign.not_belong_to": `不属于`,
"campaign.once": `一次性`,
"campaign.operator_account": `操作员账户`,
"campaign.operator_category": `操作员类型`,
"campaign.operator_name": `操作员姓名`,
"campaign.operator_time": `操作时间`,
"campaign.participation_restrict": `参与限制`,
"campaign.process_canvas": `流程画布`,
"campaign.process_category": `流程类别`,
"campaign.process_description": `流程描述`,
"campaign.process_name": `流程名称`,
"campaign.process_type": `流程类型`,
"campaign.push_scope": `推送范围`,
"campaign.running": `进行中`,
"campaign.select_type": `选择类型`,
"campaign.send_after_": `在此期间结束后再发送`,
"campaign.splitter": `分流器`,
"campaign.splitter_tip": `用户将按照流量分配比例随机进入任一分支流量总和为100%`,
"campaign.splitter_tip1": `用户将随机进入任一策略器可用于简单的 AB 实验`,
"campaign.sub_strategy": `子策略`,
"campaign.sub_strategy_tip": `由用户筛选、延迟设置、触达消息三部分组成支持对特定用户进行触达`,
"campaign.sub_tactics": `子策略`,
"campaign.sub_tactics_type": `子策略类型`,
"campaign.summary_tip": `至少有两个分支且流量总和为100%`,
"campaign.target_user_tip": `请完成目前用户设置`,
"campaign.the_same_user_": `同一用户参与当前流程最多`,
"campaign.time_within": `时间内`,
"campaign.user_meet_cnt": `{count}个用户属性`,
"campaign.user_satisfy_cnt": `{count}个用户行为组`,
"campaign.users_in": `用户在`,
"campaign.wait_time": `等待时间`,
"campaign.white_user": `白名单`,
"campaign.white_user_tips": `输入测试用户帐号并在多个用户之间用半角逗号分隔`,
"campaign.with_done": `时间内做了`,
"can_not_add_same_item": `你不能增加相同的项目`,
"carrierSetting.Add new company": `新增物流公司`,
"carrierSetting.Add/Edit carriers company": `新增/编辑物流公司`,
"carrierSetting.Carriers company code": `物流公司代码`,
"carrierSetting.Carriers company name": `物流公司名称`,
"carrierSetting.Carriers company settings": `物流公司设置`,
"carrierSetting.Carriers company status": `物流公司状态`,
"carrierSetting.DTC Tracking": `DTC Tracking`,
"carrierSetting.Header Token": `Header Token`,
"carrierSetting.tip": `管理您常用的物流商公司在订购和运输时易于选择`,
"cdn.CDN Basic Settings": `CDN基础设置`,
"cdn.CDN Setting": `CDN 设置`,
"cdn.CDN Setting.tip": `CDN设置提供了配置和微调CDN行为的方法以满足特定的性能、安全性和交付需求。`,
"cdn.Explanation": `解释`,
"cdn.OSS Prefix": `OSS 前缀`,
"cdn.Quality.explanation": `指定JPEG、WebP和AVIF ......格式图像的质量。质量等级为1-100。`,
"cdn.URL Prefix": `URL 前缀`,
"cdn.height.explanation": `以像素为单位指定图像的最大高度。准确的行为取决于拟合模式。`,
"cdn.notification": `请注意一次只能打开1个CDN。请确保您已关闭所有其他按钮然后打开它。`,
"cdn.ossPrefix.explanation": `一个固定的前缀标识这是一个由OSS的内置Worker处理的特殊路径。`,
"cdn.urlPrefix.explanation": `加速url前缀是指在CDN中增加一个域名以加速源服务器的内容分发和资源访问。`,
"cdn.weight.explanation": `以像素为单位指定图像的最大宽度。准确的行为取决于拟合模式。`,
"channel_options.douyin": `抖店`,
"channel_options.douyin.des": `为抖音销售产品创建一个商店并在DTC中心无缝管理订单。`,
"channel_options.facebook": `Facebook`,
"channel_options.facebook.des": `为Facebook销售产品创建商店并在DTC中心无缝管理订单。`,
"channel_options.instagram": `Instagram`,
"channel_options.instagram.des": `为Instagram销售产品创建商店并在DTC中心无缝管理订单。`,
"channel_options.online_store": `线上商店`,
"channel_options.online_store.des": `建立一个完整且安全的网上商店网站销售产品和管理订单。`,
"channel_options.points_of_sales": `POS`,
"channel_options.points_of_sales.des": `支持当面销售并在DTC中心无缝结账和管理订单。`,
"channel_options.twitter": `Twitter`,
"channel_options.twitter.des": `为Twitter销售产品创建商店并在DTC中心无缝管理订单。`,
"channel_options.wechat_mini_program": `微信小程序`,
"channel_options.wechat_mini_program.des": `在微信小程序上销售产品并在DTC中心无缝管理订单。`,
"channel_options.zhifubao": `支付宝小程序`,
"channel_options.zhifubao.des": `在支付宝小程序上销售产品并在DTC中心无缝管理订单。`,
"cms.API Slug": `API Slug`,
"cms.Add Block": `新增元素`,
"cms.Add Component": `新增组件`,
"cms.Add some fields": `新增字段`,
"cms.Annoucement Bar": `公告栏`,
"cms.Attribute Description": `属性描述`,
"cms.Attribute Name": `属性名称`,
"cms.Attribute Type": `属性类型`,
"cms.Audit": `审核`,
"cms.Basic Information": `基本信息`,
"cms.Body": `主题`,
"cms.Change Date": `改变日期`,
"cms.Collection": `集合`,
"cms.Component": `组件`,
"cms.Component Sync": `同步组件`,
"cms.Component Synchronization": `组件同步`,
"cms.Create dynamic landing pages using components": `利用组件构建页面模板`,
"cms.Delete": `删除`,
"cms.Drag to Sort": `拖拽排序`,
"cms.Edit Page Infor": `编辑页面信息`,
"cms.Enter Page Slug": `编辑页面Slug`,
"cms.Enter Page Title": `编辑页面名称`,
"cms.Footer": `页脚`,
"cms.Header": `页头`,
"cms.Hide": `隐藏`,
"cms.How DolInsert Custom Components in the WYSIWYG Field?": `如何在WYSIWYG字段中插入自定义组件？  `,
"cms.How to Modify Components": `如何修改组件？`,
"cms.Latest Saved Time": `最近一次保存`,
"cms.Learn": `学习`,
"cms.Leave": `离开`,
"cms.Metadata": `元数据`,
"cms.More content modeling basics": `更多关于内容建模基础知识`,
"cms.New Collection": `新建集合`,
"cms.New Component": `新组件`,
"cms.New Page Types": `新页面模板`,
"cms.Operation Log": `操作日志`,
"cms.Page Audit": `页面审核`,
"cms.Page Audit Tip": `此页面已准备好进行审核。您确认要发布此页面吗？`,
"cms.Page Publish Success Notification": `页面发布成功提示`,
"cms.Page Structure": `页面结构`,
"cms.Page Title": `页面名称`,
"cms.PageType": `页面模板`,
"cms.Please Enter Page API Slug": `请输入页面API Slug`,
"cms.Please Enter Page Name": `请输入页面名称`,
"cms.Publish By": `发布者`,
"cms.Publish Date": `发布日期`,
"cms.Publish History": `发布历史`,
"cms.Result": `结果`,
"cms.SEO": `SEO`,
"cms.Save Changes": `保存`,
"cms.Save Page Metadata": `保存数据`,
"cms.Search Components": `搜索组件`,
"cms.Select Metadata": `选择元数据`,
"cms.Show": `展开`,
"cms.Slug Tips": `API slug用于查询DTC Slug以获取此页面的内容`,
"cms.Slug Tips1": `API slug用于查询DTC Slug以获取此页面的内容点击<a target='_blank' href={href} style='font-weight:bold; color:#999;text-decoration:underline'>此处</a>查看此页`,
"cms.Synchronize": `同步`,
"cms.Target URL": `目标URL`,
"cms.Text": `文本`,
"cms.Theme Components": `主题组件`,
"cms.Validation Parameters": `参数验证`,
"cms.Version": `版本`,
"cms.View Later": `稍后查看`,
"cms.View Now": `立即查看`,
"cms.You have created components sucessfully": `组件已创建成功。`,
"cms.You have deleted components sucessfully": `组件已删除成功。`,
"cms.attribute_create_tip": `请选择所创建属性的所需类型。`,
"cms.contentType.New Content Type": `创建`,
"cms.contentType.name": `名称`,
"cms.contentType.search": `依据内容名称进行搜索`,
"cms.contentType.type": `类型`,
"cms.contentTypeSubTitle": `通过统一的指示板(包括集合、组件和页面类型)轻松地监督所有内容类型。要预览每个组件或页面类型的外观只需点击 <a target='_blank' href={href} style='font-weight:bold; color:#999;text-decoration:underline'>这里</a> 访问Storybook。`,
"cms.min max length": `最小/最大长度`,
"cms.offline": `下线`,
"cms.online": `上线`,
"cms.page publish success desc": `您的页面 [{pageTitle}] 已成功发布在我们的CMS平台上。您现在可以访问您的实时页面，网址为 [{pageUrl}]。`,
"cms.save_tips": `嘿看起来您还有一些未保存的更改。请注意如果您现在退出您的更改将不会被保存。在离开之前您想保存更改吗？`,
"cms.search.Search": `搜索`,
"cms.search.collectionSeach": `依据集合名称搜索`,
"cms.search.collectionType": `集合类型`,
"cms.search.collections": `集合`,
"cms.search.language": `语言`,
"cms.search.noData.tip1": `我们找不到任何结果`,
"cms.search.noData.tip2": `你可以尝试搜索不同的关键字。`,
"cms.search.page": `页面`,
"cms.search.pageSearch": `依据页面名称进行搜索`,
"cms.search.pageType": `页面类型`,
"cms.search.placeholder": `请输入搜索关键词`,
"cms.search.tip": `搜索您的商店和DTC的资源如产品页面和帖子。`,
"cms.titleSubInfo": `为电子商务主页、电子商务促销、付费广告活动建立登陆页。`,
"cms.titleSubInfo1": `为要引用的数据表创建集合。`,
"cms.webhookSubTitle": `当内容发生变更时我们会通过Webhook进行广播。`,
"cms.webhookSubTitle1": `配置您的Webhook地址向您的应用程序发送PoT通知。有关事件类型的详细信息请查看<a style={{ color: '#EF583A' }} to='#'>文档</a>。`,
"cms.webhookSubTitle2": `文档`,
"columns-Roles": `角色`,
"comingSoon.text": `我们将很快推出我们的新功能`,
"comment.are_you_sure_to_delete": `请问您是否确实要删除此备注？`,
"comment.delete_comment": `删除备注`,
"comment.inputTip": `请输入您的备注`,
"comment.placeholder": `输入备注`,
"comment.remarks": `备注`,
"consumer.Adding time": `添加时间`,
"consumer.Browsing Time": `浏览时间`,
"consumer.Consent Info": `同意条款`,
"consumer.Favorite Products": `产品收藏`,
"consumer.NEWSLETTER OPT-IN": `新闻简报订阅`,
"consumer.Product Browsing History": `产品浏览历史`,
"consumer.opt-in": `是的，我想订阅Malts.com的新闻简报，以便了解新产品发布和促销活动。`,
"consumerInsights": `消费者洞察`,
"consumerInsightsList": `消费者洞察列表`,
"consumers want": `给消费者`,
"consumptionPoints": `消费积分`,
"copyStore is required": `需要复制存储`,
"coupon": `优惠券`,
"couponCode.length.rule": `请输入大写字母或数字，最多五个字符`,
"create successfully": `create successfully`,
"customer.customer_service": `客户服务`,
"customer.dtc_center_support": `DTC体验支持配置移动客户服务的启用/禁用。`,
"customer.mini_program": `小程序`,
"dashboard": `首页`,
"dashboard.apidoc": `API文件`,
"dashboard.apidoc_desc": `包含使用API所需的所有信息包括函数、类、返回类型、参数等的详细信息。`,
"dashboard.channel": `渠道`,
"dashboard.channel_desc": `支持管理线上和线下多渠道包括线上自营、POS、第三方渠道等`,
"dashboard.main": `主要功能`,
"dashboard.marketing": `营销`,
"dashboard.marketing_desc": `提供折扣、优惠券和其他营销工具帮助商店引流并提升商品复购率。`,
"dashboard.more": `探索更多`,
"dashboard.order": `订单`,
"dashboard.order_desc": `员工可以管理订单状态查询订单信息并为订单提供备注。`,
"dashboard.product": `产品`,
"dashboard.product_desc": `发布多种类型的产品包括组合商品和常规产品并维护产品类目、产品图片、属性和规格。`,
"dashboard.shipment": `发货`,
"dashboard.shipment_desc": `为员工提供发货工具以实现向消费者交付订单。`,
"dashboard.solution": `D2C解决方案`,
"dashboard.solution_desc": `通过在战略、咨询、数字、技术和运营方面提供无与伦比的服务解决客户面临的最严峻挑战。`,
"dashboard.subscription": `订阅`,
"dashboard.subscription_desc": `为员工提供订阅工具以查询信息、修改交付时间、修改交付地址和添加订阅备注。`,
"dataManagement.actions": `操作`,
"dataManagement.addEvent": `新增事件`,
"dataManagement.addEventAttribute": `新增事件属性`,
"dataManagement.addNewEvent": `新增事件`,
"dataManagement.addNewEventAttribute": `新增事件属性`,
"dataManagement.addNewPricipalAttribute": `新增主体属性`,
"dataManagement.addNewTransactionAttribute": `新增交易属性`,
"dataManagement.addPrincipalAttribute": `新增主体属性`,
"dataManagement.addTransactionAttribute": `新增交易属性`,
"dataManagement.all": `全部`,
"dataManagement.applicationScenario": `应用场景：该模块主要显示、配置（查看详细信息或删除）和批量导入事件信息包括事件名称、显示名称、事件描述、状态因此我们可以在洞察模块中详细跟踪事件。`,
"dataManagement.associatedEvent": `关联事件`,
"dataManagement.associatedEventAttribute": `关联事件属性`,
"dataManagement.associatedEvents": `关联事件`,
"dataManagement.attributeDescription": `属性描述`,
"dataManagement.attributeName": `属性名称`,
"dataManagement.bulkAdd": `批量新增`,
"dataManagement.bulkAddDescription": `一键快速上传多个事件并批量上传。`,
"dataManagement.bulkAddDescriptionEventAttribute": `一键快速上传多个事件属性并批量上传。`,
"dataManagement.bulkAddDescriptionPricipalAttribute": `一键快速上传多个主体属性并批量上传。`,
"dataManagement.bulkAddDescriptionTransactionAttribute": `一键快速上传多个交易属性并批量上传。`,
"dataManagement.clickDrag": `单击或拖动文件到此区域以上传`,
"dataManagement.dataType": `数据类型`,
"dataManagement.description": `描述`,
"dataManagement.disabled": `禁用`,
"dataManagement.displayName": `显示名称`,
"dataManagement.displayStatus": `显示状态`,
"dataManagement.downloadTemplate": `下载模板`,
"dataManagement.enabled": `已启用`,
"dataManagement.eventAttribute": `事件属性`,
"dataManagement.eventAttributeDescription": `应用场景：该模块主要显示、配置（查看详细信息或删除）和批量导入事件属性信息包括事件属性名称、显示名称、事件属性描述、显示状态。`,
"dataManagement.eventAttributeDetails": `事件属性详情`,
"dataManagement.eventAttributeDisplayName": `事件属性展示名称`,
"dataManagement.eventAttributeInformation": `事件属性信息`,
"dataManagement.eventAttributeName": `事件属性名称`,
"dataManagement.eventAttributes": `事件属性`,
"dataManagement.eventDescription": `事件描述`,
"dataManagement.eventDetail": `事件详细信息`,
"dataManagement.eventDetails": `事件详细信息`,
"dataManagement.eventDisplayName": `事件属性显示名称`,
"dataManagement.eventInformation": `事件信息`,
"dataManagement.eventName": `事件名称`,
"dataManagement.eventStatus": `事件状态`,
"dataManagement.events": `事件`,
"dataManagement.generalEvents": `一般事件`,
"dataManagement.keywords": `关键字`,
"dataManagement.locale": `多语言设置`,
"dataManagement.newEventAttributeDescription": `事件属性描述`,
"dataManagement.onlyOneAnd100M": `2.只能上载一个文件并且≤ 100M`,
"dataManagement.options": `操作`,
"dataManagement.preset": `预设置`,
"dataManagement.principalAttribute": `主体属性`,
"dataManagement.principalAttributeDescription": `主体属性描述`,
"dataManagement.principalAttributeDisplayName": `主体属性展示名称`,
"dataManagement.principalAttributeName": `主体属性名称`,
"dataManagement.principalAttributeScenarioDescription": `应用场景：此模块主要展示、配置和批量导入主体属性信息包括主体属性名称展示名称主体属性描述状态`,
"dataManagement.principalAttributes": `主体属性`,
"dataManagement.singleAdd": `单个新建`,
"dataManagement.singleAddDescription": `逐个创建新事件并灵活修改显示名称。`,
"dataManagement.singleAddDescriptionEventAttribute": `逐个创建新事件属性并灵活修改显示名称。`,
"dataManagement.singleAddDescriptionPricipalAttribute": `逐个创建新的主体属性灵活修改显示名称。`,
"dataManagement.singleAddDescriptionTransactionAttribute": `逐个创建新交易并灵活修改显示名称。`,
"dataManagement.status": `状态`,
"dataManagement.supportFile": `1.支持文件格式：XLSX CSV`,
"dataManagement.transactionAttribute": `交易属性`,
"dataManagement.transactionAttributeDescription": `交易属性描述`,
"dataManagement.transactionAttributeDisplayName": `交易属性展示名称`,
"dataManagement.transactionAttributeName": `交易属性名称`,
"dataManagement.transactionAttributeScenarioDescription": `应用场景：该模块主要显示、配置（查看详细信息或删除）和批量导入交易属性信息包括交易属性名称、显示名称、交易属性描述、显示状态。`,
"dataManagement.transactionAttributes": `交易属性`,
"delete img tip": `您确定要删除资产 {assetName} 吗？`,
"deleteCategoryTip": `这个文件夹下有文件。请确保在删除此文件夹之前将其文件移动到其他文件夹`,
"deleteConsumer": `删除消费者`,
"deleteConsumerAlert": `您确定要删除该消费者吗？`,
"delivery & payment info": `配送和支付信息`,
"desc.name": `描述名称`,
"description.Action": `操作`,
"description.AddNewDescription": `添加新的产品描述`,
"description.CustomizedContent": `定制内容`,
"description.DescriptionDetail": `产品描述详情`,
"description.DescriptionStatus": `描述状态`,
"description.DescriptionType": `描述类型`,
"description.Description_label": `产品描述名称`,
"description.Disable": `禁用`,
"description.DisplayName": `显示名称`,
"description.Enable": `启用`,
"description.Status": `状态`,
"description.UnifiedContent": `统一内容`,
"description.cancel": `否`,
"description.confirm": `是`,
"description.deleteItemTip": `无法恢复删除的描述。`,
"description.delete_item": `请问您确定要删除此描述吗？`,
"description.descriptionLabelTip": `请输入描述标签`,
"descrptions": `描述`,
"detail page": `详情页面`,
"disabled": `禁用`,
"display name": `显示名称`,
"downloadLater": `晚些再下载`,
"edit": `编辑`,
"edit page": `编辑`,
"email": `电子邮件`,
"empower_your_AI": `助力您的人工智能、数据和数字化之旅！`,
"empower_your_AI.subTitle": `智能运营旨在帮助个人在人工智能、数据和数字化的动态领域取得更大的成功。我们的目标是提供支持和指导，帮助人们在这些令人兴奋的创新和增长领域取得成功。`,
"enabled": `已启用`,
"endTime": `时间结束`,
"event groups": `事件组`,
"export report task tips": `导出报告任务当前正在进行中，很快将在销售渠道/导出报告中提供。`,
"filter.Attribute Filter": `标签筛选`,
"filter.Attribute Label": `属性标签`,
"filter.AttributeValue": `属性值`,
"filter.Customized Filter": `自定义筛选器`,
"filter.Display Name": `名称`,
"filter.DisplayName": `显示名称`,
"filter.FilterLable": `属性名称`,
"filter.My Filters & Sort": `过滤器和排序`,
"filter.My Filters & Sort detail": `筛选器用来帮助消费者过滤以快速找到所需产品。目前我们支持属性筛选器、标签过滤器和自定义过滤器可以在下面进行配置！启用的排序条件将显示在前台的“排序依据”中供消费者依照其要求对产品进行排序。`,
"filter.SelectAttribute": `选择属性`,
"filter.SelectTag": `选择标签`,
"filter.Sort By": `排序`,
"filter.SortFieldName": `排序字段名称`,
"filter.SortFieldStatus": `排序字段状态`,
"filter.Tag Filter": `标签筛选器`,
"filter.TagName": `标签名称`,
"finance.application_period": `使用期间`,
"finance.choose_invoice_type": `选择发票类型`,
"finance.invoice.actions": `操作`,
"finance.invoice.all": `全部`,
"finance.invoice.application_time": `应用时间`,
"finance.invoice.download": `下载`,
"finance.invoice.email_address": `邮箱地址`,
"finance.invoice.invoice_title": `发票标题`,
"finance.invoice.invoice_type": `发票类型`,
"finance.invoice.invoiced": `已开票`,
"finance.invoice.invoiced_failed": `开票失败`,
"finance.invoice.invoicing": `正在开票`,
"finance.invoice.not_invoiced": `未开票`,
"finance.invoice.order_amount": `订单金额`,
"finance.invoice.order_number": `订单号`,
"finance.invoice.status": `状态`,
"finance.invoice_list": `发票列表`,
"finance.invoice_title": `发票抬头`,
"finance.invoice_type": `发票类型`,
"finance.order_number": `订单ID`,
"finance.reset": `重置`,
"finance.search": `搜索`,
"fraud_analysis.rule1": `将此订单的特征与过去观察到的欺诈订单进行比较。`,
"fraud_analysis.rule2": `将客户下订单的 IP 地址与提供的送货地址进行比较。`,
"goalTierSetting": `目标等级设置`,
"hasBeenPaused": `{arg1} 已暂停。`,
"hasBeenRestarted": `{arg1} 已暂停。`,
"header.Blue": `蓝色`,
"header.CDP Experience": `消费者体验`,
"header.Consumer Experience": `消费者体验`,
"header.Content Experience": `内容体验`,
"header.Fulfilment Experience": `履约体验`,
"header.Global Setting": `全局设置`,
"header.MA Experience": `营销体验`,
"header.Open Platform": `开放平台`,
"header.Orange": `橙色`,
"header.Sales Experience": `销售体验`,
"header.Smart Operation": `智能运营`,
"header.Switch Language": `切换语言`,
"header.Switch Theme": `切换主题`,
"header.back_to_login_page": `返回登录页面`,
"header.dtc_center": `DTC经验`,
"header.efficient_store_tool": `高效的门店管理工具`,
"header.logout": `注销`,
"input category name": `输入类别名称`,
"input.consumer name": `请输入消费者姓名`,
"input.phone number": `请输入电话号码`,
"insights": `洞察`,
"insights.Activity": `活跃度`,
"insights.Behavior": `行为细查`,
"insights.Behavior Tags": `行为标签`,
"insights.Boolean": `布尔型`,
"insights.Date": `日期`,
"insights.Datetime": `日期`,
"insights.High": `高`,
"insights.Int": `整数型`,
"insights.Low": `低`,
"insights.Loyalty": `忠诚度`,
"insights.Manual": `手动`,
"insights.Marketing Preferences": `市场偏好`,
"insights.Medium": `中`,
"insights.Product Preferences": `产品偏好`,
"insights.Profile Tags": `标签`,
"insights.Regular": `常规`,
"insights.Segment": `分群包`,
"insights.String": `字符串`,
"insights.Tags": `标签`,
"insights.Total Segments": `人群包总数`,
"insights.Total Tags": `标签总数`,
"insights.Value": `价值度`,
"insights.Value Tags": `价值标签`,
"insights.age": `年龄`,
"insights.baseId": `Base ID`,
"insights.birthday": `生日`,
"insights.city": `城市`,
"insights.consumerId": `会员ID`,
"insights.email": `电子邮件`,
"insights.gender": `性别`,
"insights.lastLoginTime": `上次登录时间`,
"insights.memberTier": `会员等级`,
"insights.name": `消费者名称`,
"insights.numberOfConsumers": `消费者数量`,
"insights.phoneNumber": `电话号码`,
"insights.placeholder": `Base ID/姓名/电子邮件/电话号码/会员级别`,
"inventory.Add Products": `新增商品`,
"inventory.Arrange Outbound": `安排出库`,
"inventory.Arrange Shipment": `安排发货`,
"inventory.Associated Logical warehouse": `关联逻辑仓`,
"inventory.Associated Physical warehouse": `关联物理仓`,
"inventory.Associated Store": `关联店铺`,
"inventory.Available": `可用库存`,
"inventory.Available Tip": `可用库存 = 现有库存 - 锁定库存 - 预占库存 + 在途库存 - 安全库存`,
"inventory.Brand": `品牌`,
"inventory.Consumer Name": `消费者`,
"inventory.EAN Code": `EAN`,
"inventory.Enable Safety Stock": `启用安全库存`,
"inventory.In Transit": `在途库存`,
"inventory.Inbound": `入库`,
"inventory.Inbound Create Time": `入库创建时间`,
"inventory.Inbound Lines": `入库商品`,
"inventory.Inbound Number": `入库单号`,
"inventory.Inbound Products": `入库商品`,
"inventory.Inbound Status": `入库状态`,
"inventory.Inbound Succeed": `入库成功`,
"inventory.Inbound Summary": `入库总览`,
"inventory.Inbound Warehouse": `入库仓库`,
"inventory.Locked": `锁定库存`,
"inventory.On Hand": `现有库存`,
"inventory.Outbound": `出库单`,
"inventory.Outbound Create Time": `出库创建时间`,
"inventory.Outbound Lines": `出库商品`,
"inventory.Outbound Number": `出库单号`,
"inventory.Outbound Status": `出库状态`,
"inventory.Outbound Summary": `出库总览`,
"inventory.Outbound Warehouse": `出库仓库`,
"inventory.Product Category": `商品类别`,
"inventory.Products Name": `产品名称`,
"inventory.Qty": `商品数量`,
"inventory.Received Qty": `已入库商品`,
"inventory.Related Order Number": `关联订单号`,
"inventory.Reserved": `预占库存`,
"inventory.SKU Code": `SKU`,
"inventory.SPU": `SPU`,
"inventory.Safety": `安全库存`,
"inventory.Search Inbound Number": `搜索入库单号`,
"inventory.Search Outbound Number": `搜索出库单号`,
"inventory.Search SKU Code": `搜索商品SKU`,
"inventory.Ship From": `发货地址`,
"inventory.Shipment Number": `发货面单号`,
"inventory.Shipped Qty": `已发货数量`,
"inventory.Shipper Name": `发货人`,
"inventory.To Receive Qty": `待入库商品`,
"inventory.To ship Qty": `待发货数量`,
"inventory.Total Received Qty": `总已入库商品`,
"inventory.Total Shipped Qty": `总已发货数量`,
"inventory.Total To Receive Qty": `总待入库商品`,
"inventory.Total To ship Qty": `总待发货数量`,
"inventory.Tracking Detail": `物流详情`,
"inventory.Warehouse Name": `仓库名称`,
"inventory.Warehouse information": `仓库信息`,
"inventory.inbound success Tip": `您确认入库成功吗?`,
"inventory.outbound": `出库`,
"ip": `ip`,
"ip BlockList": `ip阻止列表`,
"is mainly to generate base ID of Consumer Experience business module according to flexible business logic.": `根据灵活的业务逻辑生成消费者体验业务模块的Base ID。Base ID是消费者体验业务单元的唯一用户ID主要通过根据合理的逻辑规则映射每个渠道（EC活动、广告活动等）的消费者唯一标识ID（消费者ID、电话号码、电子邮件、IP等）来生成。`,
"isMember is required": `会员是必填`,
"items": ` 条`,
"items selected": `个用户被选中`,
"last two years": `近两年`,
"last year": `近一年`,
"loadMore": `加载更多`,
"login.account_number": `账号`,
"login.code_send_failed": `验证代码发送失败请重试！`,
"login.confirm_password": `确认密码`,
"login.did_your_receive": `没有收到代码？`,
"login.donot_have_an_account": `没有账户？`,
"login.enter_new_password": `请输入新密码`,
"login.enter_password": `输入密码`,
"login.enter_phone_number": `请输入电话号码`,
"login.enter_user_name": `请输入用户名`,
"login.enter_valid_phone": `输入有效的电话。示例：13101227768`,
"login.enter_verify_code": `输入验证码`,
"login.expired_tip": `登录已过期请重新登录！`,
"login.forget_password": `忘记密码？`,
"login.incorrect_code": `错误代码！`,
"login.login": `登录`,
"login.password": `密码`,
"login.password_should_match": `两个密码需要匹配！`,
"login.phone_not_registed": `未用手机注册为用户！`,
"login.please_confirm_password": `请确认您的密码！`,
"login.please_enter_code": `请输入验证码`,
"login.please_input_account_number": `请输入您的帐号`,
"login.please_input_password": `请输入您的密码`,
"login.please_input_phone": `请输入您的电话号码`,
"login.please_input_username": `请输入您的用户名`,
"login.please_select_brand": `请选择品牌！`,
"login.please_select_brand_of_store": `请选择您商店的品牌`,
"login.please_select_store": `请选择商店`,
"login.please_select_store_of": `请选择商店`,
"login.provide_better_service": `让我们为您提供更好的服务`,
"login.register": `注册`,
"login.remember_me": `记住我`,
"login.resend": `重新发送`,
"login.resend_code": `重新发送代码`,
"login.select_brand": `选择品牌`,
"login.select_store": `选择商店`,
"login.signup": `注册`,
"login.user_wrong": `用户名或密码错误！`,
"login.verify_sent_to": `您的验证码已发送到`,
"logisticRuleAdd": `新增`,
"logisticRuleDetail": `详情`,
"logisticRuleEdit": `编辑`,
"loyalProgram": `忠诚度计划`,
"loyalProgramDescription": `忠诚度计划描述`,
"loyalProgramIcon": `图标`,
"loyalProgramName": `忠诚度计划名称`,
"loyalPrograms": `忠诚度计划`,
"loyalPrograms.Create Loyalty Program": `创建忠诚度计划`,
"loyalPrograms.Create Rewards": `创建奖励`,
"loyalPrograms.Customized Loyalty Program": `去体验您的定制忠诚度计划！`,
"loyalPrograms.Set up Points Rules": `设置积分规则`,
"loyalPrograms.Set up Tiers": `设置层`,
"loyalPrograms.Start From Here": `从这里开始`,
"loyalPrograms.add": `添加忠诚度计划`,
"loyalPrograms.desc": `忠诚度计划是公司用来鼓励持续客户忠诚度和长期重复业务的策略。在本节中您可以根据您的实际业务需求设置自己的定制忠诚度计划！我们同时支持多个忠诚度计划。`,
"loyalPrograms.description": `还未创建忠诚度计划！请注意合适的忠诚度计划可以最大化消费者参与度和增加终身价值的最佳方式之一。按照以下步骤创建您的第一个忠诚度计划！`,
"loyalPrograms.step1": `步骤1`,
"loyalPrograms.step2": `步骤2`,
"loyalPrograms.step3": `步骤3`,
"loyalPrograms.step4": `步骤4`,
"loyalPrograms.step5": `步骤5`,
"marketing.boost_sale_promo": `通过促销促进销售`,
"marketing.boost_sale_promo_s1": `通过在结账时向买家提供折扣券来扣减订单金额`,
"marketing.boost_sale_promo_s2": `为您的产品设置折扣以提高销量`,
"marketing.boost_sale_promo_s3": `通过在您的商店中创建活动来提高产品销量`,
"marketing.boost_sale_promo_t1": `优惠券`,
"marketing.boost_sale_promo_t2": `折扣促销`,
"marketing.boost_sale_promo_t3": `活动`,
"marketing.engage_petowner": `与消费者互动`,
"marketing.engage_petowner_s1": `与您的观众进行实时连接轻松回答购物者的问题`,
"marketing.engage_petowner_t1": `直播`,
"marketing.marketing_center": `营销经验`,
"marketing.recom_basic_setting": `推荐的基本设置`,
"marketing.recom_basic_setting_s1": `手动设置产品重量或禁用某些产品`,
"marketing.recom_basic_setting_t1": `产品推荐管理`,
"marketing.recom_spot_manage": `推荐点位管理`,
"marketing.recom_spot_manage_s1": `基于产品的相关性推荐`,
"marketing.recom_spot_manage_s2": `基于用户兴趣的推荐`,
"marketing.recom_spot_manage_s3": `置顶推荐`,
"marketing.recom_spot_manage_s4": `基于用户兴趣的推荐`,
"marketing.recom_spot_manage_s5": `基于用户兴趣的推荐`,
"marketing.recom_spot_manage_s6": `置顶推荐`,
"marketing.recom_spot_manage_s7": `基于产品相关性的推荐`,
"marketing.recom_spot_manage_s8": `基于用户兴趣的推荐`,
"marketing.recom_spot_manage_t1": `购物车`,
"marketing.recom_spot_manage_t2": `产品详情`,
"marketing.recom_spot_manage_t3": `产品列表`,
"marketing.recom_spot_manage_t4": `我的账户`,
"marketing.recom_spot_manage_t5": `会员体验`,
"marketing.recom_spot_manage_t6": `订单确认页`,
"marketing.recom_spot_manage_t7": `类别`,
"marketing.recom_spot_manage_t8": `类别推荐渠道`,
"marketing.recom_strategy_manage": `推荐策略管理`,
"marketing.recom_strategy_manage_s1": `根基整体统计数据对产品进行排序通常用于“热销”场景。`,
"marketing.recom_strategy_manage_s2": `推荐与当前产品具有关联性的产品以增强产品的捆绑销售。通常在产品详情页上的“相关推荐”等模块中找到。`,
"marketing.recom_strategy_manage_s3": `提供自定义推荐服务包括基于用户兴趣推荐产品通常用于“您可能也喜欢”等模块中。`,
"marketing.recom_strategy_manage_s4": `提供自定义推荐服务包括基于订阅类型和宠物信息推荐产品这通常用于“产品推荐”等模块中。`,
"marketing.recom_strategy_manage_t1": `置顶推荐`,
"marketing.recom_strategy_manage_t2": `基于产品相关性的推荐`,
"marketing.recom_strategy_manage_t3": `基于消费者兴趣的推荐`,
"marketing.recom_strategy_manage_t4": `产品推荐`,
"max of 140 chars": `元描述不超过140个字符`,
"max of 55 chars": `元标题不超过55个字符`,
"menu.Activity Logs": `活动日志`,
"menu.Campaign plan": `活动计划`,
"menu.Categories": `博客类别`,
"menu.Compaign plan": `活动计划`,
"menu.Consumer Experience": `消费者体验`,
"menu.Content Experience": `内容体验`,
"menu.External SKU": `外部SKU`,
"menu.Fulfilment Experience": `履约体验`,
"menu.Global Setting": `全局设置`,
"menu.JobList": `任务列表`,
"menu.MA Experience": `营销体验`,
"menu.Metadata": `元数据`,
"menu.Open Platform": `开放平台手册`,
"menu.Order Eligible For Return": `符合退货条件的订单`,
"menu.Process Canvas": `流程画布`,
"menu.Quick Task": `快速任务`,
"menu.Return Order": `退货订单`,
"menu.Sales Experience": `销售体验`,
"menu.Smart Operation": `智能运营`,
"menu.Storybook": `Storybook`,
"menu.Sub SKU": `子SKU`,
"menu.Tags": `博客标签`,
"menu.Tax Class": `税费类型`,
"menu.User List": `用户`,
"menu.Weight Unit": `重量单位`,
"menu.Weight Value": `重量`,
"menu.account_management": `账户管理`,
"menu.addNewPosts": `新增博客`,
"menu.addNewSegments": `新增人群包`,
"menu.addNewTags": `添加新标签`,
"menu.add_new_product": `新增产品`,
"menu.address schema": `地址字段`,
"menu.address scope": `Address Scope`,
"menu.adress_management": `地址管理`,
"menu.aireco": `智能推荐`,
"menu.aireco_models": `智能推荐模型`,
"menu.allPosts": `所有博客`,
"menu.app_setting": `应用`,
"menu.assets_management": `资产管理`,
"menu.attributes": `属性`,
"menu.auditOrder": `待审核订单`,
"menu.automatic_replies": `自动回复`,
"menu.backOrder": `异常订单`,
"menu.basic_information": `基本信息`,
"menu.bulkAdd": `批量新增`,
"menu.carousel2": `轮播`,
"menu.channel": `渠道库存`,
"menu.channel_options": `渠道选项`,
"menu.cmsAsets": `资产`,
"menu.cmsCollections": `集合`,
"menu.cmsMateData": `元数据`,
"menu.cmsPages": `页面`,
"menu.cmsPages.addNewPage": `添加新页面`,
"menu.cmsPages.pageList": `页面列表`,
"menu.cmsPosts": `博客`,
"menu.consumerInsights": `消费者洞察`,
"menu.contentTypes": `内容类型`,
"menu.customer_service": `客户服务`,
"menu.dashboard": `首页`,
"menu.dataIngestion": `数据接入`,
"menu.dataManagement": `数据管理`,
"menu.description": `产品描述`,
"menu.emailList": `电子邮件模板`,
"menu.eventAttribute": `事件属性`,
"menu.eventAttributes": `事件属性`,
"menu.events": `事件`,
"menu.exports": `导入 & 导出`,
"menu.fans_management": `粉丝管理`,
"menu.filter": `筛选器和排序`,
"menu.finance": `财务`,
"menu.fulfillment": `履约`,
"menu.generalEvents": `一般事件`,
"menu.idMapping": `ID映射`,
"menu.inboundMgt.": `入库管理`,
"menu.insights": `洞察`,
"menu.intelligent_recommendation": `智能推荐`,
"menu.interface": `界面`,
"menu.inventory": `库存`,
"menu.invoice_list": `发票列表`,
"menu.jobCenter": `任务中心`,
"menu.log": `日志`,
"menu.logical": `逻辑库存`,
"menu.loyalty": `忠诚度计划`,
"menu.loyaltyPrograms": `忠诚度计划`,
"menu.loyalty_program": `忠诚度计划`,
"menu.marketing": `营销`,
"menu.marketing_center": `营销中心`,
"menu.measurement_family": `UOM`,
"menu.measurements_manage": `计量单位`,
"menu.menu_management": `菜单管理`,
"menu.messageCenter": `信息中心`,
"menu.messagelist": `消息列表`,
"menu.metaData": `元数据`,
"menu.mini_program_banner": `小程序横幅`,
"menu.mini_program_qr_code": `小程序二维码`,
"menu.my_orders": `我的订单`,
"menu.my_pet_owner": `消费者列表`,
"menu.my_products": `产品列表`,
"menu.my_shipment": `我的发货`,
"menu.my_subscriptions": `我的订阅`,
"menu.nodeAssociation": `仓库网络`,
"menu.online_store": `在线商店`,
"menu.order": `订单`,
"menu.order monitor": `订单监控`,
"menu.order_flow": `订单流程`,
"menu.order_setting": `订单设置`,
"menu.orders": `订单`,
"menu.org": `组织设置`,
"menu.org_management": `组织管理`,
"menu.outboundMgt.": `出库管理`,
"menu.partner": `合作伙伴`,
"menu.partner_list": `合作伙伴列表`,
"menu.partner_type": `合作伙伴类型`,
"menu.payment_settings": `支付设置`,
"menu.pet_owner": `消费者`,
"menu.physical": `物理库存`,
"menu.points": `积分`,
"menu.points_of_sales": `POS`,
"menu.preferences": `用户偏好`,
"menu.principalAttribute": `主体属性`,
"menu.principalAttributes": `主体属性`,
"menu.product": `产品`,
"menu.product.categrey": `产品分类`,
"menu.productSetting": `产品设置`,
"menu.qr_code_management": `二维码管理`,
"menu.quickStart": `快速入门`,
"menu.recommendation_history": `推荐历史记录`,
"menu.referrals": `推荐`,
"menu.region_language": `地区/语言`,
"menu.reply_content": `回复内容`,
"menu.rewardHistory": `奖励历史记录`,
"menu.rewardSettings": `奖励设置`,
"menu.salesOrder": `销售订单`,
"menu.search": `搜索`,
"menu.segmentsList": `人群包列表`,
"menu.segmentsManagement": `人群包管理`,
"menu.setting": `设置`,
"menu.setting.cdn": `CDN`,
"menu.setting.privacy": `Privacy`,
"menu.setting.webhook": `Webhook`,
"menu.settings": `Webhook`,
"menu.shipment": `发货`,
"menu.shippingMethod": `物流配送`,
"menu.shippingMethod.carrierSetting": `物流设置`,
"menu.shippingMethod.shippingMethods": `运费模版`,
"menu.shippingMethod.transitionMethods": `配送方式`,
"menu.shipping_setting": `发货设置`,
"menu.shop": `店铺设置`,
"menu.shop_category": `销售类目`,
"menu.singleAdd": `单个新建`,
"menu.site_map": `站点地图`,
"menu.smart_operation": `智能运营`,
"menu.smsList": `短信模板`,
"menu.social_media": `销售渠道`,
"menu.sourcingRule": `寻源规则`,
"menu.store_currency": `货币`,
"menu.store_details": `店铺详情`,
"menu.store_management": `商店`,
"menu.store_standards_format": `标准格式`,
"menu.subscription": `订阅`,
"menu.subscriptions_setting": `订阅设置`,
"menu.suggestions": `地址推荐`,
"menu.tagManagement": `标签管理`,
"menu.tagging_setting": `消费者标签`,
"menu.tagsList": `标签列表`,
"menu.taskCenter": `任务中心`,
"menu.taskList": `任务列表`,
"menu.tax": `税费配置`,
"menu.tax_management": `税费管理`,
"menu.templateManagement": `模板管理`,
"menu.template_message": `模板消息`,
"menu.themes": `Themes`,
"menu.transactionAttribute": `交易属性`,
"menu.transactionAttributes": `交易属性`,
"menu.transferOrder": `转移订单`,
"menu.translation": `翻译`,
"menu.user": `用户`,
"menu.valetorder": `代客下单`,
"menu.warehouse": `库存创建`,
"menu.wechat_mini_program": `小程序`,
"messageCenter.Campaign ID": `优惠券代码`,
"messageCenter.Campaign name": `优惠券名称`,
"messageCenter.Consumer Account": `消费者账户`,
"messageCenter.Consumer Email": `消费者邮件`,
"messageCenter.Consumer Phone Number": `消费者电话号码`,
"messageCenter.Consumer Type": `消费者类型`,
"messageCenter.CreateAt": `创建时间`,
"messageCenter.Finish": `完成`,
"messageCenter.Message Detail": `消息详细信息`,
"messageCenter.Object No.": `对象编号`,
"messageCenter.Object Type": `对象类型`,
"messageCenter.Options": `操作`,
"messageCenter.Pending": `进行中`,
"messageCenter.Push Channel": `推送通道`,
"messageCenter.Push type": `推送类型`,
"messageCenter.Reciplent": `收件人`,
"messageCenter.Reciplents": `收件人`,
"messageCenter.Satus": `状态`,
"messageCenter.SendStatus": `发送状态`,
"messageCenter.Signature": `签名`,
"messageCenter.Source": `来源`,
"messageCenter.Task ID": `任务ID`,
"messageCenter.Template Name": `模板名称`,
"messageCenter.quickSend": `快速发送`,
"messageCenter.refresh": `刷新`,
"messageCenter.sentTime": `发送时间`,
"noData": `没有数据`,
"noMoreData": `暂无更多数据`,
"obtainBaiduStatisticStep1": `1.进入<strong>账户管理选项卡</strong>。`,
"obtainBaiduStatisticStep2": `2.单击“添加新网站”，然后输入所需信息，包括网站域名、网站名称等。`,
"obtainBaiduStatisticStep3": `3.进入<strong>代码获取选项卡</strong>，即可找到并复制脚本。`,
"off": `关`,
"oms.quickstart.Pending Review": `待审核`,
"oms.quickstart.Pending Review tip": `订单状态为“待审核”的订单总数`,
"on": `开`,
"operation.by": `通过`,
"operation.log": `操作日志`,
"or Drag and Drop File": `选择图片或上传图片`,
"order.Accept": `通过`,
"order.Accounts are optional": `账号是非必需的`,
"order.Accounts are required": `账号是必需的`,
"order.Amount Quantity": `数量`,
"order.Application time": `申请时间`,
"order.Approve": `通过`,
"order.Attachment information": `附件信息`,
"order.Attachment information tip": `请将您的退货凭据添加到附件中。支持的图片格式：JPG JPEG PNG GIF文件大小不超过5M，最多上传10张`,
"order.Automatic Audit": `自动审核`,
"order.Cancel order": `取消订单`,
"order.Choose if you want to prompt your consumer to create an account when they check out": `选择是否要在消费者结账时提示他们创建帐户`,
"order.Commodity": `商品`,
"order.Confirm Refund": `确认退款`,
"order.Consumer account": `消费者账号`,
"order.Consumer name": `消费者名称`,
"order.Consumer type": `消费者类型`,
"order.Consumers will be able to check out with a consumer account or as a guest": `消费者可以使用消费者帐户或作为游客结账`,
"order.Consumers will only be able to check out if they have a consumer account.": `消费者只有在拥有消费者账户的情况下才能结账。`,
"order.Custom Checkout Account": `定制下单账号`,
"order.Customize order number format and this change will apply to future orders": `自定义订单号格式，此更改将应用于未来的订单`,
"order.Enable This Manual Audit Condition": `启用此手动审核条件`,
"order.Fill in logistics": `填写物流`,
"order.From": `从`,
"order.Initiate after-sales application": `发起售后申请`,
"order.Operation Category": `操作类型`,
"order.Order Audit Setting": `订单审核设置`,
"order.Order Flow Setting": `订单流程设置`,
"order.Order meeting the following conditions requires manual audit": `满足以下条件的订单需要手动审核`,
"order.Order number": `订单号`,
"order.Payment status": `支付状态`,
"order.Pending refund": `待退款`,
"order.Pending review": `待审核`,
"order.Product Name": `产品名称`,
"order.Product name": `商品名称`,
"order.Quantity returned": `已退数量`,
"order.Real refund": `真实退款`,
"order.Reason": `理由`,
"order.Recipient": `收件人`,
"order.Recipient accepted": `收件人接收`,
"order.Recipient phone": `收件人电话`,
"order.Recipient rejected": `收件人拒收`,
"order.Refund time": `退款时间`,
"order.Refunded amount": `已退款金额`,
"order.Refused to refund": `拒绝退款`,
"order.Reject": `拒绝`,
"order.Rejected": `拒绝`,
"order.Return Order Application": `退单申请`,
"order.Return Order Detail": `退单详情`,
"order.Return description": `退货描述`,
"order.Return list": `退单列表`,
"order.Return method": `退货方式`,
"order.Return order attachment": `退单附件`,
"order.Return order information": `退单信息`,
"order.Return order number": `退单编号`,
"order.Return order status": `退单状态`,
"order.Return quantity": `退货数量`,
"order.Return reason": `退货理由`,
"order.Return unit price": `退货单价`,
"order.Returnable number": `可退货数量`,
"order.SKU code": `SKU`,
"order.Select Color": `选择颜色`,
"order.Select return products": `选择退货产品`,
"order.Shipping status": `发货状态`,
"order.Skip logistics": `跳过物流`,
"order.Specification": `规格`,
"order.Subtotal of return amount": `退单金额小计`,
"order.To": `到`,
"order.To be delivered": `待发货`,
"order.To be received": `待收货`,
"order.To refund amount": `待退款金额`,
"order.Total returnable number": `可退货总数`,
"order.Weight": `重量`,
"order.arrangeShipment": `安排发货`,
"order.carrier.all": `所有物流商`,
"order.carrier.best": `百世快递`,
"order.carrier.sf": `顺丰快递`,
"order.carrier.sto": `申通快递`,
"order.carrier.yto": `圆通快递`,
"order.carrier.yunda": `韵达快递`,
"order.carrier.zto": `中通快递`,
"order.carrierCompany": `物流公司`,
"order.carrierCompanyTip": `请选择物流公司`,
"order.carrierNumber": `物流编号`,
"order.carrierNumberTip": `请输入物流编号`,
"order.carrier_info": `物流信息`,
"order.carrier_name": `物流商名称`,
"order.carrier_number": `物流编号`,
"order.completeTip": `请问您确认要完成订单吗？`,
"order.completed": `已完成`,
"order.confirm refund p1": `你确认退款了吗？`,
"order.confirm refund p2": `退款的金额和积分是多少？`,
"order.confirm refund p3": `默认情况下，产品的实际金额不包括服务费和运费。`,
"order.consumer_information": `消费者信息`,
"order.create_date": `订单创建日期`,
"order.export.download_tip": `这是您尚未下载的报告`,
"order.export.export": `导出`,
"order.export.latest_reports": `最新报告`,
"order.export.my_exports": `我的报告`,
"order.export.report_name": `报告名称`,
"order.export.view_all": `查看所有内容`,
"order.information": `产品信息`,
"order.operation_log": `操作日志`,
"order.orderCompleted": `订单已完成`,
"order.orderId": `订单号`,
"order.orderTime": `订单创建时间`,
"order.order_amount": `订单金额`,
"order.order_info": `产品信息`,
"order.order_status": `订单状态`,
"order.order_total": `订单总金额`,
"order.order_type": `订单类型`,
"order.orders": `订单`,
"order.payChannelName": `支付平台`,
"order.payWayName": `支付类型`,
"order.payment": `支付`,
"order.payment_method": `支付方式`,
"order.payment_status": `支付状态`,
"order.payment_time": `付款时间`,
"order.pet_owner_name": `消费者姓名`,
"order.process": `订单流程`,
"order.product_amount": `产品金额`,
"order.products": `产品`,
"order.promotion_amount": `促销金额`,
"order.receiver": `收件人`,
"order.rule1": `10分钟内下10个以上订单`,
"order.rule2": `消费者阻止列表`,
"order.rule3": `订单生成后`,
"order.setting.Customize order number format and this change will apply to future orders": `自定义订单号格式，此更改将应用于未来的订单`,
"order.setting.Fraud Detection Criteria": `欺诈检测标准`,
"order.setting.Number format": `编号格式`,
"order.setting.Order Number Format": `订单号格式`,
"order.setting.Order not generated": `订单未生成`,
"order.setting.Return Order": `退单`,
"order.setting.Sales Order": `订单`,
"order.setting.Value": `值`,
"order.setting.general": `订单设置`,
"order.setting.prefix": `前缀`,
"order.setting.suffix": `后缀`,
"order.setting.tip_1": `在`,
"order.setting.tip_2": `分钟后如果PO未成功付款订单将自动取消`,
"order.setting.tip_3": `天后（订单状态发货开始计算）订单将自动完成`,
"order.shipmentDate": `发货日期`,
"order.shipmentDateTip": `请选择发货日期`,
"order.shipping_address": `收货地址`,
"order.shipping_fee": `运费`,
"order.status.cancel": `订单已取消`,
"order.status.completed": `订单已完成`,
"order.status.init": `订单已创建`,
"order.status.pay": `支付成功`,
"order.status.ship": `订单已发货`,
"order.status.tab.all": `全部`,
"order.status.tab.canceled": `取消`,
"order.status.tab.completed": `已完成`,
"order.status.tab.shipped": `已发货`,
"order.status.tab.to_ship": `待发货`,
"order.status.tab.unpaid": `未付款`,
"order.subscriptionId": `订阅号`,
"order.transaction_id": `交易编码`,
"order.type.single": `一次性订单`,
"order.type.subscription": `订阅`,
"order.variation": `规格`,
"order.viewDetail": `查看订单详情`,
"order.view_amount_detail": `查看订单金额详情`,
"order.view_carrier_detail": `查看物流详细信息`,
"orders.Abnormal": `异常`,
"orders.Abnormal address": `异常地址`,
"orders.Abnormal logistics": `异常物流`,
"orders.Abnormal product": `异常商品`,
"orders.Actual Ship Date": `实际发货日期`,
"orders.Add Tag": `添加标签`,
"orders.Address": `地址`,
"orders.Amount": `金额`,
"orders.Amount Paid": `实付金额`,
"orders.Amount Paid Tip": `总实付金额`,
"orders.Apply Hold": `挂起`,
"orders.Audit Log": `操作日志`,
"orders.Audit Log Detail": `操作日志详情`,
"orders.Audit Order": `审核订单`,
"orders.Audit Order Notification": `审核订单通知`,
"orders.Audit order": `审核订单`,
"orders.Authorized Amount": `授权金额`,
"orders.Authorized Time": `授权时间`,
"orders.Back Order": `异常订单`,
"orders.Back Order Comment": `异常订单`,
"orders.Back Order Type": `异常订单类型`,
"orders.Bill to": `发票地址`,
"orders.Cancel Order": `取消订单`,
"orders.Cancel Order Note": `注：取消订单后，金额和积分将按原方式退款，具体退款时间由PSP退款决定`,
"orders.Cancelled": `取消`,
"orders.Captured Amount": `扣款金额`,
"orders.Captured Time": `扣款时间`,
"orders.Carrier": `物流公司`,
"orders.Channel": `渠道`,
"orders.Charges": `金额信息`,
"orders.City": `城市`,
"orders.Collection": `收集`,
"orders.Completed": `完成`,
"orders.Confirm Delivery": `确认收货`,
"orders.Consumer Name": `消费者名称`,
"orders.Country": `国家`,
"orders.Customer cancel order": `消费者要求取消订单`,
"orders.Delivered": `已签收`,
"orders.Designated Fulfillment Method": `指定履约方式`,
"orders.Designated logistic": `指定物流`,
"orders.Designated store": `指定门店`,
"orders.Designated warehouse": `指定仓库`,
"orders.Email Address": `邮箱`,
"orders.Expected Ship Date": `预期发货日期`,
"orders.External Order Created Time": `外部订单创建时间`,
"orders.External Order Number": `外部订单号`,
"orders.Failed": `寻源失败`,
"orders.Fraudulent order": `欺诈订单`,
"orders.Hold": `挂起`,
"orders.Invoice Amount": `开票金额`,
"orders.Invoice Information": `发票信息`,
"orders.Invoice Method": `开票方式`,
"orders.Invoice Number": `开票单号`,
"orders.Invoice Status": `开票状态`,
"orders.Invoice Time": `开票时间`,
"orders.Invoice Title": `开票抬头`,
"orders.Line Qty": `行数量`,
"orders.Locked": `锁住`,
"orders.Logistic Company": `物流公司`,
"orders.Logistic Detail": `物流详情`,
"orders.Logistic Number": `物流单号`,
"orders.Manual Price Adjustment": `总手工调价`,
"orders.Manual Price Adjustment Tip": `订单手工调价和商品手工调价的总和`,
"orders.Manual Sourcing": `手动寻源`,
"orders.Method": `支付方式`,
"orders.Node": `物理仓库`,
"orders.Not Sourcing": `未寻源`,
"orders.Operation Log": `操作日志`,
"orders.Operation Time": `操作时间`,
"orders.Operation type": `操作类型`,
"orders.Operator": `操作人`,
"orders.Order Amount": `订单总金额`,
"orders.Order Amount Detail": `订单金额明细`,
"orders.Order Amount Tip": `总订单应付金额`,
"orders.Order Created Time": `订单创建时间`,
"orders.Order Discount": `订单优惠`,
"orders.Order Discount Tip": `订单折扣包括商品和订单级别折扣`,
"orders.Order Lines": `商品信息`,
"orders.Order Number": `订单号`,
"orders.Order Status": `订单状态`,
"orders.Order Summary": `订单总览`,
"orders.Order Tag": `订单标签`,
"orders.Order Type": `订单类型`,
"orders.Other": `其他`,
"orders.Out of stock": `缺货`,
"orders.Out of stock product item": `缺货商品`,
"orders.Outbound": `出库`,
"orders.Outbound Number": `出库单号`,
"orders.Outbound Qty": `出库数量`,
"orders.Outbound Status": `出库状态`,
"orders.Outbound Warehouse": `出库仓库`,
"orders.Parcel Detail": `包裹详情`,
"orders.Partially Shipped": `部分发货`,
"orders.Payment": `支付信息`,
"orders.Payment Method": `支付方式`,
"orders.Payment Process": `支付流程`,
"orders.Payment Records": `支付记录`,
"orders.Payment Time": `支付时间`,
"orders.Pending Review": `待审核`,
"orders.Phone Number": `电话号码`,
"orders.Please enter your remarks": `请输入您的备注`,
"orders.Please input Order Number": `请输入订单号`,
"orders.Post Code": `邮政编码`,
"orders.Process": `处理`,
"orders.Product Amount": `商品金额`,
"orders.Product Amount Tip": `等于商品销售价*商品购买数量的总和`,
"orders.Product Qty": `商品数量`,
"orders.Products Name": `商品名称`,
"orders.Provider": `支付提供商`,
"orders.Reason for Cancellation": `取消理由`,
"orders.Receiver": `收件人`,
"orders.Receiver Name": `收件人`,
"orders.Refund": `退款`,
"orders.Refund Status": `退款状态`,
"orders.Refund Time": `退款时间`,
"orders.Related Order": `关联订单`,
"orders.Remark": `备注`,
"orders.Requested Ship Date": `请求发货日期`,
"orders.Resolved Hold": `解挂`,
"orders.Resolved Hold Tip": `您确认解挂这笔订单吗？`,
"orders.Resourcing": `再次寻源`,
"orders.Result": `结果`,
"orders.Select Warehouse Sourcing Rule": `选择仓库寻源规则`,
"orders.Seller": `销售店铺`,
"orders.Ship to": `收货地址`,
"orders.Shipment": `发货`,
"orders.Shipment Information": `发货信息`,
"orders.Shipment Number": `发货面单号`,
"orders.Shipment Qty": `发货商品数`,
"orders.Shipped": `已发货`,
"orders.Shipping Fee": `运费`,
"orders.Shipping Fee Tip": `产品需要交付给消费者时的运费。`,
"orders.Shipping Status": `发货状态`,
"orders.Shipping fee Discount": `运费优惠`,
"orders.Shipping fee Discount Tip": `运费折扣`,
"orders.Sourcing Status": `寻源状态`,
"orders.Status": `支付状态`,
"orders.Success": `寻源成功`,
"orders.Tag Preview": `标记预览`,
"orders.Text Content": `文本内容`,
"orders.To Ship": `待发货`,
"orders.Total Amount": `总金额`,
"orders.Total Discount": `总折扣`,
"orders.Total Order amount": `总订单金额`,
"orders.Total Products Amount": `总商品金额`,
"orders.Total Tax": `总税费`,
"orders.Total Used Point": `总使用积分`,
"orders.Tracking Detail": `物流详情`,
"orders.UOM": `UOM`,
"orders.User": `用户`,
"orders.View Product Detail": `查看商品详情`,
"orders.View Tracking Detail": `查看物流详情`,
"orders.apply hold Tip": `您确认挂起这笔订单吗？`,
"orders.payChannelName": `支付频道`,
"org.address information": `地址信息`,
"org.buyer": `买方`,
"org.buyer_tip": `当一个组织从企业或其他被设置为卖方的组织购买产品时它被分配买方角色。`,
"org.carrier": `承运商`,
"org.carrier_tip": `当一个组织在买方、卖方和客户之间提供送货服务时它被分配了承运人的角色。`,
"org.contacts name": `联系人`,
"org.enterprice_tip": `组织结构中的每个组织都必须是一个企业或者指定一个企业作为其主要企业。`,
"org.enterprise": `企业`,
"org.fixed telephone": `固定电话`,
"org.input organization name": `请输入组织名称`,
"org.mobile phone": `电话号码`,
"org.node": `节点`,
"org.node_tip": `节点表示一个物理位置（例如制造厂、小型仓库或仓库）。节点还可以扮演买方或卖方的角色。`,
"org.organization code": `组织代码`,
"org.organization list": `组织列表`,
"org.organization name": `组织名称`,
"org.organization role": `组织角色`,
"org.parent organization": `父级组织`,
"org.seller": `卖方`,
"org.seller_tip": `当一个组织向企业或其他被设置为买方的组织销售产品时它被分配卖方角色。`,
"pageTree.view": `视图:`,
"partner.From to": `至`,
"partner.Remove from partner list": `从合作伙伴的列表中移除`,
"payment.api_v3_key": `API_V3_KEY`,
"payment.appid": `应用程序ID`,
"payment.certificate_number": `证书编号`,
"payment.merchant_account": `商户账户`,
"payment.private_key": `私钥`,
"payment.provider": `提供商`,
"payment.public_key": `公钥`,
"payment.status": `状态`,
"payment.supported_payment": `支付方式配置`,
"payment.supported_tip": `由DTC Center批准的支付服务提供商提供的支付方式。`,
"payment.url": `URL`,
"peocessCanvas.Campaign Plan Overview": `流程画布`,
"petowner.Current Tier": `当前等级`,
"petowner.Fisrt Order Date": `首单日期`,
"petowner.Guest": `游客`,
"petowner.Holding Points": `持有积分数量`,
"petowner.Last Order Date": `最后订单日期`,
"petowner.Member": `会员`,
"petowner.Points": `积分`,
"petowner.Rewards History": `奖励历史`,
"petowner.Tier Change History": `会员级别变更历史`,
"petowner.Tier End Time": `等级结束时间`,
"petowner.Tier Start Time": `等级开始时间`,
"petowner.Tier Start and End Time": `积分有效期`,
"petowner.address.address": `地址`,
"petowner.address.city": `城市`,
"petowner.address.consumertype": `消费者类型`,
"petowner.address.district": `地区`,
"petowner.address.phone_number": `电话号码`,
"petowner.address.postal_code": `邮政编码`,
"petowner.address.province": `省`,
"petowner.address.receiver_name": `接收方名称`,
"petowner.age": `年龄`,
"petowner.basic_info": `基本信息`,
"petowner.birth_date": `出生日期`,
"petowner.breed": `品种`,
"petowner.email": `电子邮件`,
"petowner.follow_status": `跟踪状态`,
"petowner.follow_status.followed": `关注`,
"petowner.follow_time": `关注时间`,
"petowner.gender": `性别`,
"petowner.login_time": `登录时间`,
"petowner.loyaltyProgram": `忠诚度计划`,
"petowner.months": `月`,
"petowner.my_address": `我的地址`,
"petowner.my_pet_owner": `消费者列表`,
"petowner.openid": `OpenId`,
"petowner.order_info": `订单信息`,
"petowner.pet_category": `宠物类别`,
"petowner.pet_info": `宠物信息`,
"petowner.pet_name": `宠物名称`,
"petowner.profile_photo": `头像`,
"petowner.smart_device": `智能设备`,
"petowner.smart_device.locked_time": `锁定时间`,
"petowner.smart_device.name": `智能设备名称`,
"petowner.smart_device.sku": `智能设备SKU`,
"petowner.smart_device.subscription_no": `订阅ID`,
"petowner.smart_device.subscription_time": `订阅时间`,
"petowner.social_account": `社交媒体账户`,
"petowner.sterillized": `绝育`,
"petowner.sterillized_status": `是否绝育`,
"petowner.subscription_info": `订阅信息`,
"petowner.tagging": `标签`,
"petowner.tags": `标签`,
"petowner.unionid": `UNIONID`,
"petowner.unsterillized": `未绝育`,
"petowner.user_type": `用户类型`,
"petowner.voucher_info": `优惠券信息`,
"petowner.wechat_name": `用户名`,
"please enter": `请输入`,
"please input SMS template content": `请输入SMS模板内容`,
"please input SMS template description": `请输入SMS模板描述`,
"please input SMS template name": `请输入SMS模板名称`,
"please input email template name": `请输入邮件模板名称`,
"please input right email": `请输入正确的邮箱地址`,
"please input right number": `请输入正确的数字`,
"please input right phone": `请输入正确的电话`,
"please input scenario description": `请输入场景描述`,
"please input scenario link": `请输入场景链接`,
"please select": `请选择`,
"please select Associated Store": `请选择关联店铺`,
"please select Associated logical warehouse": `请选择关联逻辑仓库`,
"please select Physical Warehouse": `请选择实体仓库`,
"please select push pushCategory": `请选择推送类型`,
"please select push pushChannel": `请选择推送渠道`,
"please select sendgrid template name": `请选择SendGrid模板`,
"please select signature": `请选择签章`,
"please select template type": `请选择模板类型`,
"points.Add ways to earn": `增加积分赚取方式`,
"points.Add ways to redeem": `增加积分核销方式`,
"points.Calendar Year": `日历年`,
"points.Create Loyalty Program": `创建忠诚度计划`,
"points.Create Rewards": `创建奖励`,
"points.Customized Loyalty Program": `去体验您定制的忠诚度计划！`,
"points.Earn Points": `赚取积分`,
"points.Earn Points Description": `创造消费者加入、分享和参与您的品牌时获得积分的方式。`,
"points.Expired Points": `过期积分数量`,
"points.Lifetime": `生命周期`,
"points.Outstanding balance": `余额`,
"points.Points": `积分`,
"points.Points Expiration Term": `积分到期期限`,
"points.Points Expiry": `积分到期`,
"points.Points Expiry Description": `设置消费者的积分到期时间。设置积分过期时间可以有效提升用户的活跃度。有三个选项可用：`,
"points.Points Expiry Description1": `日历年：积分在年底过期。`,
"points.Points Expiry Description2": `滚动年：积分在12个月后过期`,
"points.Points Expiry Description3": `终生：积分不会过期。`,
"points.Points Summary": `积分汇总`,
"points.Points Summary Description": `积分分发和核销历史。`,
"points.Points expiry is enabled": `积分过期已启用`,
"points.Redeem Points": `核销积分`,
"points.Redeem Points Description": `创建可供消费者使用积分进行核销的奖励。`,
"points.Redeemed Points": `已兑换积分`,
"points.Rolling Year": `滚动年`,
"points.Set up Points Rules": `设置积分规则`,
"points.Set up Tiers": `设置等级`,
"points.Start From Here": `从这里开始`,
"points.Total Distributed Points": `分发积分总数`,
"points.View and manage all points history": `查看和管理所有积分历史记录`,
"points.Ways to earn": `赚取积分的方法`,
"points.Ways to redeem": `核销积分方式`,
"points.description": `那里鈥檚 尚未创建忠诚度计划！请注意您需要先创建忠诚度计划然后设置积分相关规则。预期的创建流程如下。“n`,
"points.step1": `步骤1`,
"points.step2": `步骤2`,
"points.step3": `步骤3`,
"points.step4": `步骤4`,
"points.step5": `步骤5`,
"points.winning": `积分奖励可以有效吸引消费者并激励他们更频繁地与您的品牌互动。这种互动可以进一步提高收入并显著提升您的消费者满意度。您可以在此检查积分奖励和兑换并配置基于业务的积分赚取和兑换规则。`,
"pointsSetting": `积分设置`,
"pos.In stock": `有货`,
"pos.Out of stock": `缺货`,
"pos.checkout.cash": `现金`,
"pos.checkout.checkout": `立即下单`,
"pos.checkout.consent": `条款`,
"pos.checkout.consent_rule": `该订单通过我们的合作伙伴Intervino UK（AWRS XBAW00000104406）完成。请查看并接受<a href=“https://www.malts.com/en-gb/bsq-terms-of-sale-uk“style=｛｛color:'#197bbd'｝｝target=”_black“>销售条款</a>然后继续。`,
"pos.checkout.consent_rule_name": `销售条款`,
"pos.checkout.consumer_information": `消费者信息`,
"pos.checkout.discount": `折扣`,
"pos.checkout.email": `邮箱`,
"pos.checkout.items": `数量`,
"pos.checkout.pay_now": `立即支付`,
"pos.checkout.payment_method": `支付方式`,
"pos.checkout.phone": `电话号码`,
"pos.checkout.return_to_cart": `返回购物车`,
"pos.checkout.subtotal": `小计`,
"pos.checkout.total": `总计`,
"pos.search_consumer_email": `搜索消费者邮箱`,
"pos.search_items": `搜索商品`,
"pos.shopping_cart": `购物车`,
"post.Meta Data": `基础信息`,
"postAddTitlePlaceholder": `请输入博客名字`,
"posts.CategoriesButtonText": `新增类别`,
"posts.CategoriesDesc": `创建、编辑和管理店铺中的博客类别`,
"posts.CategoriesModalTitle": `新增类别`,
"posts.CategoriesTitle": `类别`,
"posts.CategoriesTitle2": `个类别`,
"posts.Category Name": `类别名称`,
"posts.Category Placeholder": `请输入类别名称`,
"posts.Category Slug": `类别Slug`,
"posts.Slug": `Slug`,
"posts.Slug Placeholder": `请输入Slug`,
"posts.Tags Name": `标签名称`,
"posts.Tags Placeholder": `请输入标签名称`,
"posts.Tags Slug": `标签 Slug`,
"posts.TagsButtonText": `新增标签`,
"posts.TagsDesc": `创建、编辑和管理店铺中的博客标签`,
"posts.TagsModalTitle": `新增标签`,
"posts.TagsTitle": `标签`,
"posts.TagsTitle2": `个标签`,
"posts.allPosts": `所有博客`,
"posts.postsTitle": `创建、编辑和管理网站上的帖子。`,
"potential legal risks": `潜在的法律风险`,
"preferences.Adroll": `Adroll`,
"preferences.Adroll pop title": `如何获取您的Adroll ID？`,
"preferences.Analytics Integration": `分析&定位工具集成`,
"preferences.Baidu Statistic ID": `百度统计`,
"preferences.Baidu Statistic pop title": `如何获取百度统计脚本？`,
"preferences.Enable reCAPTCHA on contact": `在联系页面启用Google reCAPTCHA`,
"preferences.Enable reCAPTCHA on login": `在登录、创建账户以及密码页面启用Google reCAPTCHA。`,
"preferences.Facebook Pixel ID": `Facebook Pixel ID`,
"preferences.Facebook Pixel pop title": `如何获取Facebook Pixel ID？`,
"preferences.Featured Image": `主图`,
"preferences.GTM ID": `GTM ID`,
"preferences.GTM pop title": `如何获取您的Google Tag Manager (GTM) ID？`,
"preferences.Google Analytics ID": `Google Analytics ID`,
"preferences.Google Analytics pop title": `如何获取您的Google Analytics ID？`,
"preferences.Image": `图片`,
"preferences.Preview": `预览`,
"preferences.Recommended size": `（推荐尺寸：1200 x 628 像素）`,
"preferences.Social Sharing": `社交媒体分享`,
"preferences.Social Sharing tip": `当您在社交媒体上分享到您的商店链接时，通常会显示一张图片在您的帖子中。`,
"preferences.Spam Protection": `垃圾邮件保护`,
"preferences.Spam Protection tip": `通过启用Google reCAPTCHA来保护您的商店免受垃圾邮件和滥用。这可能需要一些客户完成一个reCAPTCHA任务。`,
"privacy.Cookie Management": `Cookie 管理`,
"privacy.Cookie Management.tip": `Cookie管理设置允许您集成第三方Cookie来显示和管理Cookie。`,
"privacy.Enable Cookie Banner": `启用 Cookie Banner`,
"privacy.Script": `脚本`,
"privacy.title.tip": `隐私设置提供内容可见性和访问控制、数据收集和存储以及cookie同意管理。这些设置使您能够建立与您的品牌特定需求和法律要求相一致的隐私偏好和策略。`,
"product.Add New Ribbon": `添加新功能区`,
"product.Add New Top Search": `新增热词`,
"product.Add a New Product": `新增商品`,
"product.Add add-on services": `新增附加服务`,
"product.Add-on Service": `附加服务`,
"product.Add-on Services": `附加服务`,
"product.Add-on Services info": `附加服务是根据产品本身应用的额外收费服务单击下面的蓝色按钮将您的定制服务添加到您的产品中！`,
"product.Are you sure want to delete the following product ? Warning": `请问您确认删除该商品吗？`,
"product.Are you sure want to delete the following product ? Warning: You cannot undo this action!": `请问您确认删除该商品吗？`,
"product.Are you sure want to delete the product(s) ? Warning": `请问您确认删除该商品吗？`,
"product.Are you sure want to delete the product(s) ? Warning: You cannot undo this action!": `请问您确认删除该商品吗？`,
"product.Audit": `审核`,
"product.Bundle": `组合商品`,
"product.Change Date": `修改日期`,
"product.Choose Category": `选择类别`,
"product.Choose Product Type": `选择产品类型`,
"product.Delete Product": `删除产品`,
"product.Delist": `下架`,
"product.Delisted": `已下架`,
"product.EAN": `EAN`,
"product.EANTip": `EAN：
  <p>EAN应与SKU相关联并符合编码规则</p>`,
"product.Edit History": `编辑历史`,
"product.Edit Ribbon": `编辑产品卡标签`,
"product.Edited By": `编辑人`,
"product.Feeding Days": `喂食时间`,
"product.Functions": `功能`,
"product.Gift Card": `礼品卡`,
"product.Hide": `隐藏`,
"product.Input Product Name": `请输入产品名称`,
"product.Input SKU": `请输入SKU`,
"product.Input SPU": `请输入SPU`,
"product.Life Stage": `生命阶段`,
"product.List Price": `划线价`,
"product.Live": `上架`,
"product.Live/Dellist": `上架/下架`,
"product.Live/DellistTip": `上架/下架
   <p> 上架：该SKU消费者可购买</p>
<p>下架：该SKU消费者不可购买</p>`,
"product.Modified Info": `修改信息`,
"product.More": `更多`,
"product.No category has been chosen": `未选择类目`,
"product.Not saleable": `不可销售`,
"product.Please enter description name": `请输入描述名称`,
"product.Please input stock": `请输入库存数量`,
"product.Preview": `预览`,
"product.Priority": `优先级`,
"product.Product Audit": `产品审核`,
"product.Product Type": `产品类型`,
"product.Products SKUs": `SKU`,
"product.Publish": `发布`,
"product.Regular": `常规`,
"product.Related Content": `相关内容`,
"product.Related Products": `关联商品`,
"product.Related Products info": `相关产品是除了您的客户当前正在查看的项目之外的推荐产品。下面配置的相关产品将显示在产品详细信息页面下。`,
"product.Relationship Type": `关系类型`,
"product.Ribbon color": `颜色`,
"product.Ribbon font": `字体`,
"product.Ribbon name": `产品卡标签名称`,
"product.Saleable": `可销售`,
"product.Sales Category": `销售类目`,
"product.Save and Delist": `保存草稿`,
"product.Save and Publish": `保存并发布`,
"product.Select a option and change input text above": `选择一个选项并更改输入文本`,
"product.Shipping": `发货`,
"product.Size": `尺寸`,
"product.Sold out": `售罄`,
"product.Sub-SKU": `子SKU`,
"product.Sub-SKUTip": `子SKU：<p>子SKU为SKU需要绑定和销售的子产品的编号</p>`,
"product.Subscription Price": `订阅价格`,
"product.SubscriptionTip": `订阅：<p>支持订阅请设置为Y</p>
  <p>不支持订阅请设置为N</p>`,
"product.Summary": `总结`,
"product.Support 100": `支持快递100`,
"product.Technology": `技术`,
"product.The currently selected": `当前选定的`,
"product.Tips": `提示`,
"product.To Search Name": `搜索名称`,
"product.To Search Name input": `请输入热词`,
"product.Top Search Name": `搜索热词`,
"product.Top Search is visible on shop": `搜索热词在商店中可见`,
"product.Variation": `规格`,
"product.Variations": `规格`,
"product.addOption": `新增选项`,
"product.addVariation": `新增规格`,
"product.add_category": `新增类目`,
"product.add_products": `新增产品`,
"product.add_subcategory": `添加子类别`,
"product.adjust_seq": `调整顺序`,
"product.attribute.Maximum characters": `最大字符数`,
"product.attributeTip": `产品属性：
  <p>产品属性与产品名称和描述相关</p>`,
"product.attribute_add_new_title": `新增属性`,
"product.attribute_basic_info": `属性基本信息`,
"product.attribute_create_tip": `请选择创建属性的类型`,
"product.attribute_display_name": `显示名称`,
"product.attribute_guidelines": `属性填写指南`,
"product.attribute_guidelines_title": `属性填写指南`,
"product.attribute_label": `属性名称`,
"product.attribute_multi_select": `多选`,
"product.attribute_no_options_tip": `请创建选项`,
"product.attribute_required": `必填`,
"product.attribute_single_select": `单选`,
"product.attribute_text": `Text`,
"product.attribute_type": `类型`,
"product.baseInfo": `基本信息`,
"product.brand": `品牌`,
"product.breeds": `品种`,
"product.category": `类目`,
"product.category_basic_info": `基本信息`,
"product.category_cannot_active": `因为该销售类目不包含任何产品所以它无法被激活`,
"product.category_delete_content_tip": `已删除的销售类目不可恢复。`,
"product.category_delete_title_tip": `请问您确定要删除此销售类目吗？`,
"product.category_description": `描述`,
"product.category_displayName": `显示名称`,
"product.category_display_name": `类目名称`,
"product.category_filter": `筛选器`,
"product.category_filter_display_name": `显示名称`,
"product.category_filter_status": `筛选状态`,
"product.category_image": `类目图片`,
"product.category_label": `类目名称`,
"product.category_name": `请选择产品类目`,
"product.category_seo_description": `元描述`,
"product.category_seo_keywords": `元关键字`,
"product.category_seo_setting": `SEO设置`,
"product.category_seo_slug": `Slug`,
"product.category_seo_title": `元标题`,
"product.category_type": `类别`,
"product.chooseCategoryTip": `请为您的产品选择合适的类目`,
"product.create_by": `创建者`,
"product.description": `产品描述`,
"product.descriptionTip": `产品描述:
  应该包括<br/>
  1.品牌<br/>
  2.重量<br/>
  3.好处<br/>
  4.喂养建议<br/>
  5.推荐产品<br/>
  6.产品保证<br/>`,
"product.details": `详细信息`,
"product.display_on_off": `显示开/关`,
"product.edit_filter_rule": `编辑筛选规则`,
"product.enter_category_name": `输入类目名称`,
"product.enter_display_name": `输入类目名称`,
"product.feedingDays": `喂食时间
<p>喂养时间应该根据产品规格合理填写</p>`,
"product.filter_results": `筛选结果`,
"product.image": `图片`,
"product.listPriceTip": `划线价：
  <p>划线价应含税并在一段时间内保持稳定<p>`,
"product.manual_selection": `手动选择`,
"product.manual_selection_tip": `手动选择需要包含在销售类目中的产品`,
"product.market_price": `市场价`,
"product.marketingPriceTip": `市场价格：
  <p>市场价格应包括增值税并在一段时间内相对稳定。</p＞`,
"product.miss_category_name": `缺少类目名称`,
"product.miss_display_name": `缺少显示名称`,
"product.my_shop_category": `销售类目`,
"product.name": `产品名称`,
"product.opration": `操作`,
"product.parcelSize": `包裹尺寸`,
"product.parcelSizeTip": `包裹尺寸：
  <p>为了系统能够准确分配包裹请输入包裹尺寸而非产品尺寸。</p＞`,
"product.price": `价格`,
"product.price(s)": `价格`,
"product.product": `产品`,
"product.productImage": `产品图片`,
"product.productImageTip1": `请保证每一张照片都有高清的分辨率以确保放大时像素不会出现损坏情况`,
"product.productImageTip2": `产品图片最多可添加9张`,
"product.productNameRequired": `需要产品名称`,
"product.productNameTip": `产品名称：<p>简要概括产品</p>`,
"product.productRibbon": `产品卡标签`,
"product.productTag": `产品标签`,
"product.productThumbnail": `产品缩略图`,
"product.productTypeRequired": `需要产品类型`,
"product.productVideo": `产品视频`,
"product.productVideoTip1": `1.尺寸：最大30Mb分辨率不应超过1280x1280px`,
"product.productVideoTip2": `2.持续时间：10-60秒`,
"product.productVideoTip3": `3.格式：MP4`,
"product.productVideoTip4": `4. 注释：您可以在处理视频时发布此商品。成功处理后视频<br/>将显示在列表中。`,
"product.product_category": `产品类目`,
"product.product_list": `产品列表`,
"product.product_name": `产品名称`,
"product.product_s": `产品`,
"product.product_will_add_to_category": `如果您的产品符合筛选条件它们将自动添加到您的销售类目中`,
"product.products": `产品`,
"product.products_found": `搜索出｛num｝个产品`,
"product.products_selected": `已选择｛num｝个产品`,
"product.rule_based_filter": `基于规则的过滤`,
"product.rule_based_tip": `将依据您的筛选条件将对应产品自动加入到销售类目中`,
"product.salesInfo": `销售信息`,
"product.salesStatus": `销售状态`,
"product.salesStatusTip": `销售状态：
  <p>需要在商店中展示和销售的产品应设置为可销售</p>
  <p>不需要在商店内展示和销售的产品应设置为不可销售</p>`,
"product.search_products": `搜索产品`,
"product.select_opt_and_change": `选择一个选项并更改输入文本`,
"product.select_products": `选择产品`,
"product.set_filter_rule": `设置筛选规则`,
"product.skuName": `SKU名称`,
"product.skuNameTip": `SKU名称`,
"product.skuTip": `SKU：<p>SKU应该是唯一且符合编码规则的</p>`,
"product.specification": `规格`,
"product.specificationTip": `填写更多产品属性以让消费者更了解您的产品。`,
"product.spuTip": `SPU：＜p＞SPU应该是唯一的并且符合编码规则</p＞`,
"product.stock": `库存`,
"product.stockTip": `库存：
  <p>库存数量应反映准备发货的实际库存</p＞
  <p>如果没有库存请填写0以避免未交货率（NFR）或延迟发货率（LSR）异常</p＞`,
"product.subscriptionPriceTip": `订阅价格：
<p>订阅价格应该包括税费并且不得超过市场价格。</p>
<p>如果不支持订阅则无需填写订阅价格。</p>`,
"product.support100Tip": `支持快递100：
  <p>产品生产日期在100天内设置为Y不在100天时设置为N</p>`,
"product.update": `更新`,
"product.variationImageTip": `规格图像：
<p>规格的图像应与规格的名称保持一致</p>
<p>所有图像应保持格式一致</p>`,
"product.variationTip": `最多可设置两层规格`,
"product.weight": `重量`,
"product.weightTip": `产品重量：
  <p>应在产品准备配送时输入重量即产品重量+包装重量</p>`,
"product.zone": `区域`,
"productExportAlert": `导出报告任务当前正在进行中，很快将在销售渠道/导出报告中提供。`,
"promotions.Actions": `操作`,
"promotions.Add Gifts": `新增礼品`,
"promotions.Add Products": `新增产品`,
"promotions.All": `全部`,
"promotions.Applicable Products": `适用产品`,
"promotions.Automatic discount": `自动折扣`,
"promotions.Basic Information": `基本信息`,
"promotions.Buy X get Y": `买X送Y`,
"promotions.Consumers": `消费者`,
"promotions.Create": `创建`,
"promotions.Create New Promotion": `创建新促销`,
"promotions.Date from": `数据来源`,
"promotions.Discount Amount": `折扣金额`,
"promotions.Discount Code": `折扣代码`,
"promotions.Discount Type": `折扣类型`,
"promotions.Discount Type & Amount": `折扣类型和金额`,
"promotions.Expired": `已过期`,
"promotions.Fix Amount": `固定金额`,
"promotions.Free Shipping": `免费送货`,
"promotions.GSV": `GSV`,
"promotions.Gifted Products": `礼品`,
"promotions.Key Metrics": `关键指标`,
"promotions.Manual Generation": `手动生成`,
"promotions.Minimum Basket price": `最低客单价`,
"promotions.Ongoing": `正在进行`,
"promotions.Order": `订单`,
"promotions.Order Discount": `订单折扣`,
"promotions.Order Type": `订单类型`,
"promotions.Please enter promotion type": `请输入促销类型`,
"promotions.Product Discount": `产品折扣`,
"promotions.Promotion Description": `促销描述`,
"promotions.Promotion List": `促销列表`,
"promotions.Promotion Method": `促销方法`,
"promotions.Promotion Name": `促销名称`,
"promotions.Promotion Title": `促销标题`,
"promotions.Promotion Type": `促销类型`,
"promotions.Promotion image": `促销图片`,
"promotions.Recurrence": `循环`,
"promotions.Rule Setting": `规则设置`,
"promotions.Status": `状态`,
"promotions.The recommended size for images is 100px *100px": `建议的图片大小为100px*100px`,
"promotions.Total amount of all confirmed orders using promotion.": `使用促销的所有已确认订单的总额。`,
"promotions.Total number of confirmed orders using promotion.": `促销。使用促销的确认订单总数。`,
"promotions.Total number of unique consumers who used promotion in confirmed orders.": `在确认订单中使用促销的消费者总数。`,
"promotions.UTC+8": `UTC+8`,
"promotions.Unlimited": `无限`,
"promotions.Upcoming": `即将到来`,
"promotions.Usage": `用法`,
"promotions.Usage Limit": `使用次数限制`,
"promotions.Usage Quantity": `使用数量`,
"promotions.Valid Period": `有效期`,
"promotions.actionFaild": `操作无效。请稍后再试`,
"promotions.image size should not exceed 1M.": `图像大小不应超过1M。`,
"promotions.max": `需要小于或等于阈值`,
"promotions.number": `请输入数字`,
"promotions.quantity": `数量`,
"promotions.to": `至`,
"public.AND": `和`,
"public.All": `全部`,
"public.Are you sure you want to pause this subscription?": `请问您是否确定要暂停此订阅？`,
"public.Are you sure you want to restart this subscription?": `请问您是否要重新启动此订阅？`,
"public.Close": `关闭`,
"public.Condition": `条件`,
"public.Detail": `详细信息`,
"public.Disable": `禁用`,
"public.Edit": `编辑`,
"public.Export": `导出`,
"public.Failed": `失败`,
"public.Field": `字段`,
"public.Hidedetails": `隐藏详情`,
"public.Import": `导入`,
"public.No data": `暂无数据`,
"public.OR": `或`,
"public.Operation": `操作`,
"public.Operation Successful": `操作成功！`,
"public.Operations": `操作`,
"public.Options": `操作`,
"public.Please Input": `请输入相应内容`,
"public.Please Select": `请选择`,
"public.Preview": `预览`,
"public.SKU": `SKU`,
"public.SPU": `SPU`,
"public.Successed": `成功`,
"public.Update": `更新`,
"public.Value": `价值`,
"public.action": `操作`,
"public.action_in_progress": `运行中`,
"public.actions": `操作`,
"public.add": `添加`,
"public.approve": `通过`,
"public.are_you_sure_delete": `请问您确定要删除该项目吗？`,
"public.are_you_sure_disable": `请问您确认要禁用此项目吗？`,
"public.are_you_sure_enable": `请问您确认要启用此项目吗？`,
"public.audit": `审核`,
"public.back": `后退`,
"public.cancel": `取消`,
"public.clear_all": `清除全部`,
"public.confirm": `确认`,
"public.confirm_delete": `确认删除`,
"public.confirm_tip": `请问您确定要执行此操作吗？`,
"public.create": `创建`,
"public.date": `日期`,
"public.delete": `删除`,
"public.deleteItemTip": `请问您确定要删除该项目吗？`,
"public.delete_description": `已删除的项目不可恢复。`,
"public.delete_item": `删除项目`,
"public.details": `查看详细信息`,
"public.disableItemTip": `请问您确定要禁用该项目吗？`,
"public.disable_item": `禁用项目`,
"public.download": `下载`,
"public.edit": `编辑`,
"public.enable": `启用`,
"public.enableItemTip": `请问您确定要启用该项目吗？`,
"public.enable_item": `启用项目`,
"public.field_required": `此字段为必填字段`,
"public.file_upload_failed": `文件上传失败`,
"public.file_upload_only_jpg": `您只能上传JPG/PNG文件！`,
"public.file_upload_smaller_than_2M": `图片必须小于2MB！`,
"public.file_upload_success": `文件上传成功`,
"public.input": `输入`,
"public.moreActions": `更多选项`,
"public.new": `新建`,
"public.next": `下一步`,
"public.next_step": `下一步`,
"public.no": `否`,
"public.no.": `放弃`,
"public.no_more_than_200": `不超过两百个字符`,
"public.notice": `通知`,
"public.ok": `确认`,
"public.operate_fail": `操作失败`,
"public.operate_success": `操作成功`,
"public.option": `操作`,
"public.pause": `暂停`,
"public.pleaseEnter": `请输入相应内容`,
"public.pleaseEnterAccordingToRules": `请按规则输入`,
"public.pleaseInput": `请输入相应内容`,
"public.pleaseSelect": `请选择`,
"public.preview": `预览`,
"public.previous_step": `上一步`,
"public.reject": `拒绝`,
"public.reset": `重置`,
"public.run": `运行`,
"public.save": `保存`,
"public.search": `搜索`,
"public.select": `选择`,
"public.shop_sitemap_download": `店铺Sitemap下载`,
"public.status": `状态`,
"public.succeed": `成功！`,
"public.upload": `上传`,
"public.yes": `是`,
"publishing and will be published within 10 minutes": `您的页面 {arg1} 正在发布，并将在 10 分钟内发布。您稍后可以通过 {arg2} 访问您的实时页面。`,
"qrCode.Add QR Code": `添加二维码`,
"qrCode.Banner Name": `横幅名称`,
"qrCode.Click Type": `点击类型`,
"qrCode.Comment": `备注`,
"qrCode.Default": `默认`,
"qrCode.Expired Time": `过期时间`,
"qrCode.Mini Program": `小程序`,
"qrCode.Official Account": `官方账户`,
"qrCode.Path": `路径`,
"qrCode.Permanent integer parameter": `永久整数参数`,
"qrCode.Permanent string parameter": `永久字符串参数`,
"qrCode.Pic Location": `照片位置`,
"qrCode.Please input QR Code Name!": `请输入二维码名称`,
"qrCode.Please input Scenario ID!": `请输入场景ID`,
"qrCode.Please input Scenario STR and do not contain _": `请输入方案STR且不包含_`,
"qrCode.Please select QR Code Type!": `请选择二维码类型！`,
"qrCode.Please select Reply Content!": `请选择回复内容！`,
"qrCode.QR Code Name": `二维码名称`,
"qrCode.QR Code Type": `二维码类型`,
"qrCode.Response Content": `响应内容`,
"qrCode.Scenario ID": `场景ID`,
"qrCode.Scenario STR": `情景STR`,
"qrCode.Sort": `排序`,
"qrCode.Temporary integer parameter": `临时整数参数`,
"qrCode.Temporary string parameter": `临时字符串参数`,
"qrCode.Wechat Account": `微信账号`,
"quantity (item)": `数量`,
"quickstart.4 Key Capability Modules": `4个关键能力`,
"quickstart.4 Key Capability Modules.1": `全渠道数据接入`,
"quickstart.4 Key Capability Modules.2": `消费者行为洞察`,
"quickstart.4 Key Capability Modules.3": `消费者标签管理`,
"quickstart.4 Key Capability Modules.4": `消费者分群包管理`,
"quickstart.Add New Product": `新增产品`,
"quickstart.Attributes": `属性`,
"quickstart.Basic Setting": `基本设置`,
"quickstart.Bind a new inventory network": `绑定新的物流网络`,
"quickstart.Channel": `渠道仓`,
"quickstart.Consumer Experiences": `消费者体验`,
"quickstart.Consumer Experiences.desc": `消费者体验门户是从多个来源收集和统一第一方消费者数据以构建每个消费者的单一、一致、完整的视图然后将消费者细分为其他应用程序使用。`,
"quickstart.Consumer Segment": `人群包`,
"quickstart.Consumer Segment.desc.1": `人群包列表显示已创建的人群包和相关信息。`,
"quickstart.Consumer Segment.desc.2": `添加一个新的人群包。`,
"quickstart.Consumer Segment.desc.3": `导出创建的分群包以供其他应用程序使用。`,
"quickstart.Consumer Tags": `消费者标签`,
"quickstart.Consumer Tags.desc.1": `标签列表展示了所有已创建标签及详情。`,
"quickstart.Consumer Tags.desc.2": `标签定时任务显示每个创建的标签的更新规则例如定期更新或手动更新。`,
"quickstart.Create New Channel Warehouse": `创建新渠道仓`,
"quickstart.Create New Logical Warehouse": `创建新逻辑仓`,
"quickstart.Create New Physical Warehouse": `创建新物理仓`,
"quickstart.Create New Store": `创建新店铺`,
"quickstart.Create New logistic": `创建新的物理商`,
"quickstart.Data Management": `数据管理`,
"quickstart.Data Management.desc.1": `所有元数据信息经过规范化配置后可保证数据接入的准确性。`,
"quickstart.Data Management.desc.2": `打破数据孤岛跨所有平台和渠道接入全渠道数据。`,
"quickstart.Data Management.desc.3": `通过不同渠道对消费者进行不同ID映射生成消费者Base ID为精细化营销运营提供支持。`,
"quickstart.Insights": `洞察`,
"quickstart.Insights.desc": `消费者洞察有助于全面了解每个统一的消费者深入了解每个消费者的行为分析和优化消费者旅程识别并降低损失风险。`,
"quickstart.Inventory Network Configuration": `库存网络配置`,
"quickstart.Learn more": `更多`,
"quickstart.Logical": `逻辑仓`,
"quickstart.Logistic Configuration": `物流配置`,
"quickstart.Maintenance cost price": `维护商品成本价`,
"quickstart.Maintenance marketing price": `维护商品零售价`,
"quickstart.New Price Aging": `新建价格时效`,
"quickstart.Order": `订单设置`,
"quickstart.Physical": `物理仓`,
"quickstart.Price": `价格管控`,
"quickstart.Product": `商品管理`,
"quickstart.Product Setting": `产品设置`,
"quickstart.Products Capability": `产品能力`,
"quickstart.Store Configuration": `店铺配置`,
"quickstart.Warehouse": `仓库管理`,
"quickstart.Warehouse Configuration": `仓库配置`,
"referralAFriend": `推荐朋友`,
"referrals.Add reward": `新增奖励`,
"referrals.Minimum order amount": `最低订单金额`,
"referrals.Place an order": `下单`,
"referrals.Referral": `推荐`,
"referrals.Referral reward": `推荐奖励`,
"referrals.Referral rewards": `推荐奖励`,
"referrals.Referral rewards description1": `给与推荐人推荐奖励。`,
"referrals.Referral rewards description2": `一旦被推荐人下单成功推荐人就会获得奖励。`,
"referrals.Referral setting": `推荐设置`,
"referrals.Referral setting description": `推荐相关设置决定您和被推荐人如何获得奖励和其他相关设置。`,
"referrals.Referred friend reward": `被推荐人奖励`,
"referrals.Referred friend reward emptyText": `为推荐的朋友添加奖励。`,
"referrals.Referring customer reward": `推荐人奖励`,
"referrals.Referring customer reward emptyText": `为推荐朋友的消费者添加奖励。`,
"referrals.Registration": `注册`,
"referrals.Reward distribution threshold": `奖励最低门槛：`,
"referrals.Reward distribution threshold:": `奖励发放标准`,
"referrals.delete description": `删除的奖励不可恢复`,
"referrals.delete title": `请问您确定要删除此奖励吗？`,
"reload": `刷新数据`,
"remaining": `个剩余字符`,
"reply.Add New Reply Content": `添加新回复内容`,
"reply.Are you sure you want to": `您确定要`,
"reply.Asset": `资产`,
"reply.Assets Title": `资产名称`,
"reply.Confirm Delete?": `确认删除？`,
"reply.Content Description": `内容描述`,
"reply.Content description": `内容描述`,
"reply.Create Time": `创建时间`,
"reply.Edit Reply Content": `编辑回复内容`,
"reply.Graphic main cover": `图片主封面`,
"reply.Message content": `消息内容`,
"reply.Picture": `图片`,
"reply.Please input description!": `请输入相应描述`,
"reply.Please select Asset!": `请选择资产！`,
"reply.Please select reply type!": `请选择回复类型！`,
"reply.Reply Type": `回复类型`,
"reply.Select Assets": `选择资产`,
"reply.Title": `标题`,
"reply.Video": `视频`,
"reply.Voice": `语音`,
"reply.Wechat Assets ID": `微信资产ID`,
"reply.graphicMessage": `图形消息`,
"reply.pictureMessage": `图片消息`,
"reply.textMessage": `文本消息`,
"reply.this item?": `此项目？`,
"reply.videoMessage": `视频消息`,
"reply.voiceMessage": `语音信息`,
"reportEmbed": `reportEmbed`,
"resetPassword.phoneNumber": `电话号码`,
"resetPassword.resetPassword": `重置密码`,
"return-order": `退货单`,
"returnOrder.description": `针对退货订单的不同状态添加和修改整个流程`,
"rewardHistory.action": `操作`,
"rewardHistory.add": `新增`,
"rewardHistory.adjust_points": `调整积分`,
"rewardHistory.adjustment_reason": `调整原因`,
"rewardHistory.adjustment_type": `调整类型`,
"rewardHistory.consumer_email": `消费者邮件`,
"rewardHistory.consumer_name": `消费者名称`,
"rewardHistory.date": `日期`,
"rewardHistory.minus": `减`,
"rewardHistory.points": `积分`,
"rewardHistory.points_value": `积分值`,
"rewardHistory.related_transaction": `关联交易`,
"rewardSettings": `奖励设置`,
"rewardSettings.add_new_reward": `添加新奖励`,
"rewardSettings.add_perk": `新增其他奖励`,
"rewardSettings.add_product": `新增产品`,
"rewardSettings.add_reward": `新增奖励`,
"rewardSettings.amount": `金额`,
"rewardSettings.choose_a_product": `选择产品`,
"rewardSettings.create_perk": `创建其他奖励`,
"rewardSettings.create_perk_description": `其他奖励描述`,
"rewardSettings.create_perk_icon": `Perk图标`,
"rewardSettings.create_perk_name": `其他奖励名称`,
"rewardSettings.create_perk_status": `状态`,
"rewardSettings.create_perk_status_active": `激活`,
"rewardSettings.create_perk_status_inactive": `非活动`,
"rewardSettings.description": `描述`,
"rewardSettings.display_name": `显示名称`,
"rewardSettings.edit_perk": `编辑其他奖励`,
"rewardSettings.free_product": `免费产品`,
"rewardSettings.free_product_redeem_times": `免费产品兑换次数`,
"rewardSettings.free_shipping": `免运费`,
"rewardSettings.general": `概述`,
"rewardSettings.info1": `1.会员等级与会员福利有关。会员可以享受与等级对应的福利。`,
"rewardSettings.info2": `2.推荐奖励与奖励相关。`,
"rewardSettings.order_discount": `订单折扣`,
"rewardSettings.perk_name": `其他奖励名称`,
"rewardSettings.perks": `其他奖励`,
"rewardSettings.points": `积分`,
"rewardSettings.points_value": `积分值`,
"rewardSettings.product_name": `产品名称`,
"rewardSettings.product_price": `产品价格`,
"rewardSettings.product_stock": `产品库存`,
"rewardSettings.reward_history_tittle": `奖励详情`,
"rewardSettings.reward_icon": `奖励图标`,
"rewardSettings.reward_name": `奖励名称`,
"rewardSettings.reward_number": `奖励数量`,
"rewardSettings.reward_value": `奖励金额`,
"rewardSettings.rewards": `奖励`,
"rewardSettings.set_shipping_amount": `设置此奖励的最低运费金额`,
"ribbon.delete confirm description": `删除的功能区不可恢复`,
"ribbon.delete confirm text": `请问您是否确认删除此产品卡标签？`,
"route.Content Experience": `内容体验`,
"route.Sales Experience": `销售体验`,
"sales-order": `销售订单`,
"salesOrder.description": `针对销售订单的不同状态添加和修改整个流程`,
"sales_channel.pos": `POS`,
"sales_channel.pos.direct_checkout": `立即下单`,
"sales_channel.pos.language": `语言`,
"search.Add synonyms": `Add synonyms`,
"search.Edit synonyms": `Edit synonyms`,
"search.Missing phrase": `Missing phrase`,
"search.Please input Synonyms or delete this field": `Please input Synonyms or delete this field.`,
"search.Search phrase(When search for)": `搜索短语（搜索时）`,
"search.Synonyms (also search for)": `同义词（也可搜索）`,
"search.Synonyms Are Repeated": `Synonyms Are Repeated`,
"searchPageNamePlaceholder": `请输入页面名称`,
"segment.Add condition": `添加条件`,
"segment.Add condition group": `添加条件组`,
"segment.Create New Segments": `创建人群包`,
"segment.Crowd Estimation": `人数预估`,
"segment.Crowd Number": `人群数量`,
"segment.Events": `人群包`,
"segment.Last Update Time": `上次更新时间`,
"segment.Please Input Segment Label": `请输入人群包名称`,
"segment.Segment Basic Information": `分群包基本信息`,
"segment.Segment Description": `人群包描述`,
"segment.Segment Label": `人群包标签`,
"segment.Segment Rules": `分群包规则`,
"segment.Segments Detail": `人群包详细信息`,
"segment.Segments List": `人群包列表`,
"segment.Segments List.desc": `人群包是具有共同属性、操作或行为的消费者组。请根据您的业务需求定义人群包！`,
"segment.Update Type": `更新类型`,
"segment.Update Type.desc": `人群包每日更新。`,
"select City": `选择城市`,
"select Country": `选择国家`,
"select Currency format": `选择显示格式`,
"select Currency symbol": `选择货币符号`,
"select Date format": `选择日期格式`,
"select Default Weight": `选择默认重量`,
"select Digit grouping": `选择数据分组`,
"select Language": `选择语言`,
"select No of digits after decimal": `选择货币精度`,
"select No. of digits after decimal": `选择货币精度`,
"select Region": `选择地区`,
"select Time Zone": `选择时区`,
"select Time format": `选择时间格式`,
"select Unit System": `选择系统单位`,
"select User Type": `选择用户类型`,
"select your Signed Product Category": `请选择签约产品类目`,
"selected": `已选择 `,
"setting-Org": `组织`,
"setting.General Setting": `基本信息`,
"setting.back": `返回`,
"setting.returnOrderNumber.tips": `退货单号格式已成功更改。`,
"setting.shippingMethod": `发货方式`,
"setting.shippingMethod.add": `新增`,
"setting.shippingMethod.addShippingMethod": `添加运费模板`,
"setting.shippingMethod.shippingMethod": `发货方式`,
"setting.shippingMethod.shippingMethods": `发货方式`,
"setting.shippingMethod.status": `状态`,
"setting.shippingMethod.transitionMethod": `配送方式`,
"setting.shippingMethod.transitionMethods": `配送方式`,
"shipping-method-name": `模板名称`,
"sitemap.description": `网站地图是商店所有页面和内容的结构化列表，旨在帮助搜索引擎和用户导航和理解网站的布局和层次结构。轻松访问和下载网站的结构化内容列表，以优化SEO、简化内容管理和增强数据分析。`,
"sitemap.task_create_success": `正在下载中。请稍候，您可以稍厚在“导出”部分下载。
`,
"smartOperation.Activity Log": `活动日志`,
"smartOperation.Actual Executed Time": `实际执行时间`,
"smartOperation.Actual Population": `实际人数`,
"smartOperation.Add events": `新增事件`,
"smartOperation.Basic Task Information": `基本任务信息`,
"smartOperation.Behaviour": `行为`,
"smartOperation.Conditions": `条件`,
"smartOperation.Create Task": `创建任务`,
"smartOperation.Detail Task": `任务详细信息`,
"smartOperation.Email": `电子邮件`,
"smartOperation.Goal": `目标`,
"smartOperation.Goal Achieved Population": `已实现目标人数`,
"smartOperation.One Time": `一次性`,
"smartOperation.Operation": `操作`,
"smartOperation.Operation Log": `操作日志`,
"smartOperation.Planed Executed Time": `计划执行时间`,
"smartOperation.Planned Executed Time": `计划执行时间`,
"smartOperation.Population": `人数`,
"smartOperation.Process Step Name": `流程步骤名称`,
"smartOperation.Push Channel": `推送通道`,
"smartOperation.Push Type": `推送类型`,
"smartOperation.Repeated Time": `重复时间`,
"smartOperation.SMS": `短信`,
"smartOperation.Save as Draft": `另存为草稿`,
"smartOperation.Start and End Time": `开始和结束时间`,
"smartOperation.Target Population": `目标人数`,
"smartOperation.Target Users": `目标用户`,
"smartOperation.Target Users are selected by segments": `目标用户基于人群包选择`,
"smartOperation.Task Description": `任务描述`,
"smartOperation.Task Label": `任务标签`,
"smartOperation.Task Step Name": `任务步骤名称`,
"smartOperation.Task Type": `任务类型`,
"smartOperation.Template Name": `模板名称`,
"smartOperation.The Task will be executed once": `任务将执行一次`,
"smartOperation.Trigger Events": `触发事件`,
"smartOperation.Triggering Time": `触发时间`,
"smartOperation.Update Result": `更新结果`,
"smartOperation.Update Task": `编辑任务`,
"smartOperation.please enter number": `请输入数字`,
"smartOperation.update": `更新`,
"sourcingRule": `寻源规则`,
"startTime": `开始时间`,
"store Basic information info": `维护和管理商店的基本档案主要包括商店名称、地址、签约产品类目等`,
"subscription": `订阅`,
"subscription.100": `100`,
"subscription.Actions": `操作`,
"subscription.Age": `年龄`,
"subscription.Benefit Type": `奖励类型`,
"subscription.Breed": `品种`,
"subscription.Carrier information": `物流信息`,
"subscription.Completed": `已完成`,
"subscription.Consumer Name": `用户名`,
"subscription.Consumer information": `消费者信息`,
"subscription.Frequency": `频率`,
"subscription.Freshness": `频率`,
"subscription.Inactive": `取消`,
"subscription.Ongoing": `正在进行`,
"subscription.Order ID": `订单号`,
"subscription.Order status": `订单状态`,
"subscription.PauBenefit Namese": `奖励名称`,
"subscription.Pause": `暂停`,
"subscription.Pause Subscription": `暂停订阅`,
"subscription.Paused": `暂停`,
"subscription.Pet Detail": `宠物详情`,
"subscription.Phone Number": `电话号码`,
"subscription.Product": `产品`,
"subscription.Product Name": `产品名称`,
"subscription.Product(s)": `产品`,
"subscription.Quantity": `数量`,
"subscription.Restart": `重启`,
"subscription.Restart Subscription": `重启订阅`,
"subscription.SEQ": `次数`,
"subscription.Shipment date": `发货日期`,
"subscription.Subscription Benefits": `订阅奖励`,
"subscription.Subscription Creation Date": `订阅创建日期`,
"subscription.Subscription Frequency": `订阅频率`,
"subscription.Subscription ID": `订阅号`,
"subscription.Subscription Product": `订阅产品`,
"subscription.Subscription Status": `订阅状态`,
"subscription.Subscription Time": `订阅时间`,
"subscription.Subscription Type": `订阅类型`,
"subscription.Subscription information": `订阅信息`,
"subscription.Subtotal": `小计`,
"subscription.To start": `即将开始`,
"subscription.Unit price": `单价`,
"subscription.Variation": `规格`,
"subscription.View Order Detail": `查看订单详情`,
"subscription.addresses": `地址`,
"subscription.auto_ship": `订阅`,
"subscription.half_year": `半年`,
"subscription.info": `订阅信息`,
"subscription.log.create_comment": `添加备注`,
"subscription.log.delete_comment": `删除备注`,
"subscription.log.modify_address": `修改交货地址`,
"subscription.log.modify_comment": `修改备注`,
"subscription.log.modify_ship_date": `修改发货日期`,
"subscription.normal": `普通`,
"subscription.quarter": `季度`,
"subscription.setting.Customize subscription number format and this change will apply to future subscriptions": `自定义订阅编号格式，此更改将应用于未来的订阅`,
"subscription.setting.Frequency Unit": `频率单位`,
"subscription.setting.Frequency name": `频率`,
"subscription.setting.Frequency value": `频率值`,
"subscription.setting.Status": `状态`,
"subscription.setting.Subscription Number Format": `订阅编号格式`,
"subscription.shipping address": `收货地址`,
"subscription.sku id": `SKU`,
"subscription.status.completed": `订阅已完成`,
"subscription.status.created": `订阅已创建`,
"subscription.status.pause": `订阅已暂停`,
"subscription.status.refill": `已创建下一次订单`,
"subscription.status.resume": `订阅已重启`,
"subscription.year": `年`,
"switchLanguageTip": `您之前没有在所选语言下创建过此页面我们将在默认语言下保存修改后的页面并为您创建所需语言的新页面。`,
"tag.Actions": `操作`,
"tag.Add New Pet Owner": `新增消费者`,
"tag.Add New Tag": `添加新标签`,
"tag.Display in Shop": `显示在销售店铺`,
"tag.Edit tag": `编辑标签`,
"tag.Enter a tagging name": `请输入标签名称`,
"tag.Missing Display Name": `缺少显示名称`,
"tag.Options": `选项`,
"tag.Pet Owner List": `消费者列表`,
"tag.Pet Owner(s)": `消费者`,
"tag.Phone Number": `电话号码`,
"tag.Price": `价格`,
"tag.Profile Photo": `头像`,
"tag.Select Pet Owner": `选择消费者`,
"tag.Stock": `库存`,
"tag.Tagging Name": `标签名称`,
"tag.Tagging Name:": `标签名称`,
"tag.Tagging Status": `标签状态`,
"tag.WeChat Name": `微信名`,
"tag.delete confirm description": `删除的标签不可恢复。`,
"tag.delete confirm text": `请问您是否确认删除此标签？`,
"tag.tagName": `标签名称`,
"tag.tagNameTip": `请输入标签名称`,
"tagList.Input Tag Description": `请输入标签描述`,
"tagList.Please Input Tag Name": `请输入标签名称`,
"tagList.Please Input segment Name": `请输入分群包名称`,
"tagList.Population": `人数`,
"tagList.Tag Description": `标签描述`,
"tagList.Tag Detail": `标签详情`,
"tagList.Tag History": `标签更新历史`,
"tagList.Tag ID": `标签ID`,
"tagList.Update time": `更新时间`,
"tagsList.Tags Management": `标签管理`,
"taskDetail.basicInfoJobId": `任务ID`,
"taskDetail.basicInfoJobStatus": `任务状态`,
"taskDetail.basicInfoReferenceLabel": `对象名称`,
"taskDetail.basicInfoTaskId": `任务ID`,
"taskDetail.basicInfoTaskStatus": `任务状态`,
"taskDetail.basicInfoUpdateType": `更新类型`,
"taskDetail.basicInfoUsageType": `使用类型`,
"taskDetail.job.title": `任务基本信息`,
"taskDetail.operationLog": `任务操作日志`,
"taskDetail.title": `任务基本信息`,
"taskDetail.updateResult": `更新结果`,
"taskList.All": `全部`,
"taskList.Data Type": `数据类型`,
"taskList.GeneralEventsTitleDescription": `任务列表定期执行更新标签和人群包的所有任务。`,
"taskList.Segment": `人群包`,
"taskList.Select Data Type": `请选择数据类型`,
"taskList.Tag": `标签`,
"taskList.Tag Description": `标签描述`,
"taskList.Usage": `使用次数`,
"taskList.generalEventsTitle": `任务列表`,
"taskList.job.GeneralEventsTitleDescription": `任务列表定期执行更新标签和人群包的所有任务。`,
"taskList.job.Segment": `人群包`,
"taskList.job.Tag": `标签`,
"taskList.job.generalEventsTitle": `任务列表`,
"taskList.job.status": `任务状态`,
"taskList.job.totalEvents": `任务`,
"taskList.lastExcutionTime": `下次执行时间`,
"taskList.lastUpdateTime": `上次更新时间`,
"taskList.options": `操作`,
"taskList.population": `人数`,
"taskList.referenceLabel": `标的名称`,
"taskList.status": `任务状态`,
"taskList.totalEvents": `任务`,
"taskList.updateType": `更新类型`,
"taskList.usageType": `使用类型`,
"taskListExecutionTime": `操作`,
"tax.Calculation url": `计算url`,
"tax.Company code": `公司代码`,
"tax.Edit tax setting": `编辑税设置`,
"tax.Password": `密码`,
"tax.Tax code": `税务代码`,
"tax.User name": `用户名`,
"templateMessage.Add": `新增`,
"templateMessage.Add New Template": `添加新模板`,
"templateMessage.Cancel Reminder": `取消提醒`,
"templateMessage.Description": `描述`,
"templateMessage.Graphical Representation": `图片展示`,
"templateMessage.H5 Jump Path": `H5跳转路径`,
"templateMessage.Industry": `工业`,
"templateMessage.Jump Path": `跳转路径`,
"templateMessage.List Representation": `列表展示`,
"templateMessage.Mini Program AppId": `小程序AppId`,
"templateMessage.Modify": `修改`,
"templateMessage.Please input Description": `请输入描述`,
"templateMessage.Please input H5 jump path": `请输入H5跳转路径`,
"templateMessage.Please input Mini Program Jump Path": `请输小程序跳转路径`,
"templateMessage.Please input Mini Program appid": `请输入小程序appid`,
"templateMessage.Please input Primary Industry": `请输入第一产业`,
"templateMessage.Please input Secondary Industry": `请输入第二产业`,
"templateMessage.Please input Template ID": `请输入模板ID`,
"templateMessage.Please input Template Name": `请输入模板名称`,
"templateMessage.Primary Industry": `第一产业`,
"templateMessage.Scenario": `场景`,
"templateMessage.Secondary Industry": `第二产业`,
"templateMessage.Select Products": `选择产品`,
"templateMessage.Select Scenario": `选择场景`,
"templateMessage.Select Template": `选择模板`,
"templateMessage.Shipped": `已发货`,
"templateMessage.Status": `状态`,
"templateMessage.Synchronous": `同步`,
"templateMessage.Template ID": `模板ID`,
"templateMessage.Template ID:": `模板ID`,
"templateMessage.Template Name": `模板名称`,
"templateMessage.Template Name:": `模板名称`,
"templateMessage.Template Title": `模板标题`,
"templateMessage.Title": `标题`,
"templateMessage.View Industries": `查看行业`,
"templateMessage.View Industry": `查看行业`,
"test": `测试`,
"test11": `test`,
"th": `Th`,
"three": ``,
"tierDowngrade": `等级降级`,
"tierManagement": `等级管理`,
"tierTerm": `等级期限`,
"tierTerm.addRewards": `新增奖励`,
"tierTerm.amountSpent": `花费金额`,
"tierTerm.baseTier": `基础等级`,
"tierTerm.description": `描述`,
"tierTerm.earnedHistoryTitle": `获得历史头衔`,
"tierTerm.entryRetention": `级别保留条件`,
"tierTerm.entryRetentionCheckText": `条目保留与条目阈值不同。`,
"tierTerm.entryRewards": `入门奖励`,
"tierTerm.entryRewardsTip": `添加消费者晋升至此级别的奖励。每个消费者只会享受一次奖励。`,
"tierTerm.entryThreshold": `进入阈值`,
"tierTerm.from": `发件人`,
"tierTerm.general": `概述`,
"tierTerm.name": `名称`,
"tierTerm.previousLowerTier": `前一较低等级`,
"tierTerm.radio1": `日历年 - 级别资格从1月1日开始到12月31日结束`,
"tierTerm.radio2": `滚动年份 – 从客户达到某一级别开始至12个月后过期。`,
"tierTerm.radio3": `生命周期 - 消费者将始终维持其现有等级。`,
"tierTerm.rewards": `奖励`,
"tierTerm.rewardsEmpty": `尚未添加奖励。`,
"tierTerm.spent": `已用`,
"tierTerm.startDate": `资格开始日期`,
"tierTerm.tierIcon": `等级图标`,
"tierTerm.to": `至`,
"time back to you": `节省时间`,
"translation.Add new key": `新增Key`,
"translation.Auto-translate": `自动翻译`,
"translation.DTC Platform": `DTC平台`,
"translation.DTC Platform desc": `DTC平台中使用的所有字段的翻译`,
"translation.Search Key": `搜索`,
"translation.Store Front": `前台店铺`,
"translation.Store Front desc": `前台店铺内使用的所有字段的翻译`,
"translation.Translated": `已翻译`,
"translation.Untranslated": `未翻译`,
"translation.desc": `通过我们高质量的无障碍服务，简化您的翻译流程。轻松管理多种语言的常用密钥，并利用无限制的自动翻译实现更高效的后期编辑工作流程。`,
"translation.key name": `Key名称`,
"translation.title": `提高翻译效率`,
"type is required": `类型为必填项`,
"uom.delete uom": `请问您是否确认要删除该项目？`,
"update successfully": `update successfully`,
"user segments": `人群包`,
"user.subTile": `平台用户管理`,
"valetOrder.openWindow.description": `请在新窗口中进行结帐。如果在新窗口中无法打开，请点击<span style="color:rgb(238, 77, 45);cursor:pointer">此处</span>`,
"valetOrder.successfully placed for user": `{name} 的订单已成功下达`,
"volume (m³)": `体积`,
"voucher Details": `优惠券详情`,
"voucher.Actions": `操作`,
"voucher.Activities": `活动`,
"voucher.Activity name": `活动名称`,
"voucher.Add Products": `新增产品`,
"voucher.All Category": `所有类别`,
"voucher.Applicable Products": `适用产品`,
"voucher.Automatic Offer": `自动适用`,
"voucher.Basic Information": `基本信息`,
"voucher.Brand": `品牌`,
"voucher.By Percentage": `按百分比`,
"voucher.Discount Type | Amount": `折扣类型 | 金额`,
"voucher.Fix Amount": `固定金额`,
"voucher.GSV": `GSV`,
"voucher.GSVTip": `使用优惠券的所有已确认订单的总额。`,
"voucher.Generate Codes": `生成促销代码`,
"voucher.Image must smaller than 1M!": `图像必须小于1M！`,
"voucher.Image size should not exceed 1M.": `图片大小不应超过1M。`,
"voucher.Issuance time": `发行时间`,
"voucher.Minimum Basket Price": `最低客单价`,
"voucher.Note: One consumer can only use once.": `注释：一个消费者只能使用一次。`,
"voucher.Number of codes": `促销代码的数量`,
"voucher.Orders": `订单`,
"voucher.Picture upload failed!": `优惠券图片上传失败！`,
"voucher.Please enter a value between 1 and 99": `请输入介于1和99之间的值`,
"voucher.Price(s)": `价格`,
"voucher.Product Category": `后台类目`,
"voucher.Product Category:": `产品类目：`,
"voucher.Product Voucher": `产品优惠券`,
"voucher.Product(s) Selected": `所选产品`,
"voucher.Products": `产品`,
"voucher.Quantity": `数量`,
"voucher.Recurrence": `循环`,
"voucher.Rule Settings": `规则设置`,
"voucher.SKU": `SKU`,
"voucher.SPU": `SPU`,
"voucher.Select a option and change input text above": `选择一个选项并更改上面的输入文本`,
"voucher.Shop Voucher": `购物券`,
"voucher.Status": `状态`,
"voucher.Stock": `库存`,
"voucher.The price cannot be less than the voucher discount amount.": `价格不得低于优惠券折扣金额。`,
"voucher.The recommended size for images is 100px * 100px.": `建议的图片大小为100px*100px。`,
"voucher.Total number of coupon codes": `优惠券代码的总数`,
"voucher.Total usable voucher for all consumers": `总可用优惠券`,
"voucher.Unlimited": `无限`,
"voucher.Usage Quantity": `使用数量`,
"voucher.Valid Period": `有效期`,
"voucher.Voucher Code": `优惠券代码`,
"voucher.Voucher Description": `优惠券描述`,
"voucher.Voucher Image": `优惠券图片`,
"voucher.Voucher Name": `优惠券名称`,
"voucher.Voucher Type": `优惠券类型`,
"voucher.Voucher Value": `优惠券价值`,
"voucher.end": `结束`,
"voucher.keyMetrics": `关键指标`,
"voucher.list": `凭单列表`,
"voucher.list.Are you sure you want to": `您确定要`,
"voucher.list.Create": `创建`,
"voucher.list.Data from": `数据来源`,
"voucher.list.Expired": `已过期`,
"voucher.list.Ongoing": `正在进行`,
"voucher.list.Status": `状态`,
"voucher.list.UTC+8": `UTC+8`,
"voucher.list.Upcoming": `即将到来`,
"voucher.list.Valid Period": `有效期`,
"voucher.list.listDescription": `在DTC Experience上为您的商店和产品创建和管理您自己的优惠券！`,
"voucher.list.the item?": `项目？`,
"voucher.list.to": `至`,
"voucher.list.usageLimit": `使用次数限制`,
"voucher.order": `订单`,
"voucher.orderTip": `使用优惠券的所有已确认订单的总额。`,
"voucher.orders": `订单`,
"voucher.orders.All": `全部`,
"voucher.orders.All Products": `所有产品`,
"voucher.orders.Applicable Products": `适用产品`,
"voucher.orders.BasicInformation": `基本信息`,
"voucher.orders.Claimed": `声称`,
"voucher.orders.Collapse Voucher Information": `折叠优惠券信息`,
"voucher.orders.DiscountAmount": `折扣金额`,
"voucher.orders.MinimumBasketPrice": `最低客单价`,
"voucher.orders.More Voucher Information": `更多优惠券信息`,
"voucher.orders.NormalSubscription": `普通订阅`,
"voucher.orders.OrderType": `订单类型`,
"voucher.orders.ProductVoucher": `产品优惠券`,
"voucher.orders.Products": `产品`,
"voucher.orders.Quantity": `数量`,
"voucher.orders.Search": `搜索`,
"voucher.orders.ShopVoucher": `购物券`,
"voucher.orders.SingleOrder": `一次性订单`,
"voucher.orders.TotalNumberOfVouchers": `优惠券总数`,
"voucher.orders.Usage": `用法`,
"voucher.orders.Valid Period": `有效期`,
"voucher.orders.Voucher Type": `优惠券类型`,
"voucher.orders.VoucherName": `优惠券名称`,
"voucher.orders.claimed.tip": `已申领的优惠券数量`,
"voucher.orders.usage.tip": `已使用的优惠券数量（不包括已取消和订单）`,
"voucher.petOwners": `消费者`,
"voucher.petOwnersTip": `使用优惠券的消费者总数。`,
"voucher.selectProduct": `选择产品`,
"voucher.spu": `SPU`,
"voucher.spu:": `spu:`,
"voucher.usageRate": `使用率`,
"voucher.usageRateTip": `已确认订单中的优惠券使用总数除以优惠券发放总数。`,
"voucher.usageTip": `已使用的优惠券数量（不包括已取消的订单）`,
"warehouseRuleAdd": `新增`,
"warehouseRuleDetail": `详情`,
"warehouseRuleEdit": `编辑`,
"weight (kg)": `重量`,
"wx.abstract": `摘要`,
"wx.abstract_tip": `可选如果未填写默认情况下将抓取45个单词的第一行`,
"wx.account_disable_tip1": `你确定要禁用公众号吗？如果是微信管理的其他模块（粉丝管理、资产管理、模型管理……）将仅与激活的帐户关联。`,
"wx.account_disable_tip2": `请问您确定要禁用此帐户吗？`,
"wx.account_enable_tip1": `您确定要启用公众号吗？如果是微信管理的其他模块（粉丝管理、资产管理、模型管理……）将仅与激活的帐户关联。`,
"wx.account_enable_tip2": `请问您是否确认要启用此帐户？`,
"wx.account_name": `账户名称`,
"wx.account_principal": `账户本金`,
"wx.account_type": `账户类型`,
"wx.add_account": `新增帐户`,
"wx.add_graphic_message": `添加图形消息`,
"wx.add_new_auto_replay": `添加新的自动回复`,
"wx.add_new_banner": `添加新小程序横幅`,
"wx.add_new_menu": `添加新菜单`,
"wx.add_new_message": `添加新消息`,
"wx.add_new_qr_code": `添加新二维码`,
"wx.add_picture_message": `添加图片消息`,
"wx.add_video": `新增视频`,
"wx.add_video_message": `添加视频消息`,
"wx.add_voice_message": `添加语音消息`,
"wx.all": `全部`,
"wx.anchor_name": `锚点名称`,
"wx.app_id": `App Id`,
"wx.app_secret": `AppSecret`,
"wx.are_you_sure_to_delete_message": `请问您是否确实要删除此邮件`,
"wx.are_you_sure_to_publish": `请问您是否确定要发布项目？`,
"wx.are_you_sure_to_sync": `请问您确认要同步吗？`,
"wx.assets_id": `微信资产ID`,
"wx.asso_account": `关联账户`,
"wx.author": `作者`,
"wx.avatar": `头像`,
"wx.banner_name": `横幅名称`,
"wx.certificate_path": `证书路径`,
"wx.channel": `渠道`,
"wx.channel_qr": `频道二维码值`,
"wx.channel_type": `渠道类型`,
"wx.click_to_jump_link": `订阅者单击此菜单可跳转到以下链接`,
"wx.click_to_jump_miniprogram": `订阅者单击此菜单可跳转到以下小程序`,
"wx.click_to_select": `点击选择`,
"wx.click_type": `点击类型`,
"wx.comment": `备注`,
"wx.confirm_sync": `确认同步`,
"wx.content": `内容`,
"wx.content_desc": `内容描述`,
"wx.cover_preview": `封面预览`,
"wx.create_time": `创建时间`,
"wx.description": `描述`,
"wx.edit_account": `编辑帐户`,
"wx.edit_auto_reply": `编辑自动回复`,
"wx.edit_banner": `编辑小程序横幅`,
"wx.edit_menu": `编辑菜单`,
"wx.exact_matching": `精确匹配`,
"wx.expired": `已过期`,
"wx.fans_detail": `粉丝详情`,
"wx.file": `文件`,
"wx.follow_time": `关注时间`,
"wx.fuzzy_matching": `模糊匹配`,
"wx.gen_menu_data": `生成菜单数据`,
"wx.graphic_main_cover": `图片主封面`,
"wx.graphic_message": `图片消息`,
"wx.graphic_messages": `图形消息`,
"wx.home_page": `主页`,
"wx.is_member": `是否为会员`,
"wx.joint_with_miniprogram": `与小程序联合`,
"wx.keywords": `关键字`,
"wx.large_pic_size": `大尺寸图片：900*500（单图片或多图片）`,
"wx.link": `链接`,
"wx.link_for_article": `阅读原创文章”的链接`,
"wx.livestream_id": `直播ID`,
"wx.livestream_name": `直播名称`,
"wx.main_cover": `主封面`,
"wx.management_mode": `管理模式`,
"wx.match_type": `匹配类型`,
"wx.menu_item_delete_tip": `请问您确定要删除它吗？`,
"wx.menu_name": `菜单名称`,
"wx.menu_name_length": `建议不超过4个中文字符或8个英文字符`,
"wx.menu_valid_fail": `菜单项验证失败`,
"wx.merchant_key": `商户密钥`,
"wx.merchat_id": `商户ID`,
"wx.message_url": `消息加密Url`,
"wx.mini_program": `小程序`,
"wx.mini_program_id": `小程序ID`,
"wx.mini_program_path": `小程序路径`,
"wx.miniprogram_should_complete_setting": `小程序菜单应完成设置！`,
"wx.move_down": `下移`,
"wx.move_left": `向左移动`,
"wx.move_right": `向右移动`,
"wx.move_up": `上移`,
"wx.new_graphic": `新图形`,
"wx.no_operation": `无操作`,
"wx.official_account_type": `官方账户类型`,
"wx.ongoing": `正在进行`,
"wx.open_mp_page": `打开小程序页面`,
"wx.open_other_mp_page": `打开其他MP页面`,
"wx.open_web_page": `打开网页`,
"wx.openid": `OPENID`,
"wx.original_id": `原始ID`,
"wx.page": `页面`,
"wx.page_path": `页面路径`,
"wx.page_url": `页面URL`,
"wx.period": `期间`,
"wx.pic_location": `照片位置`,
"wx.picture": `图片`,
"wx.picture_assets": `图片资产`,
"wx.picture_message": `图片消息`,
"wx.pictures": `图片`,
"wx.please_input_account_name": `请输入帐户名称`,
"wx.please_input_account_principal": `请输入账户主体`,
"wx.please_input_app_secret": `请输入AppSecret`,
"wx.please_input_appid": `请输入AppID`,
"wx.please_input_keywords": `请输入关键字`,
"wx.please_input_message_url": `请输入消息加密Url`,
"wx.please_input_original_id": `请输入原始ID`,
"wx.please_input_token": `请输入令牌`,
"wx.please_select_account_type": `请选择帐户类型！`,
"wx.please_select_mode": `请选择管理模式！`,
"wx.please_select_official_account_type": `请选择官方账户类型！`,
"wx.please_select_reply_content": `请选择回复内容！`,
"wx.pls_add_menu": `请添加菜单`,
"wx.pls_add_menu_name": `请添加菜单名`,
"wx.pls_input_content": `请输入相应内容`,
"wx.pls_input_desc": `请输入相应描述`,
"wx.pls_input_title": `请输入标题`,
"wx.pls_input_valid_url": `请输入有效的URL`,
"wx.pls_upload_asso_assets": `请上传相关信息！`,
"wx.pls_upload_file": `请上传文件`,
"wx.pls_upload_thumb": `请上传缩略图！`,
"wx.publish": `发布`,
"wx.publish_item": `发布项目`,
"wx.push_server": `推送服务器`,
"wx.qr_code_color": `二维码颜色`,
"wx.qr_code_detail": `二维码详情`,
"wx.qr_code_key_value": `二维码键值`,
"wx.qr_code_path": `二维码路径`,
"wx.qr_code_size": `二维码大小`,
"wx.qr_code_size_recom": `最小280最大1280`,
"wx.qr_code_type": `二维码类型`,
"wx.qr_code_value": `二维码值`,
"wx.redirect_should_contain_url": `Web重定向菜单应重定向到有效的url！`,
"wx.reply_desc": `回复描述`,
"wx.reply_type": `回复类型`,
"wx.response_desc": `响应描述`,
"wx.response_should_choose_asset": `响应消息菜单应选择相应资产！`,
"wx.response_type": `响应类型`,
"wx.save_and_publish": `保存并发布`,
"wx.save_and_sync": `保存并同步`,
"wx.scenario": `场景`,
"wx.scenario_type": `场景类型`,
"wx.select_exist_graphic": `选择现有图形`,
"wx.select_file": `选择文件`,
"wx.select_from_assets": `从资产中选择`,
"wx.select_reply_content": `请选择回复内容`,
"wx.send_response_message": `发送响应消息`,
"wx.share": `共享`,
"wx.shop_page": `商店页面`,
"wx.single_mess_del_tip": `无法删除单个邮件！`,
"wx.small_pic_size": `小图：200*200（多张图片第一张图文下方）`,
"wx.sort": `排序`,
"wx.suggest_size": `推荐尺寸`,
"wx.sync_all": `全部同步`,
"wx.sync_fans_all": `同步所有粉丝信息`,
"wx.sync_fans_all_con": `请问您确定要同步吗？粉丝数量太多请稍候`,
"wx.sync_fans_openid": `同步所有Openid`,
"wx.sync_fans_tip": `正在同步粉丝信息。一旦完成信息将自动更新。`,
"wx.sync_live_tip": `请选择要同步的实时流`,
"wx.sync_livestream": `同步直播设置`,
"wx.sync_partial": `部分同步`,
"wx.sync_partial_tip": `请选择至少一项`,
"wx.sync_wechat_assets": `同步微信资产`,
"wx.title": `标题`,
"wx.token": `令牌`,
"wx.trans_background": `透明背景`,
"wx.unionid": `UNIONID`,
"wx.upcoming": `即将到来`,
"wx.update_time": `更新时间`,
"wx.upload": `上传`,
"wx.url": `URL`,
"wx.video": `视频`,
"wx.video_assets": `视频资产`,
"wx.video_message": `视频消息`,
"wx.videos": `视频`,
"wx.view_details": `查看详细信息`,
"wx.view_image": `查看图像`,
"wx.view_qr_code": `查看二维码`,
"wx.view_video": `查看视频`,
"wx.voice": `语音`,
"wx.voice_assets": `语音资产`,
"wx.voice_message": `语音信息`,
"wx.voices": `声音`,
"wx.web_redirection": `Web重定向`,
"wx.wechat_account": `微信账号`,
"wx.wechat_assets_link": `微信资产链接`,
"wx.wechat_name": `微信名`,
"yourTiers": `您的等级`,
"默认重量": `默认重量`,
 }
