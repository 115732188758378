// 引入icon样式文件
// import "../assets/css/iconfont/iconfont"
import partnerSvg from '@/assets/images/partner.svg'

interface MenuObject {
  name: string
  value: string
  url: string
  key: string // auth permisstion key for items
  icon?: any
  activekey: string // active key for item
  langKey: string
  children?: MenuObject[]
}

export const menus: MenuObject[] = [
  {
    name: 'Quick Start',
    value: 'quickStart',
    url: '/quick-start',
    key: 'quickStart',
    activekey: 'quickStart',
    icon: <span className='icon iconfont text-[18px] icon-quickstarts' />,
    langKey: 'menu.quickStart',
  },
  {
    name: 'Digital Data Assist',
    value: 'digitalDataAssist',
    url: '/digital-data-assist/ai-conversation',
    key: 'digitalDataAssist',
    activekey: 'digital-data-assist',
    icon: <span className='icon iconfont text-[18px] icon-datamanagement' />,
    langKey: 'Digital Data Assist',
    children: [{
      name: 'CRM AI Conversation',
      value: 'crmAiConversation',
      url: '/digital-data-assist/ai-conversation',
      key: 'digitalDataAssist.crmAiConversation',
      activekey: 'ai-conversation',
      langKey: 'CRM AI Conversation',
    }]
  },
  {
    name: 'Q&A Chatbot',
    value: 'qaChatbot',
    url: '/qaChatbot',
    key: 'qaChatbot',
    activekey: 'qa-chatbot',
    icon: <span className='icon iconfont text-[18px] icon-tag' />,
    langKey: 'Q&A Chatbot',
  },
  {
    name: 'Configuration',
    value: 'configuration',
    url: '/configuration',
    key: 'configuration',
    activekey: 'configuration',
    icon: <span className='icon iconfont text-[18px] icon-a-listview' />,
    langKey: 'Configuration',
  }

  // {
  //   name: 'Data Management',
  //   value: 'dataManagement',
  //   url: '',
  //   key: 'dataManagement',
  //   activekey: 'data-management',
  //   icon: <span className='icon iconfont text-[18px] icon-datamanagement' />,
  //   langKey: 'menu.dataManagement',
  //   children: [{
  //     name: 'Meta Data',
  //     value: 'metaData',
  //     url: '',
  //     key: 'dataManagement.metaData',
  //     activekey: 'meta-data',
  //     langKey: 'menu.metaData',
  //     children: [{
  //       name: 'General Events',
  //       value: 'generalEvents',
  //       url: '/data-management/meta-data/general-events',
  //       activekey: 'general-events',
  //       key: 'dataManagement.events',
  //       langKey: 'menu.events',
  //     }, {
  //       name: 'Event attribute',
  //       value: 'eventAttribute',
  //       url: '/data-management/meta-data/event-attribute',
  //       activekey: 'event-attribute',
  //       key: 'dataManagement.eventattributes',
  //       langKey: 'menu.eventAttributes',
  //     }, {
  //       name: 'Principal Attribute',
  //       value: 'principalAttribute',
  //       url: '/data-management/meta-data/principal-attribute',
  //       activekey: 'principal-attribute',
  //       key: 'dataManagement.principalattributes',
  //       langKey: 'menu.principalAttributes',
  //     }, {
  //       name: 'Transaction Attribute',
  //       value: 'transactionAttribute',
  //       activekey: 'transaction-attribute',
  //       url: '/data-management/meta-data/transaction-attribute',
  //       key: 'dataManagement.transactionAttribute',
  //       langKey: 'menu.transactionAttributes',
  //     }]
  //   }, {
  //     name: 'Data Ingestion',
  //     value: 'dataIngestion',
  //     url: '/data-management/data-ingestion',
  //     activekey: 'data-ingestion',
  //     key: 'dataManagement.dataIngestion',
  //     langKey: 'menu.dataIngestion',
  //   }, {
  //     name: 'ID Mapping',
  //     value: 'idMapping',
  //     url: '/data-management/id-mapping',
  //     activekey: 'id-mapping',
  //     key: 'dataManagement.idMapping',
  //     langKey: 'menu.idMapping',
  //   }]
  // },
  // {
  //   name: 'Insights',
  //   value: 'insights',
  //   url: '',
  //   key: 'insights',
  //   icon: <span className='icon iconfont text-[18px] icon-insight' />,
  //   activekey: 'insights',
  //   langKey: 'menu.insights',
  //   children: [{
  //     name: 'Consumer Insights',
  //     value: 'consumerInsights',
  //     url: '/insights/consumer-insights-list',
  //     activekey: 'consumer-insights-list',
  //     key: 'insights.insights',
  //     langKey: 'menu.consumerInsights',
  //   }]
  // },
  // {
  //   name: 'Tag Management',
  //   value: 'tagManagement',
  //   url: '',
  //   key: 'tagManagement',
  //   icon: <span className='icon iconfont text-[18px]  icon-tag' />,
  //   activekey: 'tag-management',
  //   langKey: 'menu.tagManagement',
  //   children: [
  //     {
  //       name: 'Tags List',
  //       value: 'tagsList',
  //       url: '/tag-management/list',
  //       key: 'tagManagement.tagsList',
  //       activekey: 'list',
  //       langKey: 'menu.tagsList',
  //     },
  //     {
  //       name: 'Add New Tags',
  //       value: 'addNewTags',
  //       activekey: 'add',
  //       url: '/tag-management/add',
  //       key: 'tagManagement.addNewTags',
  //       langKey: 'menu.addNewTags',
  //     },
  //   ]
  // },
  // {
  //   name: 'Segments Management',
  //   value: 'segmentsManagement',
  //   url: '',
  //   key: 'segmentsManagement',
  //   icon: <span className='icon iconfont text-[18px] icon-segment' />,
  //   activekey: 'segments',
  //   langKey: 'menu.segmentsManagement',
  //   children: [
  //     {
  //       name: 'Segments List',
  //       value: 'segmentsList',
  //       url: '/segments/segments-list',
  //       key: 'segmentsManagement.segmentsList',
  //       activekey: 'segments-list',
  //       langKey: 'menu.segmentsList',
  //     },
  //     {
  //       name: 'Add New Segments',
  //       value: 'addNewSegments',
  //       url: '/segments/segments-list/segments-add',
  //       activekey: 'segments-add',
  //       key: '',
  //       langKey: 'menu.addNewSegments',
  //     }
  //   ]
  // },
  // {
  //   name: 'Task Center',
  //   value: 'taskCenter',
  //   url: '',
  //   key: 'taskCenter',
  //   icon: <span className='icon iconfont text-[18px] icon-a-quicktask' />,
  //   langKey: 'menu.jobCenter',
  //   activekey: 'task-center',
  //   children: [
  //     {
  //       name: 'Task List',
  //       value: 'taskList',
  //       url: '/task-center/task-list',
  //       activekey: 'task-list',
  //       key: 'taksCenter.taskList',
  //       langKey: 'menu.JobList',
  //     }
  //   ]
  // }
]

// export const initActive = (pathname: string) => {
//   let selectedKeys: string[] = []
//   menus.forEach((menu) => {
//     menu.children.forEach((subMenu) => {
//       if (subMenu.url === pathname) {
//         selectedKeys = [menu.key, subMenu.key]
//       }
//     })
//   })
//   return selectedKeys
// }
