import partner from '../../../assets/icon/icons8-partner.json'
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react'
import Lottie, { useLottie, useLottieInteractivity } from "lottie-react";
interface MyLottieProps {
  trigger?: 'hover',
  animationData: any,
  loop?: boolean,
  autoplay?: boolean,
  isActive?: boolean,
  style?: any,
  parentElement?: string
}
const MyLottie = ({ trigger, animationData, loop = true, autoplay, style, parentElement, isActive }: MyLottieProps) => {
  const lRef = useRef(null)
  const { pathname } = useLocation();

  const parmentRef = useRef(null)
  const [active, setactive] = useState(autoplay || isActive)
  const onEnter = () => {
    lRef?.current?.play?.()
  }
  const onLeave = () => {
    lRef?.current?.stop?.()
  }

  const addColor = (obj: string, oldColor: any, target: any) => {
    return JSON.parse(JSON.stringify(obj).replaceAll(oldColor, target))
  }

  useEffect(() => {
    if (parentElement) {
      const parentements = parmentRef.current?.closest(`.${parentElement}`)

      parentements.addEventListener('mouseover', () => {
        setactive(true)
      })
      parentements.addEventListener('mouseleave', () => {
        setactive(false)
      })
    }
  }, [parmentRef])

  useEffect(() => {

    if (active || isActive) {
      lRef?.current?.play?.()
    } else {
      lRef?.current?.stop?.()
    }
  }, [active, isActive])

  useEffect(() => {
    if (active || isActive) {
      // 路径变更后可能没有立马渲染dom，加个延时
      setTimeout(() => {
        lRef?.current?.play?.()
      }, 500)
    } else {
      lRef?.current?.stop?.()
    }
  }, [pathname])

  return <div ref={parmentRef}>
    <Lottie
      lottieRef={lRef}
      onMouseEnter={trigger === 'hover' ? onEnter : () => { }}
      style={style}
      onMouseLeave={trigger === 'hover' ? onLeave : () => { }}
      autoplay={autoplay}
      animationData={active || isActive ? addColor(animationData, [0, 0, 0, 1], [238 / 255, 77 / 255, 45 / 255, 1]) : animationData}
      loop={loop}
    />
  </div>
}


export default MyLottie

