/*
 * @Author: yuszhou
 * @Date: 2023-03-02 11:10:08
 * @LastEditTime: 2023-03-23 22:16:06
 * @LastEditors: yuszhou
 * @Description: 
 * @FilePath: \rc_china_storeportal_cdp\src\store\lang.store.ts
 */
import { atom } from 'jotai'
import { TLangKey } from '@/locale'
import { getDataFormSeamCookie, getFormLocalstorage } from '@/utils/utils'
let defaultLocale: any = ''
try {
    defaultLocale = getDataFormSeamCookie("locale") as TLangKey;
} catch (error) {
    defaultLocale = null
}

export const langAtom = atom<TLangKey>(defaultLocale ?? 'en_US')
