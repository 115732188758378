import { Menu } from "antd";
import intl from 'react-intl-universal';
import { Link, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from 'react'
import { session } from '@/utils/global'
import "./index.less"
import { getFormLocalstorage, treeToArr } from "@/utils/utils";
import { SiderContext } from "@/contexts/SiderContext";
import useRefreshTime from "@/contexts/intl";
import MyLottie from "../MyLottie"

const { SubMenu } = Menu;

const findOpenKeysAndSelectedKeysByPathname = (menuList: any[], pathname: string, isExpand?: boolean) => {
  const list = treeToArr(menuList)
  let openKeys: any[] = [], selectedKeys: any[] = [];

  list.map((el: any) => {
    if (isExpand) {
      openKeys.push(...el.slice(0, el.length - 1).map(v => v.activekey))
    }
    if (pathname.indexOf(el[el.length - 1].url) > -1 || el[el.length - 1].url == pathname) {
      if (!isExpand) {
        openKeys = el.slice(0, el.length - 1).map(v => v.activekey)
      }
      const current = el[el.length - 1]
      selectedKeys.push(current.activekey);
    }
  })
  if (pathname === "/dashboard" || pathname === "/setting-quick-start" || pathname === "/") {
    selectedKeys = [menuList[0].activekey]
  }
  return { openKeys, selectedKeys };
}

const Menus = (props: { menus: any; }) => {
  const { menus } = props
  const { pathname } = useLocation();
  const [selectedKeys, setSelectKeys] = useState<any[]>([])
  const { collapsed } = useContext(SiderContext)
  const [openKeys, setOpenKeys] = useState<any[]>([])
  const [refreshTime] = useRefreshTime()
  const [keyPathArr, setkeyPathArr] = useState([])

  useEffect(() => {
    let { openKeys, selectedKeys } = findOpenKeysAndSelectedKeysByPathname(menus, pathname);
    setkeyPathArr(...openKeys, ...selectedKeys)
    setSelectKeys(selectedKeys || []);
    setOpenKeys((collapsed ? [] : openKeys) || []);
  }, [pathname])

  const onOpenChange = (opens: string[]) => {
    setOpenKeys(opens);
    session.set('openMenuKeys', opens);
  }

  const onSelect = ({ item, key, keyPath, selectedKeys, domEvent, activekey }: any) => {
    setSelectKeys([activekey]);
    setkeyPathArr(keyPath)
    session.set('selectedMenuKeys', [activekey]);
  }
  const MenusDom = (menus: any, className?: string) => {
    const permitionsList = getFormLocalstorage('scope') ?? JSON.stringify([])
    const MapsPermitioins = JSON.parse(permitionsList) ?? []
    return [...(process.env.NODE_ENV == 'production' ? menus.filter((res: any) => {
      if (!res.key) return true
      return MapsPermitioins.includes(res.key)
    }) : menus)].map(({ key, icon, lottieIcon, name, url, langKey, children, activekey }) => (
      <>
        {(!children || children.length === 0) ? <Menu.Item
          key={activekey}
          icon={lottieIcon ? <span className="first">
            <Link to={url}>
              <MyLottie isActive={keyPathArr?.includes(activekey)} parentElement='ant-menu-item' animationData={lottieIcon}
                style={{
                  height: 17,
                  width: 17,
                }}
                trigger='hover' />
            </Link>

          </span> : icon}
          style={{ paddingLeft: 16, backgroundColor: '#fff' }}
        >
          <Link to={url}>
            {/* {lottieIcon&&<MyLottie animationData={lottieIcon} trigger='hover'/>} */}
            {intl.get(langKey)}</Link>
        </Menu.Item> :
          <SubMenu key={activekey} icon={lottieIcon ? <span className={`${keyPathArr?.includes(activekey) ? 'aaa' : 'bbb'} ${JSON.stringify(keyPathArr)} ${activekey}`}><MyLottie
            isActive={keyPathArr?.includes(activekey)}
            parentElement="ant-menu-submenu-title" animationData={lottieIcon}
            style={{
              height: 17,
              width: 17,
            }}
            trigger='hover' /></span> : icon} title={intl.get(langKey)} className={className}>
            {[...(process.env.NODE_ENV == 'production' ? children.filter((res: any) => {
              if (!res.key) return true
              return MapsPermitioins.includes(res.key)
            }) : children)].map((subMenu: any) => (subMenu?.children?.length) ? MenusDom([subMenu], 'second-menu') :
              <Menu.Item key={subMenu.activekey}>
                <Link key={subMenu.activekey} to={subMenu.url}>
                  {intl.get(subMenu.langKey)}
                </Link>
              </Menu.Item>
            )}
          </SubMenu>
        }
      </>
    ))
  }
  return (
    <Menu
      selectedKeys={selectedKeys}
      openKeys={openKeys}
      // openKeys={collapsed ? [] : openKeys}
      inlineIndent={23}
      mode="inline"
      style={{ fontWeight: 500 }}
      onOpenChange={onOpenChange}
      onSelect={onSelect}
      key={refreshTime}
    >
      {/* <Menu.Item key="dashboard" icon={<span className='icon iconfont text-xl icon-a-bianzu33' />}
        style={{ paddingLeft: 20, backgroundColor: '#fff' }}>
        <Link to="/dashboard" style={{ fontSize: "13px" }}>{intl.get('menu.dashboard')}</Link>
      </Menu.Item> */}
      {MenusDom(menus)}
      {/* {menus.map(({ key, icon, name, url, langKey, children }) => (
        <>
          {!children || children.length === 0 ? <Menu.Item
            key={key}
            icon={icon}
            style={{ paddingLeft: 20, backgroundColor: '#fff' }}
          >
            <Link to={url} style={{ fontSize: "13px" }}>{intl.get(langKey)}</Link>
          </Menu.Item> : <SubMenu key={key} icon={icon} title={intl.get(langKey)} >
            {children?.map((subMenu) => (
              <Menu.Item key={subMenu.key} >
                <Link key={subMenu.key} to={subMenu.url} style={{ fontSize: "13px" }}>
                  {intl.get(subMenu.langKey)}
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>}
        </>
      ))} */}
    </Menu>
  );
};
export default Menus;
