/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-01-17 11:05:26
 * @LastEditors: yuszhou@deloitte.com yuszhou@deloitte.com.cn
 * @LastEditTime: 2023-03-21 18:26:03
 * @FilePath: \rc_china_storeportal\src\routers\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import AuthLoading from '@/views/authLoading'
import { lazy } from 'react' // 路由懒加载
const Layout = lazy(() => import('../common/components/Layout'))
const Dashboard = lazy(() => import('@/views/dashboard'))
const Login = lazy(() => import('../views/login'))
const LoginStore = lazy(() => import('../views/loginStore'))
const SelectStore = lazy(() => import('@/common/components/selectStore'))

const LoginBrand = lazy(() => import('../views/loginBrand'))
const ResetPassword = lazy(() => import('../views/resetPassword'))
const Register = lazy(() => import('../views/register'))
const AiConversation = lazy(() => import('@/views/aiConversation'))
const QaChatbot = lazy(() => import('@/views/qaChatbot'))
const Configuration = lazy(() => import('@/views/configuration'))
export interface RouteObject {
  caseSensitive?: boolean
  children?: RouteObject[]
  element?: React.ReactNode
  index?: boolean
  path?: string
  breadcrumbName?: string
  Navigate?: string
  key?: string
}

let routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: 'quick-start', element: <Dashboard />, key: 'quick-start' },

      // {
      //   path: 'reportEmbed',
      //   breadcrumbName: 'reportEmbed',
      //   element: <Dashboard />,
      //   key: 'report_embed'
      // },
      {

        path: 'digital-data-assist',
        breadcrumbName: 'Digital Data Assist',
        children: [
          {
            index: true,
            element: <AiConversation />,
          }, {
            path: 'ai-conversation',
            breadcrumbName: 'CRM AI Conversation',
            children: [
              {
                index: true,
                element: <AiConversation />
              },
            ]
          },]
      },
      {
        path: 'qaChatbot',
        breadcrumbName: 'Q&A Chatbot',
        element: <QaChatbot />,
      }, {
        path: 'configuration',
        breadcrumbName: 'Configuration',
        element: <Configuration />,
      }
    ],
  },
  { path: '/authloading', element: <AuthLoading />, key: 'authLoading' },
  { path: '/login', element: <Login />, key: 'login' },
  { path: '/store/select', element: <SelectStore />, key: 'selectStore' },
  { path: '/login/store', element: <LoginStore />, key: 'storLogin' },
  { path: '/login/brand', element: <LoginBrand />, key: 'branLogin' },
  { path: '/resetPassword', element: <ResetPassword />, key: 'resetPassword' },
  { path: '/register', element: <Register />, key: 'register' },
  { path: '*', element: <Login />, key: 'N' },
]

export default routes
