import gbImg from '@/assets/images/country/gb.png'
import dsImg from '@/assets/images/country/ds.png'
import nlImg from '@/assets/images/country/nl.png'
import blImg from '@/assets/images/country/bl.png'
import alImg from '@/assets/images/country/al.png'
import usImg from '@/assets/images/country/us.png'
import zh from '@/assets/images/country/chinese.png'
export type TLangItem = {
  key: string
  desc: string
  img: string
  active?: boolean
  langKey: string
}

export const languages: TLangItem[] = [
  {
    key: 'en_US',
    desc: 'United States',
    img: usImg,
    langKey: 'US/Eastern',
  },
  {
    key: 'zh_CN',
    desc: 'Chinese',
    img: zh,
    langKey: 'Chinese',
  },
  // {
  //   key: 'ds',
  //   desc: 'Deutschland',
  //   img: dsImg,
  //   langKey: 'Europe/Istanbul',
  // },
  // {
  //   key: 'nl-en',
  //   desc: 'Netherlands(EN)',
  //   img: nlImg,
  //   langKey: 'Europe/Nicosia',
  // },
  // {
  //   key: 'nl-nl',
  //   desc: 'Netherlands(NL)',
  //   img: nlImg,
  //   langKey: 'Europe/Oslo',
  // },
  // {
  //   key: 'bl-en',
  //   desc: 'Belgique(EN)',
  //   img: blImg,
  //   langKey: 'Europe/Paris',
  // },
  // {
  //   key: 'bl-nl',
  //   desc: 'Belgique(NL)',
  //   img: blImg,
  //   active: true,
  //   langKey: 'Europe/Rome',
  // },
  // {
  //   key: 'al',
  //   desc: 'Australia',
  //   img: alImg,
  //   langKey: 'Australia/North',
  // },
  // {
  //   key: 'us',
  //   desc: 'United States',
  //   img: usImg,
  //   langKey: 'US/Eastern',
  // },
]
