import React, { createContext, useState, useEffect } from 'react'
import { getDataFormSeamCookie } from '@/utils/utils'
export const SiderContext = createContext()

const SiderContextProvider = (props) => {
  // 默认收起来
  const sider_collapsed = sessionStorage.getItem('sider_collapsed')
  const initCollapsed = !sider_collapsed || sider_collapsed === 'true'
  const [currentWidth, setCurrentWidth] = useState(initCollapsed ? 60 : 200)
  const [collapsed, setCollapsed] = useState(initCollapsed)

  useEffect(() => {
    setCurrentWidth(collapsed ? 60 : 200)
    sessionStorage.setItem('sider_collapsed', String(collapsed))
  }, [collapsed])
  return (
    <SiderContext.Provider value={{ currentWidth, setCurrentWidth, collapsed, setCollapsed }}>
      {props.children}
    </SiderContext.Provider>
  )
}

export default SiderContextProvider
