import { LabelOptionProps, PageParamsProps } from "@/framework/types/common"
import { Modal } from "antd"
import { ModalFuncProps } from "antd/es/modal"
import { ReactNode } from "react"
import moment from "@/utils/moment"
import intl from 'react-intl-universal'
import axios from "axios"
import locals, { TLangKey } from '../locale'
import Cookies from "js-cookie"

export const getCurrencyCode = (): any => {
  const storeConfig = JSON.parse((getDataFormSeamCookie('rc_sc_store_config') || '{}'));
  return storeConfig?.currency ?? '￥';
}

export const getFixnumber = (): any => {
  const storeConfig = JSON.parse((getDataFormSeamCookie('rc_sc_store_config') || '{}'));
  return storeConfig?.currencyAfterDecimal ?? '0';
}

export const currencyFormat = (unit: string, str: any, fixnumber: string): string => {
  const storeConfig = JSON.parse((getDataFormSeamCookie('rc_sc_store_config') || '{}'));
  const currentFormat = storeConfig?.currencyFormat
  const removeStr = currentFormat.replace('{symbol}', '')
  let returnStr = ''
  switch (removeStr) {
    case '12345.00':
      returnStr = (Number(str) || 0).toFixed(Number(fixnumber))
      break
    case '12345,00':
      returnStr = (Number(str) || 0).toFixed(Number(fixnumber)).replace('.', ',')
      break
    case '12,345.00':
      returnStr = (Number(str) || 0).toFixed(Number(fixnumber)).split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + (Number(str) || 0).toFixed(Number(fixnumber)).split('.')[1]
      break
    case '12.345,00':
      returnStr = (Number(str) || 0).toFixed(Number(fixnumber)).split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ',' + (Number(str) || 0).toFixed(Number(fixnumber)).split('.')[1]
      break
    default:
      returnStr = (Number(str) || 0).toFixed(Number(fixnumber))
  }
  return currentFormat.endsWith('{symbol}') ? (returnStr + unit) : (unit + returnStr)
}

export const formatMoney = (price: number) => {
  return currencyFormat(getCurrencyCode(), price, getFixnumber())
}

export const handleValueEnum = (list: LabelOptionProps[]) => {
  let newEnum: { [x: string]: ReactNode } = {}
  list.forEach((item: LabelOptionProps) => {
    newEnum[item.value] = { text: item.label }
  })
  console.log(newEnum)
  return newEnum
}

/**
 * 处理分页组件数据，达到前端分页的目的
 * @param pageParams
 */
export const handlePageParams = (pageParams: PageParamsProps) => {
  const { currentPage, pageSize } = pageParams
  return {
    offset: currentPage * pageSize - pageSize,
    limit: pageSize,
  }
}

export const handleReturnTime = (time: any, showTime: boolean = true) => {
  // const timeZone = getDataFormSeamCookie('dtc_store_time_zone')?.substring(3) || ''
  // (UTC+00:00) Greenwich Standard Time
  if (time !== null && time !== undefined && time !== '') {
    return moment(time).display()
  } else {
    return ''
  }
}

export const handleObjDataForEdit = (before: any, after: any, diffObj: any) => {
  let keysOne = Object.keys(before) // 获取对象1所有键数组
  for (let key of keysOne) {
    if (typeof before[key] === 'object' && before[key] !== null) {  // 如果是对象，则再递归对比，如果递归返回false，则直接方法也直接返回false
      if (Object.prototype.toString.call(before[key]) === '[object Object]') {
        handleObjDataForEdit(before[key], after[key], diffObj)
        // if (!handleObjDataForEdit(before[key], after[key])) return false
      }
      if (Object.prototype.toString.call(before[key]) === '[object Array]') {
        //数组的情况
        // handleArrDataForEdit(before[key], after[key], diffObj, key)
      }
    } else if (typeof before[key] === 'function' || typeof before[key] === 'symbol') { // 如果是function或symbol，转字符串再对比，不匹配则直接返回false
      if (String(before[key]) !== String(after[key])) return false
    } else {  // 最后其他类型用es6得Object.is()来比较，不匹配直接返回false
      if (before[key] !== after[key]) {

        diffObj[key] = after[key]
      }
      // if (!Object.is(before[key], after[key])) return false
    }
  }
  return diffObj  // 遍历结束了没有返回false，说明没有问题，这里直接返回true，表示键值全等了
}

export const handleArrDataForEdit = (before: any, after: any, diffObj: any, keyName: string) => {
  // todo
  if (!after) {
    return
  }
  if (keyName === 'productSpecifications') {
    // debugger
  }
  if (!diffObj[keyName]) {
    diffObj[keyName] = []
  }
  //取到补集
  let complement = after.filter((beforeItem: any) => {
    return before.findIndex((afterItem: any) => beforeItem.id === afterItem.id) === -1
  })
  //取到交集
  let union = before.filter((beforeItem: any) => {
    return after.findIndex((afterItem: any) => beforeItem.id === afterItem.id) !== -1
  })
  //判断有没有删除
  let deleted = complement.filter((item: any) => item.id).map((item: any) => {
    let newItem = {
      isDelete: true,
      id: item.id
    }
    return newItem
  })


  //判断有没有新增
  let added = complement.filter((item: any) => !item.id)
  if (deleted?.length || added?.length) {
    // debugger
    let datas = [...deleted, ...added]
    diffObj[keyName].push(datas)
    // if (diffObj[keyName]) {
    //   diffObj[keyName].push(datas)
    // } else {
    //   diffObj[keyName] = []
    //   diffObj[keyName].push(datas)
    // }
  }
  // 判断有没有修改 //修改这个有点麻烦
  union.forEach((unionItem: any) => {
    after.find((item: any) => {
      if (unionItem.id === item.id) {
        handleObjDataForEdit(unionItem, item, diffObj[keyName])
      }
    })
  })
}

export const uuid = () => {
  return 'xxxx-xxxx-4xxx-yxxx-xxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16 | 0;
    let v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  })
}


// 获取cookie
export const getCookie = (key: string) => {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(key + '=')
    if (start !== -1) {
      start = start + key.length + 1
      var end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}
// 保存cookie
export const setCookie = (cName: string, value: string, expiredays: number) => {
  var exdate = new Date()
  exdate.setDate(exdate.getDate() + expiredays)
  // @ts-ignore
  document.cookie = cName + '=' + decodeURIComponent(value) + ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
}

export const getAge = (birthdayStr: any) => {
  if (!birthdayStr) {
    return ''
  }
  let birthday = birthdayStr.split('-')
  // 新建日期对象
  let date = new Date()
  // 今天日期，数组，同 birthday
  let today = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
  // 分别计算年月日差值
  let age = today.map((value, index) => {
    return value - birthday[index]
  })
  if (age[0] > 0) {
    return age[0] * 12
  } else if (age[1] > 0) {
    return age[1]
  } else {
    return 1
  }
}

/**
 * 调用antd的Modal.confirm统一弹出确认框样式
 */
export const openConfirmModal: (config: ModalFuncProps) => void = (config) => {
  Modal.confirm({
    className: "rc-modal",
    okText: intl.get('public.confirm'),
    cancelText: intl.get("public.cancel"),
    closable: true,
    icon: null,
    title: config.title || intl.get("public.confirm"),
    content: config.content || intl.get("public.confirm_tip"),
    onOk: config.onOk,
  });
}

export const validateUrl: (value: string) => boolean = (value) => {
  const urlRegex = new RegExp("^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$", 'i');
  return urlRegex.test(value);
}

export const treeToArr = (tree: any) => {
  let result: any = [] // 结果
  function getPath(node: any, arr: any) {
    arr.push(node)
    if (node?.children?.length > 0) { // 存在多个节点就递归
      node.children.forEach((v2: any) => getPath(v2, [...arr]))
    } else {
      result.push(arr)
    }
  }
  tree.forEach((v: any) => getPath(v, []))
  return result
}

export const getJSONfromService = async (languageKey: string) => {
  try {
    const { data } = await axios.get(`https://dtcdata.oss-cn-shanghai.aliyuncs.com/phrase/${languageKey}.json`)
    return data
  } catch (error) {
    return {}
  }
}

export const getHidedPhoneNum = (text: any) => {
  return text?.length == 11 ? text.substr(0, 3) + "****" + text.substr(7) : text
}


export const setToSemeCookie = (name: string, value: string | object) => {
  Cookies.set(name, value, { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', expires: 1000 * 60 * 60 * 24, path: '/' })
}

export const getDataFormSeamCookie = (value) => {
  return Cookies.get(value)
}
export const removeToken = () => {
  Cookies.remove('rc_access_ticket', { path: '/' })
  Cookies.remove('rc_access_token', { path: '/' })
  Cookies.remove('rc_cliant', { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', path: '/' })
  Cookies.remove('rc-refreshToken', { path: '/' })

  setToSemeCookie('rc_sc_login_module', 'Consumer Experience')
  setToSemeCookie('activeTab', '0')

}
export const getTickte = () => {
  return Cookies.get('rc_access_ticket')
}

export const setToSeamLocalStorage = (name: string, data: string) => {
  localStorage.setItem(name, data)
}

export const getFormLocalstorage = (name: string) => {
  return localStorage.getItem(name)
}


export const setScope = (name: string, value: string | object) => {
  Cookies.set(name, value, { path: '/', expires: 1000 * 60 * 60 * 24 })
}
export const getScope = (name: string) => {
  return Cookies.get(name)
}


export const loginOut = (data: any) => {
  return axios({
    method: 'post',
    data: data,
    url: `${process.env.REACT_APP_AUTHPATH}/loginOut`
  })
}

export const removeToSeamCookie = (value: string) => {
  Cookies.remove(value, { domain: process.env.NODE_ENV == 'production' ? '.d2cgo.com' : 'localhost', expires: 1000 * 60 * 60 * 24, path: '/' })
}